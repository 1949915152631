import { FC } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

type Props = {
  pageNumber: number;
  pageSize: number;
  total: number;
  updatePage: (pageNumber: number) => void;
  updatePageSize: (pageSize: number) => void;
};

const Pagination: FC<Props> = ({
  pageNumber,
  pageSize,
  updatePageSize,
  updatePage,
  total,
}) => {
  const pagesNumber = Math.ceil(total / pageSize);
  const pages = Array.from({ length: pagesNumber }, (_, i) => i + 1);

  if (total === 0) return null;

  return (
    <div className="flex justify-end">
      <div>
        <div className=" h-10 justify-start items-center gap-6 inline-flex">
          <div className="text-slate-600 text-sm font-medium font-sans leading-[14px]">
            {total} items
          </div>
          <div className="justify-start items-center gap-2 flex">
            <Select
              onValueChange={(optionValue) => {
                updatePage(Number(optionValue));
              }}
              value={String(pageNumber)}
            >
              <SelectTrigger className="w-[80px]">
                <SelectValue placeholder="Select a page" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {pages.map((option) => (
                    <SelectItem key={option} value={String(option)}>
                      {option}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            <div className="text-slate-600 text-sm font-medium font-sans leading-[14px]">
              of {pagesNumber}
            </div>
          </div>
          <div className="w-px h-10 bg-slate-300" />
          <Select
            onValueChange={(optionValue) => {
              updatePageSize(Number(optionValue));
              updatePage(1);
            }}
            value={String(pageSize)}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select a company" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Select page size</SelectLabel>
                {["10", "20", "50", "100"].map((option) => (
                  <SelectItem key={option} value={option}>
                    {option} per page
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
