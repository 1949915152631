import React, { FC } from "react";
import MenuDropdown, { MenuDropdownItem } from "../../common/MenuDropdown";
import { Icons } from "../../common/Icons";
import clsx from "clsx";

type Props = {
  menuItems: MenuDropdownItem[];
  isHovering: boolean;
  isSelected: boolean;
};
const ItemActions: FC<Props> = ({ menuItems, isHovering, isSelected }) => {
  const isMenuItemsEmpty = menuItems.length === 0;
  const icon = (
    <Icons.MoreDots
      className={clsx("size-[20px]", {
        "opacity-100": isHovering || isSelected,
        "opacity-0": !isHovering && !isSelected,
      })}
    />
  );
  if (isMenuItemsEmpty) {
    return <div className="size-[20px]" />;
  }
  return isSelected ? (
    <MenuDropdown items={menuItems} align="start">
      <div className="hover:bg-primary/10 rounded-full hover:text-primary text-slate-600 transition-colors">
        {icon}
      </div>
    </MenuDropdown>
  ) : (
    icon
  );
};
export default ItemActions;
