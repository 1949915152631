import { ComputationType } from "../constants";
import {
  TComputationStatus,
  TExecutionType,
  TSocketMsg, TSocketMsgAssetError,
  TSocketMsgCampaignError,
  TSocketMsgCIError,
  TSocketMsgCIUpdate,
  TSocketMsgComputationStatus,
  TSocketMsgMicrositeDeploy,
  TSocketMsgMicrositeDeployError,
  TSocketMsgPhaseError,
  TSocketMsgPromptPostProcessingResult,
  TSocketMsgStepError,
  TSocketMsgType,
} from '../globalTypes';
import { TCcVariable } from "../store/slices/ccVariablesSlice";
import { TEventMessagesMap } from "../store/slices/computationMessagesSlice";
import { getProgressNumber, toLocalTime } from "./cm.utils";

type TProcessEventMessagesProps = {
  messages: TSocketMsg<TSocketMsgType>[];
  shouldUpdateCurrentStepCCItemsInGrid: boolean;
  currentStepId: number | undefined;
};

type TProcessEventMessagesResponse = {
  eventMessagesMap: TEventMessagesMap;
  itemsToUpdate: TCcVariable[];
  computationStatus: TComputationStatus | null;
  computationProgress: number | null;
  computationType: ComputationType | null;
  executionType: TExecutionType | null;
};

const processEventMessages = ({
  messages,
  shouldUpdateCurrentStepCCItemsInGrid,
  currentStepId,
}: TProcessEventMessagesProps): TProcessEventMessagesResponse => {
  let eventMessagesMap: TEventMessagesMap = {}; // set in all types
  const itemsToUpdate: TCcVariable[] = []; // set in CI_UPDATED and CI_ERROR type
  let computationStatus: TComputationStatus | null = null; // set in COMPUTATION_STATUS type
  let computationProgress: number | null = null; // set in COMPUTATION_STATUS type
  let computationType: ComputationType | null = null; // set in COMPUTATION_STATUS type
  let executionType: TExecutionType | null = null; // set in COMPUTATION_STATUS type

  messages.forEach((message, index) => {
    const { type, timestamp } = message;
    const localTime = toLocalTime(timestamp);

    switch (type) {
      case "CI_UPDATED": {
        const { item, computationTime, path } = message as TSocketMsgCIUpdate;
        const { key, stepId } = item.id;
        const eventMessageKey = `${stepId}_${key}`;

        //update only visible items in the grid (if certain step opened)
        if (shouldUpdateCurrentStepCCItemsInGrid && currentStepId === stepId) {
          itemsToUpdate.push(item);
        }

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path,
          status: item.state,
          computationTime,
          item,
        };
        break;
      }
      case "CI_ERROR": {
        const { key, stepId, errorMessage, item, path } =
          message as TSocketMsgCIError;
        const eventMessageKey = `${stepId}_${key}`;

        //update only visible items in the grid (if certain step opened)
        if (shouldUpdateCurrentStepCCItemsInGrid && currentStepId === stepId) {
          itemsToUpdate.push(item);
        }

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path,
          status: item.state,
          errorMessage,
          item,
        };
        break;
      }
      case "STEP_ERROR": {
        const { stepId, errorMessage, path } = message as TSocketMsgStepError;
        const eventMessageKey = `${type}_${stepId}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path,
          status: "error",
          errorMessage,
        };
        break;
      }
      case "PHASE_ERROR": {
        const { phaseId, errorMessage, path } = message as TSocketMsgPhaseError;
        const eventMessageKey = `${type}_${phaseId}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path,
          status: "error",
          errorMessage,
        };
        break;
      }
      case "CAMPAIGN_ERROR": {
        const { errorMessage, campaignId } = message as TSocketMsgCampaignError;
        const eventMessageKey = `${type}_${campaignId}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path: "An error occurred during the execution of the campaign!",
          status: "error",
          errorMessage,
        };
        break;
      }
      case "COMPUTATION_STATUS": {
        const {
          total,
          onhold,
          state,
          computationType: compType,
          executionType: execType,
        } = message as TSocketMsgComputationStatus;

        computationProgress = getProgressNumber(total, onhold);
        computationStatus = state;
        computationType = compType;
        executionType = execType;

        break;
      }

      case "POST_PROCESSING": {
        const {
          postProcessingResultMessage,
          path,
          item: { id },
        } = message as TSocketMsgPromptPostProcessingResult;
        const { stepId, key } = id;
        const eventMessageKey = `${type}_${stepId}_${key}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path,
          status: "finished",
          message: postProcessingResultMessage,
        };
        break;
      }

      case "MICROSITE_DEPLOY": {
        const {
          asset: { title, stepId },
          path,
        } = message as TSocketMsgMicrositeDeploy;
        const eventMessageKey = `${type}_${stepId}_${title}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path,
          status: "finished",
        };
        break;
      }

      case "DEPLOY_ERROR": {
        const { stepId, errorMessage, phaseId, path } =
          message as TSocketMsgMicrositeDeployError;
        const eventMessageKey = `${type}_${phaseId}_${stepId}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path,
          errorMessage,
          status: "error",
        };
        break;
      }

      case "ASSET_ERROR": {
        const { errorMessage, path } =
          message as TSocketMsgAssetError;
        const eventMessageKey = `${type}_${localTime}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path,
          errorMessage,
          status: "error",
        };
        break;
      }

      default: {
        const eventMessageKey = `${type}_${index}_${localTime}`;

        eventMessagesMap[eventMessageKey] = {
          type,
          localTime,
          key: eventMessageKey,
          path: `${index}__Unhandled message type__${localTime}`,
        };
        console.warn("Unhandled message type", message);
        break;
      }
    }
  });

  return {
    eventMessagesMap,
    itemsToUpdate,
    computationStatus,
    computationProgress,
    computationType,
    executionType,
  };
};

export default processEventMessages;
