import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { getCampaignsApi } from "../../api/campaigns.api";
import {
  handleRequestError,
  TCustomError,
} from "../../utils/handleRequestError";
import { RootState } from "../store";
import { toggleIsFetching } from "./appSlice";
import { setCampaigns } from "./campaignsSlice";
import { setCompanies } from "./companiesSlice";
import { CampaignsListRequestParams } from "../../pages/campaigns/hooks/useCampaignsList";
import { getCompaniesApi } from "../../api/companies.api";
import { TCampaignListOptionType } from "src/globalTypes";

export type TCampaignsList = {
  companyId: number | null;
  campaignType: TCampaignListOptionType;
  currentPage: number;
  pageSize: number;
  totalCampaigns: number;
};

type InitialStateType = TCampaignsList;

const initialState: InitialStateType = {
  companyId: null,
  campaignType: "ALL",
  currentPage: 1,
  pageSize: 50,
  totalCampaigns: 0,
};

const campaignsListSlice = createSlice({
  name: "campaignsList",
  initialState,
  reducers: {
    setCampaignListTotalCampaigns: (
      state: InitialStateType,
      action: PayloadAction<number>,
    ) => {
      state.totalCampaigns = action.payload;
    },
  },
});

export const { setCampaignListTotalCampaigns } = campaignsListSlice.actions;

export default campaignsListSlice.reducer;

export const useCampaignListTotalCampaigns = (): number =>
  useSelector((state: RootState) => state.campaignsList.totalCampaigns);

//THUNK

export const initCampaignsListFilters = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "campaignsList/initCampaignsListFilters",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleIsFetching(true));

      const { data } = await getCompaniesApi({
        name: "",
        pageNumber: 1,
        pageSize: 1000,
      });

      dispatch(setCompanies(data.items));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to init campaigns page:`,
        e,
      );

      return rejectWithValue(customError);
    }
  },
);

export const loadCampaignsList = createAsyncThunk<
  undefined,
  CampaignsListRequestParams,
  {
    rejectValue: TCustomError;
  }
>(
  "campaignsList/loadCampaignsList",
  async (
    { companyId, page, pageSize, campaignType },
    { rejectWithValue, dispatch },
  ) => {
    try {
      dispatch(toggleIsFetching(true));

      const { data } = await getCampaignsApi({
        companyId: companyId || null,
        currentPage: page,
        pageSize,
        campaignType,
      });
      const { items: campaigns, totalItems } = data;

      dispatch(setCampaignListTotalCampaigns(totalItems));
      dispatch(setCampaigns(campaigns));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to init campaigns page:`,
        e,
      );

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  },
);
