import React from "react";
import CustomerSite from "../../../components/companies/CompanySite/CustomerSite";
import { getCurrentCompany } from "../../../store/slices/companiesSlice";

const CustomerSitePage = () => {
  const currentCompany = getCurrentCompany();

  return (
    <div>
      {currentCompany ? (
        <CustomerSite
          companyId={currentCompany.id}
          companyName={currentCompany.name}
        />
      ) : (
        <span className="text-red-600">Unable to get company data</span>
      )}
    </div>
  );
};

export default CustomerSitePage;
