import { usePostHog } from "posthog-js/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Alert } from "src/components/alerts/Alert";
import { loginThunk } from "../../../store/slices/userDataSlice";
import { AppDispatch } from "../../../store/store";
import "./style.scss";
import localStorageProvider from "../../../utils/localStorageProvider";
import { Spin } from "antd";
import { selectMessageApi } from '../../../store/slices/appSlice';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

type TGoogleServiceAuthRes = {
  clientId: string;
  client_id: string;
  credential: string;
  select_by: string;
};

const GoogleOauth: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const posthog = usePostHog();
  const [error, setError] = useState<any>();
  const [isFetching, setIsFetching] = useState(false);
  const messageApi = useSelector(selectMessageApi);
  const signInBtnId = "signInBtn";

  useEffect(() => {
    //@ts-ignore
    if (google) {
      //@ts-ignore
      google.accounts.id.initialize({
        client_id: CLIENT_ID,
        callback: handleAuthSuccess,
      });
      //@ts-ignore
      google.accounts.id.renderButton(document.getElementById(signInBtnId), {
        theme: "outline",
        size: "large",
      });
    } else {
      messageApi.error("Couldn't initialize google OAuth library")
    }

  }, []);

  const handleAuthSuccess = async (response: TGoogleServiceAuthRes) => {
    setError(undefined);

    if ("credential" in response) {
      try {
        setIsFetching(true);

        const { email } = await dispatch(
          loginThunk(response.credential),
        ).unwrap();
        const lastUrl = localStorageProvider.getLastUrl();
        const redirectPath = lastUrl ? lastUrl : "/home";

        posthog?.identify(email);

        posthog?.capture("The user has logged in");

        navigate(redirectPath);
      } catch (err) {
        setError(err);
      } finally {
        setIsFetching(false);
      }
    }
  };

  return (
    <div className="auth-wrapper">
      <Spin spinning={isFetching} tip="Logging in...">
        <div id={signInBtnId} />
      </Spin>

      {error?.status === 404 && (
        <Alert title="User not found">
          Sorry, you don't have a registered account with Velocity Engine. Click
          here to{" "}
          <a
            href="mailto:hello@shastaelevate.com?subject=Account Troubleshooting"
            rel="noreferrer nofollower"
            target="_blank"
          >
            send us a note
          </a>
        </Alert>
      )}
    </div>
  );
};

export default GoogleOauth;
