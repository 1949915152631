import clsx from "clsx";
import React, { FC, useRef, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "src/components/common/ui/dropdown-menu";
import { DROPDOWN_MENU_COLLAPSE_LENGTH } from "src/constants";

export type MenuDropdownItem = {
  key: string;
  label: string;
  onClick: ( e?: React.MouseEventHandler<HTMLDivElement>) => void;
  icon: any;
};

type Props = {
  children: React.ReactNode;
  items: MenuDropdownItem[];
  moreItems?: MenuDropdownItem[];
  dropdownClassName?: string;
  align?: "start" | "end";
};

const MenuDropdown: FC<Props> = ({
  children,
  items,
  moreItems,
  dropdownClassName,
  align,
}) => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const shouldCollapse = moreItems?.length
    ? items!.length + moreItems?.length < DROPDOWN_MENU_COLLAPSE_LENGTH
    : false;

  const allItems = shouldCollapse ? [...items, ...(moreItems || [])] : items;

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild ref={triggerRef}>
        {children}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={dropdownClassName}
        side={"bottom"}
        align={align || "start"}
      >
        {allItems!.map((item: any) => {
          return (
            <DropdownMenuItem
              key={item.key}
              onClick={item.onClick}
              disabled={item.disabled}
              className={clsx(
                `p-[8px] cursor-pointer !text-slate-600 font-medium font-sans`,
                item.className
              )}
            >
              <item.icon className="w-5 h-5 mr-2" />
              <span className="text-sm font-medium font-sans w-[85%]">
                {item.label}
              </span>
            </DropdownMenuItem>
          );
        })}
        {!!moreItems?.length && !shouldCollapse && (
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <span className="font-sans !text-slate-600 font-medium">
                More
              </span>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                {moreItems.map((item: any) => {
                  return (
                    <DropdownMenuItem
                      key={item.key}
                      onClick={item.onClick}
                      disabled={item.disabled}
                      className={clsx(
                        `p-[8px] cursor-pointer !text-slate-600 font-medium font-sans`,
                        item.className
                      )}
                    >
                      <item.icon className="w-5 h-5 mr-2" />
                      <span className="text-sm font-medium font-sans">
                        {item.label}
                      </span>
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default MenuDropdown;
