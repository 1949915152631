import { AxiosResponse } from "axios";
import {
  TExportCampaignSettings,
  TSettingType,
} from "../components/campaignHeader/Settings/Settings";
import { TLabels, TLabelsListData, TPhase } from "../globalTypes";
import { TCcVariable } from "../store/slices/ccVariablesSlice";
import { TStep, TStepPathMap } from "../store/slices/stepsSlice";
import { VAR_IN_PROMPT_PLACEHOLDER } from "./campaigns.constant";
import { TPhasesData } from "./transformCampaignData";

export const getLastSequence = <T extends TCcVariable | TPhase | TStep>(
  arr: Array<T>,
  sequenceStep: number,
): number => {
  if (!arr.length) return 0;

  return arr[arr.length - 1].seq + sequenceStep;
};

export const getNumOfVarsInPrompt = (string: string): number =>
  string ? string.split(VAR_IN_PROMPT_PLACEHOLDER).length - 1 : 0;
export const sortBySequence = <T extends TCcVariable | TPhase | TStep>(
  arr: Array<T>,
): Array<T> => arr.sort((a, b) => a.seq - b.seq);

export const toLocalTime = (timestamp: number): string => {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hour = date.getHours().toString().padStart(2, "0");
  const minute = date.getMinutes().toString().padStart(2, "0");
  const second = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};

export const downloadTextFile = ({
  data,
  type,
  fileName,
}: {
  data: string;
  type: string;
  fileName: string;
}) => {
  const blob = new Blob([data], { type });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.href = url;
  link.download = fileName;
  link.click();

  URL.revokeObjectURL(url);
};

export const getExportSettingQueryParam = (
  settings: TExportCampaignSettings,
): string => {
  let param = "";

  for (const setting in settings) {
    if (settings[setting as TSettingType]) {
      param += `${setting},`;
    }
  }

  if (!param) return "";

  //remove last comma
  return param.slice(0, -1);
};

//TODO: check for places where to use this func
export const downloadFileUtil = (
  response: AxiosResponse<ArrayBuffer | Blob | string, any>,
  fileName?: string,
) => {
  const contentDisposition = response.headers["content-disposition"];
  let suggestedFileName = "download";

  if (
    !fileName &&
    contentDisposition &&
    contentDisposition.includes("attachment")
  ) {
    const fileNameMatch = contentDisposition.match(
      /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
    );
    if (fileNameMatch && fileNameMatch[1]) {
      suggestedFileName = fileNameMatch[1].replace(/['"]/g, "");
    }
  }

  const blob = new Blob([response.data], {
    type: response.headers["content-type"] || "text/plain",
  });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = fileName || suggestedFileName;
  a.click();

  window.URL.revokeObjectURL(url);
};

export const getDynFormSelectOptions = ({
  data,
  delimiter = "~",
}: {
  data: string[];
  delimiter?: string;
}) => {
  return data.map((option) => {
    let value: string | number | null = option;
    let label = option;

    if (option.includes(delimiter)) {
      const parts = option.split("~");
      const splitValue = parts[0];
      const splitLabel = parts.slice(1).join("~");

      let integerValue = parseInt(splitValue);

      if (!isNaN(integerValue)) {
        value = integerValue;
      } else if (splitValue === "null") {
        value = null;
      } else {
        value = splitValue;
      }

      label = splitLabel;
    }

    return {
      label,
      value,
    };
  });
};

export const convertLabelsToArray = (
  labels: TLabels,
): Array<TLabelsListData> => {
  const labelsList: Array<TLabelsListData> = [];

  for (const key in labels) {
    const value = labels[key];

    labelsList.push({
      labelKey: key,
      value,
      key,
    });
  }

  return labelsList;
};

export const getProgressNumber = (
  total: number,
  toComplete: number,
): number => {
  if (total <= 0 || toComplete < 0 || toComplete > total) {
    return 0;
  }

  const progress = (1 - toComplete / total) * 100;
  const roundedProgress = Math.min(Math.max(progress, 0), 100).toFixed(2);

  return parseFloat(roundedProgress);
};

export const getStepPathMap = (phases: Array<TPhasesData>): TStepPathMap => {
  const map: TStepPathMap = {};

  phases.forEach((phase) => {
    phase.steps.forEach((step) => {
      map[step.id] = `${phase.name}/${step.name}/`;
    });
  });

  return map;
};

export const camelCaseToWords = (str: string) => {
  return str
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
};

export const replaceBrackets = (path: string) =>
  path.replace(/\[/g, "%5B").replace(/]/g, "%5D");
