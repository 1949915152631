import { Tooltip } from "antd";
import { TCcVariable } from "src/store/slices/ccVariablesSlice";
import { SolidErrorIcon } from "../../common/Icons";
import { iconStateMapper } from "../constants";
import { getHighestPriorityState } from "../helpers";

const CampaignStateIndicator = ({
  ccVariables,
}: {
  ccVariables: TCcVariable[];
}) => {
  const priorityState = getHighestPriorityState(ccVariables);
  if (!priorityState || ccVariables?.length === 0) {
    return (
      <Tooltip title="There are no CC items in step!">
        <span className="text-red-600">
          <SolidErrorIcon />
        </span>
      </Tooltip>
    );
  }
  const info = iconStateMapper[priorityState];
  return (
    <>
      <Tooltip title={info.tooltip}>
        <div className="min-w-[20px] min-h-[20px]">{info.icon}</div>
      </Tooltip>
    </>
  );
};
export default CampaignStateIndicator;
