import { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "src/components/common/ui/button";
import MenuDropdown, {
  MenuDropdownItem,
} from "src/components/common/MenuDropdown";
import {
  DownloadIcon,
  GlobalStorageIcon,
  Icons,
  LabelsIcon,
  MiniFolderIcon,
} from "../../common/Icons";
import ImportCampaignModal from "../ImportCampaignModal/ImportCampaignModal";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";
import useNavigateWithOutletRedirect from "../../../hooks/navigation/useNavigateWithOutletRedirect";
import StartCampaignModal from "../StartCampaignModal/StartCampaignModal";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";

type Props = {
  companyId: number | undefined;
  onCampaignCreate: () => Promise<void>;
};

const SettingsMenu: FC<Props> = ({ onCampaignCreate, companyId }) => {
  const navigate = useNavigate();
  const { navigateWithOutletRedirect } = useNavigateWithOutletRedirect();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStartCampaignModalOpen, setIsStartCampaignModalOpen] =
    useState(false);
  const {
    hasCampaignCreateRole,
    hasCampaignImportRole,
    hasG18nListRole,
    hasPublicRootFsFileListRole,
    hasPrivateRootFsFileListRole,
  } = useUserHasPermission({});
  const isTemplatesPage = useLocation().pathname.includes("/templates");
  const { hasPublicFsFileListRole, hasPrivateFsFileListRole } =
    useUserHasPermission({ companyId });

  const actionsItems = [
    hasCampaignImportRole && {
      key: "import",
      label: "Import Campaign",
      icon: DownloadIcon,
      onClick: () => setIsModalOpen(true),
    },
    hasG18nListRole && {
      key: "globalLabels",
      label: "Global Labels",
      icon: LabelsIcon,
      onClick: () => navigate("/campaigns/globalLabels"),
    },
    (hasPublicRootFsFileListRole || hasPrivateRootFsFileListRole) && {
      key: "globalStorage",
      label: "Global Storage",
      icon: GlobalStorageIcon,
      onClick: () => navigateWithOutletRedirect("/campaigns/storage"),
    },
    (hasPublicFsFileListRole || hasPrivateFsFileListRole) && {
      key: "companyStorage",
      label: "Company Storage",
      icon: MiniFolderIcon,
      disabled: !companyId,
      onClick: () =>
        navigateWithOutletRedirect(`/campaigns/company/${companyId}/storage`),
    },
  ].filter(Boolean) as MenuDropdownItem[];

  return (
    <>
      <div className="flex gap-3">
        {!!actionsItems.length && (
          <MenuDropdown items={actionsItems} dropdownClassName="w-[190px]">
            <Button variant={"primaryOutline"} className="text-sm font-medium">
              Actions
              <Icons.More className="size-4 ml-2" />
            </Button>
          </MenuDropdown>
        )}

        {isTemplatesPage && hasCampaignCreateRole && (
          <Button className="rounded-full" onClick={onCampaignCreate}>
            <DocumentPlusIcon className="mr-[4px] size-4" />
            Add Draft Template
          </Button>
        )}
        {hasCampaignCreateRole && (
          <Button
            className="rounded-full"
            onClick={() => setIsStartCampaignModalOpen(true)}
          >
            <Icons.Plus className="mr-[4px] size-4" />
            Create Campaign
          </Button>
        )}
      </div>
      {hasCampaignImportRole && isModalOpen && (
        <ImportCampaignModal
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
        />
      )}
      {hasCampaignCreateRole && isStartCampaignModalOpen && (
        <StartCampaignModal
          setIsModalOpen={setIsStartCampaignModalOpen}
          isModalOpen={isStartCampaignModalOpen}
        />
      )}
    </>
  );
};

export default SettingsMenu;
