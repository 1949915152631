import { NodeColor } from "../../pages/graph/hooks/useGetNodes.hook";

const CustomMarkers = () => {
  return (
    <svg style={{ position: "absolute", top: 0, left: 0 }}>
      <defs>
        <marker
          id="default"
          viewBox="0 0 10 10"
          refX="5"
          refY="5"
          markerWidth="10"
          markerHeight="10"
          orient="auto"
        >
          <circle cx="5" cy="5" r="5" fill={NodeColor.DEFAULT} />
        </marker>
        <marker
          id="input"
          viewBox="0 0 4 4"
          refX="2"
          refY="2"
          markerWidth="4"
          markerHeight="4"
          orient="auto"
        >
          <circle cx="2" cy="2" r="2" fill={NodeColor.INPUT} />
        </marker>
        <marker
          id="target"
          viewBox="0 0 4 4"
          refX="2"
          refY="2"
          markerWidth="4"
          markerHeight="4"
          orient="auto"
        >
          <circle cx="2" cy="2" r="2" fill={NodeColor.TARGET} />
        </marker>
      </defs>
    </svg>
  );
};

export default CustomMarkers;
