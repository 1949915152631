import { Input, Modal, Skeleton, Spin } from "antd";
import { FC, useEffect, useState } from "react";
import { getMessageApi } from "../../store/slices/appSlice";
import { dictionaryApi } from "../../api/dictionary.api";
import handleRequestError from "../../utils/handleRequestError";
import { Button } from "../common/ui/button";
import { Table, TableBody, TableCell, TableRow } from "../common/ui/table";
import { DeleteIcon } from "../common/Icons";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const ClassesListModal: FC<Props> = ({ open, setOpen }) => {
  const [fetchedClasses, setFetchedClasses] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [addingClass, setAddingClass] = useState<boolean>(false);
  const [newClass, setNewClass] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const messageApi = getMessageApi();
  const [inputError, setInputError] = useState<string | null>(null);
  const [deletingClasses, setDeletingClasses] = useState<Set<string>>(
    new Set(),
  );

  const getFilteredClasses = (classes: string[], searchTerm: string) => {
    return searchTerm
      ? classes.filter((cls) =>
          cls.toLowerCase().includes(searchTerm.toLowerCase()),
        )
      : classes;
  };

  const filteredClasses = getFilteredClasses(fetchedClasses, searchTerm);

  const validateInput = (input: string) => {
    const trimmedInput = input.trim();

    if (trimmedInput === "") {
      return null;
    }

    if (trimmedInput.length < 2) {
      return "Class name must be at least 2 characters long.";
    }

    if (trimmedInput.length > 255) {
      return "Class name cannot exceed 255 characters.";
    }

    if (!/^[a-zA-Z0-9 _-]+$/.test(trimmedInput)) {
      return "Class name can only contain letters, numbers, spaces, underscores, and hyphens.";
    }

    if (fetchedClasses.includes(trimmedInput)) {
      return "Class name already exists.";
    }

    return null;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setNewClass(inputValue);
    setInputError(validateInput(inputValue));
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleAddClass = async () => {
    if (inputError) return;

    try {
      setAddingClass(true);
      await dictionaryApi({ dictionaryType: "CLASS" }).add({ item: newClass });
      setFetchedClasses((prevClasses) => [...prevClasses, newClass]);
      setNewClass("");
      setInputError(null);
      messageApi.success("Class added successfully.");
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setAddingClass(false);
    }
  };

  const handleDeleteClass = async (cls: string) => {
    try {
      setDeletingClasses((prevSet) => new Set(prevSet).add(cls));
      await dictionaryApi({ dictionaryType: "CLASS" }).delete({
        wordMatch: cls,
      });
      setFetchedClasses((prevClasses) =>
        prevClasses.filter((className) => className !== cls),
      );
      messageApi.success("Class deleted successfully.");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const { data } = await dictionaryApi({ dictionaryType: "CLASS" }).find({
          wordMatch: `**`,
        });
        setFetchedClasses(data);
      } catch (e: any) {
        const customError = handleRequestError(e);

        messageApi.error(customError.message);
        console.error(customError);
      } finally {
        setLoading(false);
      }
    };

    fetchClasses();
  }, []);

  return (
    <Modal
      title="Classes"
      open={open}
      onCancel={() => setOpen(false)}
      width={700}
      footer={null}
    >
      <div className="relative mb-2">
        <div className="flex gap-[10px] items-start">
          <div className="flex-grow">
            <div className="min-h-[56px]">
              <Input
                value={newClass}
                onChange={handleInputChange}
                placeholder="Enter new class name"
                className="h-[40px]"
              />
            </div>
          </div>
          <Button
            disabled={!!inputError || addingClass || !newClass.trim()}
            variant="primary"
            loading={addingClass}
            onClick={handleAddClass}
          >
            Add Class
          </Button>
        </div>
        {inputError && (
          <div className="absolute bottom-[-7px] left-0 right-0">
            <span className="text-red-500 text-sm block">{inputError}</span>
          </div>
        )}
      </div>

      <div className="h-[1px] bg-[#E2E8F0]" />
      <div className="mb-2 mt-6">
        <Input
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search classes"
          className="w-[200px] px-2 py-1"
          allowClear
        />
      </div>
      <div className="overflow-auto h-[400px]">
        <Table>
          <TableBody className="font-sans">
            {loading ? (
              <TableRow>
                <TableCell>
                  <Skeleton active />
                </TableCell>
              </TableRow>
            ) : filteredClasses.length ? (
              filteredClasses.map((cls, index) => (
                <TableRow key={index} className="py-4 h-12">
                  <TableCell className="p-3 break-all flex items-center">
                    <Spin spinning={deletingClasses.has(cls)}>{cls}</Spin>
                  </TableCell>
                  <TableCell className="p-3 text-right">
                    <div
                      onClick={() => handleDeleteClass(cls)}
                      className="flex items-center justify-end space-x-1 cursor-pointer"
                    >
                      <DeleteIcon className="w-4 h-5 text-red-500" />
                      <span className="text-red-500">Delete</span>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell className="text-center">No results.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </Modal>
  );
};

export default ClassesListModal;
