import React, { useState } from "react";
import ExecuteModal from "src/components/common/modals/ExecuteModal/ExecuteModal";
import { ComputationType } from "src/constants";
import CloneToPhaseModal, {
  TCloneToPhaseFormValues,
} from "../components/campaignPhases/CloneToPhaseModal/CloneToPhaseModal";
import CloneToStepModal, {
  TCloneToStepFormValues,
} from "../components/campaignSteps/CloneToStepModal/CloneToStepModal";
import TemplatePreviewModal, {
  TTemplatePreviewModalProps,
} from "../components/campaignSteps/TemplatePreviewModal";
import { TExecutionType } from "src/globalTypes";

const useModals = () => {
  const [modalProps, setModalProps] = useState<{
    visible: boolean;
    component: null | React.ReactNode;
  }>({
    visible: false,
    component: null,
  });

  const onCancel = () => {
    setModalProps({ visible: false, component: null });
  };

  const execute = ({
    title,
    requestExecute,
    executionType,
  }: {
    title: string;
    executionType: TExecutionType;
    requestExecute: (
      computationType: ComputationType,
      republish: boolean,
      updateDownstreamDependencies: boolean,
    ) => Promise<void>;
  }) => {
    const executeModal = (
      <ExecuteModal
        title={title}
        onRequestExecute={requestExecute}
        onCancel={onCancel}
        executionType={executionType}
      />
    );

    setModalProps({ visible: true, component: executeModal });
  };

  const cloneToPhase = ({
    onSubmitForm,
    entityTitle,
  }: {
    entityTitle: string;
    onSubmitForm: (values: TCloneToPhaseFormValues) => Promise<void>;
  }) => {
    const copyToModal = (
      <CloneToPhaseModal
        onSubmitForm={onSubmitForm}
        entityTitle={entityTitle}
        onCancel={onCancel}
      />
    );

    setModalProps({ visible: true, component: copyToModal });
  };

  const cloneToStep = ({
    onSubmitForm,
    entityTitle,
  }: {
    entityTitle: string;
    onSubmitForm: (values: TCloneToStepFormValues) => Promise<void>;
  }) => {
    const copyToModal = (
      <CloneToStepModal
        onSubmitForm={onSubmitForm}
        entityTitle={entityTitle}
        onCancel={onCancel}
      />
    );

    setModalProps({ visible: true, component: copyToModal });
  };

  const stepTemplatePreview = (
    props: Omit<TTemplatePreviewModalProps, "onCancel">
  ) => {
    const copyToModal = <TemplatePreviewModal {...props} onCancel={onCancel} />;

    setModalProps({ visible: true, component: copyToModal });
  };

  const modal = <>{modalProps.visible && modalProps.component}</>;

  return {
    modal,
    execute,
    cloneToPhase,
    cloneToStep,
    stepTemplatePreview,
  };
};

export default useModals;
