import { Checkbox, Input, InputRef, Modal } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import { Button } from "../../ui/button";

type Props = {
  entity: string;
  title: string;
  onDelete: (removeFiles: boolean) => Promise<void>;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const DeleteWithFilesModal: FC<Props> = ({
  title,
  entity,
  onDelete,
  isModalOpen,
  setIsModalOpen,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [removeFiles, setRemoveFiles] = useState(true);
  const inputRef = useRef<InputRef>(null);
  const keyDownDeleteHandler = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onSubmit();
    }
  };

  useEffect(() => {
    const isKeydownAllowed = isModalOpen && !isLoading && !disabled;

    if (isModalOpen && inputRef.current) {
      inputRef.current.focus({ cursor: "end" });
    }

    if (isKeydownAllowed) {
      document.addEventListener("keydown", keyDownDeleteHandler);
    } else {
      document.removeEventListener("keydown", keyDownDeleteHandler);
    }

    return () => {
      document.removeEventListener("keydown", keyDownDeleteHandler);
    };
  }, [inputRef, isModalOpen, isLoading, disabled]);

  async function onSubmit() {
    try {
      setIsLoading(true);
      await onDelete(removeFiles);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      title={`Delete ${entity}`}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      destroyOnClose
      footer={() => {
        return (
          <div className="flex gap-3 justify-end">
            <Button variant="outline" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button
              disabled={disabled || isLoading}
              loading={isLoading}
              onClick={onSubmit}
              variant="destructive"
            >
              Permanently Delete
            </Button>
          </div>
        );
      }}
    >
      <div className="flex flex-col gap-3">
        <span className="text-[15px] text-destructive">
          This will <b>permanently delete</b> the {entity}!
        </span>
        <Checkbox
            onChange={(e) => setRemoveFiles(e.target.checked)}
            checked={removeFiles}
        >Delete storage files</Checkbox>
        <span>
          Please type <b className="whitespace-pre-wrap bg-gray-100">{title}</b>{" "}
          to confirm.
        </span>
        <Input
          ref={inputRef}
          placeholder={title}
          onChange={(e) => setDisabled(e.currentTarget.value !== title)}
        />
      </div>
    </Modal>
  );
};

export default DeleteWithFilesModal;
