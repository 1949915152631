import React, { FC, useState } from "react";
import { TUserCompanySecurityGroup } from "../../../globalTypes";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { DeleteIcon, Icons } from "../../common/Icons";
import MenuDropdown, { MenuDropdownItem } from "../../common/MenuDropdown";
import { TableCell, TableRow } from "../../common/ui/table";
import { Button } from "../../common/ui/button";
import DeleteWithTitleConfirmationModal from "../../common/modals/DeleteWithTitleConfirmationModal/DeleteWithTitleConfirmationModal";
import handleRequestError from "../../../utils/handleRequestError";
import { deleteCompanyPermissionGroupApi } from "../../../api/user.api";
import clsx from "clsx";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";
import useNavigateWithOutletRedirect from "../../../hooks/navigation/useNavigateWithOutletRedirect";

type Props = {
  companySecurityGroup: TUserCompanySecurityGroup;
  updateList: ({
    userId,
    update,
  }: {
    userId: string | number;
    update?: boolean | undefined;
  }) => Promise<void>;
};

const UserCompanySecurityGroupsListItem: FC<Props> = ({
  companySecurityGroup,
  updateList,
}) => {
  const { companyName, securityGroups, userId, companyId } =
    companySecurityGroup;
  const { companyId: currentCompanyId } = useParams();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const messageApi = useSelector(selectMessageApi);
  const { navigateWithOutletRedirect } = useNavigateWithOutletRedirect();
  const {
    hasUserCompaniesUnassignRole,
    hasCompanyReadRole,
    hasUserCompanyPermReadRole,
  } = useUserHasPermission({ companyId: currentCompanyId });

  const menuItems = [
    hasCompanyReadRole && {
      key: "goToCompany",
      label: "Go to company",
      onClick: () =>
        navigateWithOutletRedirect(`/companies/${companyId}/details`),
      icon: Icons.edit,
    },
    hasUserCompaniesUnassignRole && {
      key: "delete",
      label: "Delete",
      onClick: () => setIsDeleteModalOpen(true),
      icon: DeleteIcon,
      className: "!text-red-600",
    },
  ].filter(Boolean) as MenuDropdownItem[];

  const onDelete = async () => {
    try {
      await deleteCompanyPermissionGroupApi({ userId, companyId });
      await updateList({ userId, update: true });

      //if removed opened company group - redirect to company security list
      if (currentCompanyId !== undefined && +currentCompanyId === companyId) {
        navigate(`/users/${userId}/details/company-security`);
      }
      messageApi.success("Company group deleted successfully");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const navigateToCompanyPermissions = () =>
    navigate(`/users/${userId}/details/company-security/${companyId}`);

  return (
    <>
      <TableRow
        key={companyId}
        onClick={
          hasUserCompanyPermReadRole ? navigateToCompanyPermissions : undefined
        }
        className="cursor-pointer relative h-[40px]"
      >
        <TableCell>
          <NavLink
            to={`${companyId}`}
            className={({ isActive }) => {
              return clsx({
                "text-[#4F46E5] font-semibold hover:text-[#4F46E5]": isActive,
                "text-[#000] hover:text-[#000]": !isActive,
              });
            }}
          >
            {companyName}
          </NavLink>
        </TableCell>
        <TableCell>{securityGroups}</TableCell>

        {menuItems.length ? (
          <TableCell onClick={(e) => e.stopPropagation()}>
            <MenuDropdown
              items={menuItems}
              dropdownClassName="w-[190px]"
              align="end"
            >
              <Button
                variant={"ghostPrimary"}
                className="size-[40px] p-2 flex justify-end"
              >
                <Icons.MoreDots className="size-[24px]" />
              </Button>
            </MenuDropdown>
          </TableCell>
        ) : (
          <TableCell />
        )}
      </TableRow>
      {hasUserCompaniesUnassignRole && isDeleteModalOpen && (
        <DeleteWithTitleConfirmationModal
          entity="Company Security Group"
          onDelete={onDelete}
          title={companyName}
          setIsModalOpen={setIsDeleteModalOpen}
          isModalOpen={isDeleteModalOpen}
        />
      )}
    </>
  );
};

export default UserCompanySecurityGroupsListItem;
