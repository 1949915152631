import { Form, FormInstance, Input } from "antd";
import React from "react";
import {
  allowOnlyCharsDashAndQuotes,
  email,
  minMax,
  required,
} from "../../../utils/validations";
import { TUserForm } from '../../../globalTypes';

type Props = {
  form: FormInstance<TUserForm>;
};

const UserForm: React.FC<Props> = ({ form }) => {
  //TODO move to util and reuse
  const customizeRequiredMark = (
    label: React.ReactNode,
    { required }: { required: boolean },
  ) => (
    <span className="font-sans font-bold text-[#475569] text-[14px] flex">
      {label}
      {required && <span className="text-red-500 ml-1">*</span>}
    </span>
  );

  return (
    <Form
      name="userForm"
      form={form}
      layout="vertical"
      requiredMark={customizeRequiredMark}
    >
      <Form.Item
        name="firstName"
        label="First Name"
        rules={[
          required(),
          minMax({ text: "First name", min: 2, max: 40 }),
          allowOnlyCharsDashAndQuotes,
        ]}
      >
        <Input size="large" placeholder="Enter user first name" />
      </Form.Item>

      <Form.Item
        name="lastName"
        label="Last Name"
        rules={[
          required(),
          minMax({ text: "User last name", min: 2, max: 80 }),
          allowOnlyCharsDashAndQuotes,
        ]}
      >
        <Input size="large" placeholder="Enter user last name" />
      </Form.Item>
      <Form.Item
        name="position"
        rules={[
          required(),
          minMax({ text: "User position", min: 2, max: 255 }),
        ]}
        label="Position/Title"
      >
        <Input size="large" placeholder="Enter user position" />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[required(), email, minMax({ text: "User email", max: 255 })]}
        label="Email"
      >
        <Input size="large" placeholder="Enter user email" />
      </Form.Item>
    </Form>
  );
};

export default UserForm;
