import { Form, FormInstance } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getProviderOptionsApi } from "../../../api/cc-variables.api";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { getDynFormSelectOptions } from "../../../utils/cm.utils";
import handleRequestError from "../../../utils/handleRequestError";

const useGetOptionsHook = ({
  companyId,
  campaignId,
  stepId,
  phaseId,
  restValuesProvider,
  dependsOn,
  form,
  name,
}: {
  companyId: number | undefined;
  campaignId: number;
  phaseId: number;
  stepId: number;
  restValuesProvider: string;
  dependsOn: string[];
  form: FormInstance<any>;
  name: string;
}) => {
  //subscribe to form changes, then get only dependsOn fields and use them in effect
  Form.useWatch([], form);
  const depsMapping: Record<string, any> = form.getFieldsValue(dependsOn);
  const deps = Object.values(depsMapping);
  const messageApi = useSelector(selectMessageApi);
  const [isFetching, setIsFetching] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [selectOptions, setSelectOptions] = useState<
    { label: string; value: number | string | null }[] | undefined
  >(undefined);
  const paramsToReplace: Record<string, any> = {
    ...depsMapping,
    companyId,
    campaignId,
    phaseId,
    stepId,
    // classes: classes?.length ? classes.join(",") : "",
    promptKey: form.getFieldValue("promptKey"),
  };

  useEffect(() => {
    const allNotNullOrUndefined = deps.every(
      (element: any) => element !== null && element !== undefined,
    );
    //if all elems in deps array, replace placeholder ($someValue$) with value
    if (allNotNullOrUndefined) {
      const url = restValuesProvider.replace(/\$([^$]+)\$/g, (_, subString) => {
        //if there is no mapping with paramsToReplace - set null
        return paramsToReplace[subString] || null;
      });

      loadOptions(url);
    }
  }, deps);

  const loadOptions = async (url: string) => {
    try {
      setIsFetching(true);

      const { data } = await getProviderOptionsApi({ url });
      const options = getDynFormSelectOptions({ data });

      setSelectOptions(options);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      isFirstRender
        ? setIsFirstRender(false)
        : form.setFieldValue(name, undefined);
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    selectOptions,
  };
};

export default useGetOptionsHook;
