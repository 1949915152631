import { Spin } from "antd";
import { FC } from "react";
import { Badge } from "src/components/common/ui/badge";
import { MicrositeState } from '../../../globalTypes';

const stateLabels: Record<
  MicrositeState,
  { label: string; className: string }
> = {
  INITIAL: { label: "INITIAL", className: "!text-gray-500 !bg-gray-100" },
  PENDING_VALIDATION: {
    label: "PENDING VALIDATION",
    className: "!text-gray-500 !bg-gray-100",
  },
  CREATED: { label: "CREATED", className: "!text-green-500 !bg-green-100" },
  ERROR: { label: "ERROR", className: "!bg-red-100 !text-red-900" },
};

export const CustomSiteStateBadge: FC<{ state: MicrositeState }> = ({
  state,
}) => {
  return (
    <Badge className={`${stateLabels[state].className} ml-2`}>
      {state === "PENDING_VALIDATION" && <Spin size="small" />}
      {stateLabels[state].label}
    </Badge>
  );
};
