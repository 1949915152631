import { Checkbox, Form, FormInstance, Input, Select } from "antd";
import { FC } from "react";
import { docTemplateStateBadgeSettings } from "../../constants";
import { DocumentTemplateState } from "../../types/docTemplates";
import { fetchTemplateClassesOptions } from "../../utils/apiUtils";
import SearchableMultiSelect from "../common/SearchableMultiSelect";

export type TemplateSettingForm = {
  name: string;
  classes: string[];
  state: DocumentTemplateState;
  includeCampaignStructure: boolean | null;
};

type Props = {
  className?: string;
  templateId?: string;
  form: FormInstance<TemplateSettingForm>;
};

const TemplateSettingsForm: FC<Props> = ({ className, templateId, form }) => {
  const handleSelectClasses = (classes: string[]) => {
    form.setFieldValue("classes", classes);
  };

  const stateOptions = [
    {
      value: DocumentTemplateState.DRAFT,
      label: docTemplateStateBadgeSettings[DocumentTemplateState.DRAFT].label,
    },
    {
      value: DocumentTemplateState.SYSTEM,
      label: docTemplateStateBadgeSettings[DocumentTemplateState.SYSTEM].label,
    },
    {
      value: DocumentTemplateState.PUBLISHED,
      label:
        docTemplateStateBadgeSettings[DocumentTemplateState.PUBLISHED].label,
    },
  ];

  return (
    <div className={className}>
      <Form
        key={templateId !== undefined ? templateId : "create_state"}
        name="userForm"
        form={form}
        layout="vertical"
        initialValues={{
          state: DocumentTemplateState.DRAFT,
        }}
      >
        <Form.Item
          name="name"
          label={
            <span className="text-[#475569] font-sans font-semibold text-[16px]">
              Template name
            </span>
          }
          tooltip="Please enter a value between 2 and 255 characters."
          rules={[
            {
              required: true,
              message: "Required field!",
              transform: (value) => value?.trim(),
            },
            {
              max: 255,
              min: 2,
              message:
                "The template name cannot be shorter than 2 or longer than 255 characters!",
              transform: (value) => value?.trim(),
            },
          ]}
        >
          <Input
            placeholder="Enter unique template name"
            className="h-[48px]"
          />
        </Form.Item>
        <Form.Item
          name="state"
          label={
            <span className="text-[#475569] font-sans font-semibold text-[16px]">
              Template state
            </span>
          }
          rules={[
            {
              required: true,
              message: "Required field!",
            },
          ]}
        >
          <Select
            className="h-[48px]"
            options={stateOptions}
            placeholder="Choose template state"
          />
        </Form.Item>
        <Form.Item
          name="classes"
          label={
            <span className="text-[#475569] font-sans font-semibold text-[16px]">
              Template classes
            </span>
          }
        >
          <SearchableMultiSelect
            fetchOptions={fetchTemplateClassesOptions}
            onSelect={handleSelectClasses}
          />
        </Form.Item>
        <Form.Item>
          <Form.Item
            name="includeCampaignStructure"
            valuePropName="checked"
            label={
              <span className="text-[#475569] font-sans font-semibold text-[16px]">
                Campaign structure
              </span>
            }
          >
            <Checkbox>Include</Checkbox>
          </Form.Item>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TemplateSettingsForm;
