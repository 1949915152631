import React, { FC } from "react";
import { TUserPermissionGroupFormValues } from "../../types/securityGroupes";
import { Form, FormInstance, Input } from "antd";
import { minMax, required } from "../../utils/validations";

type Props = {
  form: FormInstance<TUserPermissionGroupFormValues>;
  isSuperUser: boolean;
  initialValues?: TUserPermissionGroupFormValues;
};
const SecurityGroupForm: FC<Props> = ({ initialValues, form, isSuperUser }) => {
  return (
    <Form
      name="securityGroupForm"
      form={form}
      layout="vertical"
      initialValues={initialValues}
      className="w-[35%]"
    >
      <Form.Item
        name="title"
        label={
          <span className="text-[#475569] font-sans font-semibold text-[16px]">
            Title
          </span>
        }
        tooltip="Please enter a value between 2 and 255 characters."
        rules={[required(true), minMax({ min: 2, max: 255, text: "Title" })]}
      >
        <Input
          placeholder="Enter unique title"
          disabled={isSuperUser}
          className="h-[48px]"
        />
      </Form.Item>
      {isSuperUser && (
        <span className="text-[10px] italic text-slate-500">
          *the super user cannot be edited
        </span>
      )}
    </Form>
  );
};

export default SecurityGroupForm;
