import { Modal } from "antd";
import { FC } from "react";
import { Label } from "src/components/common/ui/label";
import useSubmitFormOnEnter from "src/hooks/useSubmitFormOnEnter";
import { Textarea } from "../ui/textarea";
import { DocumentTemplate } from "../../../types/docTemplates";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  keys: DocumentTemplate["keys"];
  name: string;
};

export const ViewKeysModal: FC<Props> = ({
  open,
  setOpen,
  name,
  keys,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  useSubmitFormOnEnter(() => handleClose());

  return (
    <Modal
      title={`View Keys for ${name}`}
      open={open}
      onOk={handleClose}
      onCancel={handleClose}
      className="w-[700px]"
    >
      <div className="font-sans flex flex-col gap-3 mt-3">
        <Label>Template Keys</Label>
        <Textarea value={keys?.join(", ") || ""} disabled rows={15} />
      </div>
    </Modal>
  );
};
