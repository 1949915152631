import React, { FC } from "react";
import { Skeleton } from "antd";

type Props = {
  className?: string;
  quantity: number;
};
const SkeletonList: FC<Props> = ({ className, quantity }) => {
  return (
    <div className={className || "flex flex-col gap-[12px]"}>
      {Array.from({ length: quantity }).map((_, index) => (
        <Skeleton key={index} active />
      ))}
    </div>
  );
};

export default SkeletonList;
