import React from "react";
import { TFileTreeItem } from "src/globalTypes";
import File from "./File";
import Folder from "./Folder";
import { GetMenuActions } from "./hooks/types";
import clsx from "classnames";

type Props = {
  treeData: TFileTreeItem[];
  onExpandFolder: (folder: TFileTreeItem) => void;
  onSelectedItemChange: (item: TFileTreeItem | null) => void;
  selectedItem: TFileTreeItem | null;
  getItemMenuActions: GetMenuActions;
  setTreeData: (tree: TFileTreeItem[]) => void;
};
const StaticStorageFileTree: React.FC<Props> = ({
  treeData,
  onExpandFolder,
  onSelectedItemChange,
  selectedItem,
  getItemMenuActions,
  setTreeData,
}) => {
  const renderTreeItem = React.useCallback(
    (item: TFileTreeItem, level: number) => {
      const { fileName, folder, expanded, isRootDir } = item;
      const commonProps = {
        selectedItem,
        item,
        onSelectItem: () => onSelectedItemChange(item),
        isSelected: selectedItem?.fileName === fileName,
        getItemMenuActions,
        setTreeData,
      };

      return (
        <div
          key={fileName}
          className={level === 0 && !isRootDir ? "mt-[16px]" : ""}
        >
          {folder ? (
            <>
              <Folder
                {...commonProps}
                item={item}
                onExpandFolder={onExpandFolder}
              />
              {expanded &&
                renderTree(
                  treeData.filter((i) => i.parentId === fileName),
                  level + 1,
                )}
            </>
          ) : (
            <File {...commonProps} />
          )}
        </div>
      );
    },
    [treeData, selectedItem, onSelectedItemChange, onExpandFolder],
  );
  const renderTree = React.useCallback(
    (items: TFileTreeItem[], level = 0) => {
      if (!items.length) return null;

      return (
        <div
          className={clsx(
            "font-medium text-[14px] text-[#475569] flex flex-col gap-[16px]",
            {
              "mt-[16px]": level !== 0,
            },
          )}
          style={{
            marginLeft: level === 0 ? 0 : `${level + 20}px`,
          }}
        >
          {items.map((item) => renderTreeItem(item, level))}
        </div>
      );
    },
    [renderTreeItem],
  );
  return <div>{renderTree(treeData.filter((item) => !item.parentId))}</div>;
};
export default React.memo(StaticStorageFileTree);
