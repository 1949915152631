import { Node } from "reactflow";

type GroupedNodes = {
  [key: string]: Node<any>[];
};

type TMaxPoints = Record<
  string,
  {
    maxX: number;
    maxY: number;
  }
>;

type TMinPoints = Record<
  string,
  {
    minX: number;
    minY: number;
  }
>;

const getGroups = (nodes: Node<any>[]) => {
  const grouped: GroupedNodes = nodes.reduce(
    (acc: GroupedNodes, item: Node<any>) => {
      const { parentId } = item;

      if (parentId) {
        if (!acc[parentId]) {
          acc[parentId] = [];
        }
        acc[parentId].push(item);
      }
      return acc;
    },
    {}
  );

  return Object.values(grouped);
};

export const getMinPoints = (nodes: Node<any>[]) => {
  const groups = getGroups(nodes);
  const minPoints: TMinPoints = {};

  groups.forEach((group) => {
    const xValues = group.map((item) => item.position.x);
    const yValues = group.map((item) => item.position.y);
    const parentId = group[0].parentId as string;

    minPoints[parentId] = {
      minX: Math.min(...xValues),
      minY: Math.min(...yValues),
    };
  });
  return minPoints;
};

export const getMaxPoints = (nodes: Node<any>[]) => {
  const groups = getGroups(nodes);
  const maxPoints: TMaxPoints = {};

  groups.forEach((group) => {
    const xValues = group.map((item) => item.position.x);
    const yValues = group.map((item) => item.position.y);
    const parentId = group[0].parentId as string;

    maxPoints[parentId] = {
      maxX: Math.max(...xValues),
      maxY: Math.max(...yValues),
    };
  });
  return maxPoints;
};
