import { ConfigProvider, message, Modal, Spin } from "antd";
import { usePostHog } from "posthog-js/react";
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setMessageApi, setModalApi } from "../../store/slices/appSlice";
import { loginThunk, logoutThunk } from "../../store/slices/userDataSlice";
import { AppDispatch } from "../../store/store";
import localStorageProvider from "../../utils/localStorageProvider";

const InitializeApp: FC<{ children: React.ReactNode }> = ({ children }) => {
  const dispatch: AppDispatch = useDispatch();
  const [messageApi, messageContext] = message.useMessage();
  const [modal, modalContext] = Modal.useModal();
  const posthog = usePostHog();
  const [initialDataIsFetched, setInitialDataIsFetched] = useState(false);
  const navigate = useNavigate();
  const { pathname, search, hash } = useLocation();

  useEffect(() => {
    const initLogin = async () => {
      try {
        const { email } = await dispatch(loginThunk()).unwrap();
        const lastUrl = localStorageProvider.getLastUrl();

        posthog?.identify(email);

        if (pathname === "/" && lastUrl) {
          navigate(lastUrl);
        }
      } catch (e) {
        localStorageProvider.setLastUrl(pathname + search + hash);

        await dispatch(logoutThunk());
        navigate("/login");

        console.error("Auth error:", e);
      } finally {
        dispatch(setMessageApi(messageApi));
        dispatch(setModalApi(modal));
        setInitialDataIsFetched(true);
      }
    };
    initLogin();
  }, []);

  if (!initialDataIsFetched) return <Spin spinning={true} fullscreen />;

  return (
    <>
      {messageContext}
      {modalContext}
      <ConfigProvider theme={{ token: { fontFamily: "Geist" } }}>
        {children}
      </ConfigProvider>
    </>
  );
};

export default InitializeApp;
