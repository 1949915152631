import { Button, Flex } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TLabelsListData } from "../../../globalTypes";
import { getMessageApi } from "../../../store/slices/appSlice";
import {
  getGlobalLabels,
  getGlobalLabelsThunk,
} from "../../../store/slices/globalLabelsSlice";
import { AppDispatch } from "../../../store/store";
import AddLabel from "../AddLabel/AddLabel";
import FindDuplicates from "../FindDuplicates/FindDuplicates";
import LabelsHeader from "../LabelsHeader/LabelsHeader";
import LabelsList from "../LabelsList/LabelsList";
import { PlusOutlined } from "@ant-design/icons";
import { convertLabelsToArray } from "../../../utils/cm.utils";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";

const GlobalLabels = () => {
  const dispatch: AppDispatch = useDispatch();
  const [editModeProps, setEditModeProps] = useState<TLabelsListData | null>(
    null,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const messageApi = getMessageApi();
  const labels = getGlobalLabels();
  const labelsList = convertLabelsToArray(labels);
  const { hasG18nCreateRole, hasG18nUpdateRole } = useUserHasPermission({});

  useEffect(() => {
    const getLabels = async () => {
      try {
        const res = await dispatch(getGlobalLabelsThunk());

        if ("error" in res) {
          messageApi.error(res.payload?.message);
        }
      } catch (e: any) {
        messageApi.error(e?.message);
        console.error(e);
      }
    };

    getLabels();
  }, []);

  return (
    <Flex
      vertical
      gap="middle"
      style={{
        width: "100%",
      }}
    >
      <LabelsHeader />
      <Flex gap="middle">
        {hasG18nCreateRole && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setEditModeProps(null);
              setIsModalOpen(true);
            }}
          >
            Add
          </Button>
        )}

        <FindDuplicates />
      </Flex>
      <LabelsList
        labelsList={labelsList}
        setEditModeProps={setEditModeProps}
        setIsModalOpen={setIsModalOpen}
      />

      {(hasG18nCreateRole || hasG18nUpdateRole) && isModalOpen && (
        <AddLabel
          //need key because form.resetFields() doesn't work properly
          key={editModeProps ? "edit" : "create"}
          setEditModeProps={setEditModeProps}
          labelData={editModeProps}
          labelsKeysLowerCase={labelsList.map((label) =>
            label.labelKey.toLowerCase(),
          )}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
        />
      )}
    </Flex>
  );
};

export default GlobalLabels;
