import { Empty, Skeleton } from "antd";
import { Outlet } from "react-router-dom";
import useGetCompanyDetailsHook from "./useGetCompanyDetails.hook";
import React from "react";
import CompanyDetailsInfo from "../../../components/companies/CompanyDetailsInfo";
import CompanyDetailsTabs from "../../../components/companies/CompanyDetailsTabs";
import CompanyDetailsHeader from "../../../components/companies/CompanyDetailsHeader";

const CompanyDetailsPage = () => {
  const { currentCompany, activeCampaigns, activeUsers, error, isInitialized } =
    useGetCompanyDetailsHook();

  if (!isInitialized) return <Skeleton active />;

  return (
    <div className="font-sans">
      <CompanyDetailsHeader error={error} isInitialized={isInitialized} />

      {error && <div className="text-red-600">Error: {error}</div>}

      {currentCompany ? (
        <div className="flex flex-col gap-6">
          <CompanyDetailsInfo
            companyData={currentCompany}
            activeCampaigns={activeCampaigns}
            activeUsers={activeUsers}
          />

          <CompanyDetailsTabs />

          {/*CompanyUsersPage or CustomerSitePage rendered here*/}
          <Outlet />
        </div>
      ) : (
        <Empty
          description={
            <div>
              <span>There is no information about this company</span>
            </div>
          }
        />
      )}
    </div>
  );
};

export default CompanyDetailsPage;
