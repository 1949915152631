import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MessageInstance } from "antd/es/message/interface";
import { HookAPI } from "antd/es/modal/useModal";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const initialState = {
  isFetching: false,
  messageApi: {} as MessageInstance,
  modalApi: {} as HookAPI,
};

type InitialStateType = typeof initialState;

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleIsFetching: (
      state: InitialStateType,
      action: PayloadAction<boolean>
    ) => {
      state.isFetching = action.payload;
    },
    setMessageApi: (
      state: InitialStateType,
      action: PayloadAction<MessageInstance>
    ) => {
      state.messageApi = action.payload;
    },
    setModalApi: (state: InitialStateType, action: PayloadAction<HookAPI>) => {
      state.modalApi = action.payload;
    },
  },
});

export const { toggleIsFetching, setMessageApi, setModalApi } =
  appSlice.actions;

export default appSlice.reducer;

/* eslint-disable*/
export const getIsFetching = (): boolean =>
  useSelector((state: RootState) => state.app.isFetching);
export const getMessageApi = (): MessageInstance =>
  useSelector((state: RootState) => state.app.messageApi);
export const selectMessageApi = (state: RootState) => state.app.messageApi;
export const getModalApi = (): HookAPI =>
  useSelector((state: RootState) => state.app.modalApi);
