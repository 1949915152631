import React from "react";
import { Navigate, Route, Routes } from 'react-router-dom';
import ProtectedByRoleRoute from "../ProtectedByRoleRoute";
import { UserPermissions } from "../../store/slices/userDataSlice";
import CampaignLabels from "../../components/campaignLabels/CampaignLabels/CampaignLabels";

const CampaignLabelsRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_CAMPAIGN_I18N_LIST}
            isCompanyRole={true}
          >
            <CampaignLabels />
          </ProtectedByRoleRoute>
        }
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export default CampaignLabelsRoutes;
