import { Spin, Tooltip } from "antd";
import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editCcItemKeyThunk,
  TCcVariableType,
  useCcVariables,
} from "../../../../store/slices/ccVariablesSlice";
import { AppDispatch } from "../../../../store/store";
import { Badge } from "../../../common/ui/badge";
import { campaignStatusBadgeSettings } from "../../../../constants";
import { useUserHasPermission } from "../../../../hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import ModifiedBy from "../../../common/ModifiedBy";
import handleRequestError from "../../../../utils/handleRequestError";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import TextArea from "antd/es/input/TextArea";

type Props = {
  itemKey: string;
  type: TCcVariableType;
  modifiedBy: null | number;
  modifiedTime: string;
};

const GridItemKey: FC<Props> = ({
  itemKey,
  type,
  modifiedBy,
  modifiedTime,
}) => {
  const { companyId } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const ccVars = useCcVariables();
  const [value, setValue] = useState(itemKey);
  const [isFetching, setIsFetching] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState("");
  const messageApi = useSelector(selectMessageApi);
  const { hasCampaignCcRenameRole } = useUserHasPermission({ companyId });

  const onBlur = async () => {
    if (isOpened) {
      setValue(itemKey);
      setIsOpened(false);

      return;
    }

    if (value === itemKey) return;

    try {
      setIsFetching(true);

      await dispatch(
        editCcItemKeyThunk({ currentKey: itemKey, newKey: value }),
      ).unwrap();

      messageApi.success("The key was successfully renamed");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);

      setValue(itemKey);
    } finally {
      setIsFetching(false);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    const lowerCaseValue = value.toLowerCase();

    if (!value?.length) {
      setValue(value);
      setTooltipTitle(
        "The value should not be empty. The changes will not be saved!",
      );
      setIsOpened(true);

      return;
    }

    if (!/^[a-zA-Z_$][a-zA-Z0-9_$]*$/.test(value)) {
      setValue(value);
      setTooltipTitle(
        "Invalid Javascript variable name. The changes will not be saved!",
      );
      setIsOpened(true);

      return;
    }

    if (
      itemKey.toLowerCase() !== lowerCaseValue &&
      ccVars.map((item) => item.id.key.toLowerCase()).includes(lowerCaseValue)
    ) {
      setValue(value);
      setTooltipTitle(
        "The key already exists in this grid. The changes will not be saved!",
      );
      setIsOpened(true);

      return;
    }

    setValue(value);
    setTooltipTitle("");
    setIsOpened(false);
  };

  return (
    <div id={itemKey} className="p-[3px]">
      {hasCampaignCcRenameRole ? (
        <div>
          <Spin size="small" spinning={isFetching}>
            <Tooltip title={tooltipTitle} color={"red"} open={isOpened}>
              <TextArea
                autoSize
                value={value.length ? value : undefined}
                placeholder="not defined"
                onBlur={onBlur}
                onChange={onChange}
                disabled={isFetching}
                className="hover:border-indigo-600 border-transparent"
              />
            </Tooltip>
          </Spin>
        </div>
      ) : (
        <div className="ml-[8px]">{itemKey}</div>
      )}
      <div className="ml-[8px] mb-4 mt-1">
        <Badge
          className={
            campaignStatusBadgeSettings[type]?.className ||
            "bg-orange-100 text-orange-500"
          }
          variant={"table"}
        >
          {type}
        </Badge>
      </div>
      <ModifiedBy modifiedBy={modifiedBy} modifiedTime={modifiedTime} />
    </div>
  );
};

export default GridItemKey;
