import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import handleRequestError from "../../../../utils/handleRequestError";

type Props = {
  getFileData: (path: string) => Promise<string>;
};
const useGetLink = ({ getFileData }: Props) => {
  const messageApi = useSelector(selectMessageApi);
  return async (path: string) => {
    try {
      messageApi.open({
        key: "getting_file_link",
        type: "loading",
        content: "Getting the file link...",
      });
      const getFileLink = async (path: string) => {
        const getLink = async () => {
          return await getFileData(path);
        };
        if (typeof ClipboardItem && navigator.clipboard.write) {
          const text = new ClipboardItem({
            "text/plain": getLink().then((url) => {
              return new Blob([url], { type: "text/plain" });
            }),
          });
          await navigator.clipboard.write([text]);
        } else {
          const url = await getLink();
          await navigator.clipboard.writeText(url as string);
        }
      };
      await getFileLink(path);
      messageApi.open({
        key: "getting_file_link",
        type: "success",
        content: "The link to the file has been copied to the clipboard",
      });
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(customError);
      messageApi.open({
        key: "getting_file_link",
        type: "error",
        content: customError.message,
      });
    }
  };
};
export default useGetLink;
