import { TEnvVariableType } from "../store/slices/envVariablesSlice";
import { TCcVariableType } from "../store/slices/ccVariablesSlice";

const IGNORE_REDIRECTS_TO = ["/login", "/"];
const ENV_VAR_TYPE_LOCAL_STORAGE_KEY = "envVarType";
const CC_VAR_TYPE_LOCAL_STORAGE_KEY = "ccVarType";
const LAST_URL_LOCAL_STORAGE_KEY = "lastUrl";
const UPDATE_DOWNSTREAM_DEPENDENCIES_LOCAL_STORAGE_KEY =
  "updateDownstreamDependencies";

const localStorageProvider = {
  getEnvType: () => localStorage.getItem(ENV_VAR_TYPE_LOCAL_STORAGE_KEY),
  setEnvType: (item: TEnvVariableType) =>
    localStorage.setItem(ENV_VAR_TYPE_LOCAL_STORAGE_KEY, JSON.stringify(item)),
  getCCType: () => localStorage.getItem(CC_VAR_TYPE_LOCAL_STORAGE_KEY),
  setCCType: (item: TCcVariableType) =>
    localStorage.setItem(CC_VAR_TYPE_LOCAL_STORAGE_KEY, JSON.stringify(item)),
  getLastUrl: () => localStorage.getItem(LAST_URL_LOCAL_STORAGE_KEY),
  setLastUrl: (url: string) => {
    const shouldIgnorePath = IGNORE_REDIRECTS_TO.includes(url);
    if (!shouldIgnorePath) {
      localStorage.setItem(LAST_URL_LOCAL_STORAGE_KEY, url);
    }
  },
  getUpdateDownstreamDependencies: () => {
    const value = localStorage.getItem(
      UPDATE_DOWNSTREAM_DEPENDENCIES_LOCAL_STORAGE_KEY,
    );
    return value === "true";
  },
  setUpdateDownstreamDependencies: (value: boolean) =>
    localStorage.setItem(
      UPDATE_DOWNSTREAM_DEPENDENCIES_LOCAL_STORAGE_KEY,
      JSON.stringify(value),
    ),
};
export default localStorageProvider;
