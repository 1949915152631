import { FC } from "react";
import { Link } from "react-router-dom";
import { Icons } from "../common/Icons";
import { Button } from "../common/ui/button";
import { useUserHasPermission } from '../../hooks/useUserHasPermission';

type Props = {};

const ModelConfigsHeader: FC<Props> = () => {
  const { hasAiConfigurationCreateRole } =
    useUserHasPermission({});
  return (
    <>
      <div className="flex justify-between">
        <h1 className=" text-slate-900 text-2xl font-semibold leading-normal font-sans">
          Model Configs
        </h1>
        {hasAiConfigurationCreateRole && (
          <div className="flex gap-3">
            <Link to="/model-configs/create">
              <Button
                icon={Icons.Plus}
                className="rounded-full font-sans"
                size={"sm"}
              >
                Create
              </Button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default ModelConfigsHeader;
