import { Spin } from "antd";
import "reactflow/dist/style.css";
import { Icons } from "../../components/common/Icons";
import { Button } from "../../components/common/ui/button";
import EditorPane from "../../components/graph/EditorPane";
import Graph from "../../components/graph/Graph";
import useComputationEvents from "./hooks/useComputationEvents.hook";
import useGetNodesHook from "./hooks/useGetNodes.hook";

const GraphPage = () => {
  const {
    edges,
    nodes,
    isInitialized,
    initialNodeId,
    inputNode,
    targetNode,
    stepPhaseMetaInfo,
    isTargetLoading,
    isInputLoading,
    onGoBack,
    changeNodeProps,
    setInputNode,
    setTargetNode,
    // companyId,
    // campaignId,
  } = useGetNodesHook();

  useComputationEvents({
    changeNodeProps,
    setInputNode,
    setTargetNode,
    inputNodeId: inputNode ? `${inputNode.id.key}_${inputNode.id.stepId}` : null,
    targetNodeId: targetNode ? `${targetNode.id.key}_${targetNode.id.stepId}` : null
  });

  const graphHeight = ((window.innerHeight - 200) / 3) * 2; //200 - approximate value

  return (
    <div className="relative w-full h-full">
      <div className="flex justify-between items-center">
        <div className="flex gap-[16px] items-center mb-[12px]">
          <Button
            variant={"ghost"}
            icon={Icons.back}
            onClick={onGoBack}
            className="p-[12px] h-auto"
          />
          <h1 className="text-slate-900 text-2xl font-semibold font-sans leading-normal">
            Prompt Engineering
          </h1>
        </div>
        {/*{campaignId && (*/}
        {/*  <ComputationMessages*/}
        {/*    campaignId={+campaignId}*/}
        {/*    companyId={companyId}*/}
        {/*  />*/}
        {/*)}*/}
      </div>
      <Spin spinning={!isInitialized} tip="Loading the data...">
        <div className="w-full border-2" style={{ height: `${graphHeight}px` }}>
          <Graph
            nodes={nodes}
            edges={edges}
            initialNodeId={initialNodeId}
            isInitialized={isInitialized}
          />
        </div>
        <EditorPane
          isTargetLoading={isTargetLoading}
          isInputLoading={isInputLoading}
          inputNode={inputNode}
          targetNode={targetNode}
          stepPhaseMetaInfo={stepPhaseMetaInfo}
          changeNodeProps={changeNodeProps}
          setInputNode={setInputNode}
          setTargetNode={setTargetNode}
        />
      </Spin>
    </div>
  );
};

export default GraphPage;
