import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getModelConfigApi } from "src/api/model-configs.api";
import { ItemHeader } from "src/components/common/ItemHeader";
import ModelConfigForm from "src/components/modelConfigs/ModelConfigForm";
import { ModelConfigFormValues } from "src/types/modelConfigs";

const EditModelConfigPage = () => {
  const { modelConfigId } = useParams();
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate("/model-configs");
  };

  const [modelConfigValues, setModelConfig] = useState<
    ModelConfigFormValues | undefined
  >();

  useEffect(() => {
    const getModelConfig = async () => {
      const {
        data: { id, ...values },
      } = await getModelConfigApi(Number(modelConfigId));

      setModelConfig(values);
    };

    if (modelConfigId) {
      getModelConfig();
    }
  }, [modelConfigId]);

  return (
    <>
      <ItemHeader title="Edit Model Config" clickBack={navigateBack} />
      {!modelConfigValues && <Spin />}
      {modelConfigValues && (
        <ModelConfigForm
          modelConfigId={modelConfigId}
          initialValues={modelConfigValues}
        />
      )}
    </>
  );
};

export default EditModelConfigPage;
