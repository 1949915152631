import clsx from "clsx";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import packageJson from "../../../package.json";
import logo from "../../assets/images/logo_horizontal.svg";
import UserAvatar from "../common/Avatar";
import VersionButton from "../common/VersionButton";
import ReleasesPopup from "./ReleasesPopup";
import { useSelector } from "react-redux";
import {
  selectUserData,
  UserPermissions,
} from "../../store/slices/userDataSlice";
import { useUserHasPermission } from "../../hooks/useUserHasPermission";

type HeaderNavItem = {
  label: string;
  active: boolean;
  path: string;
};

const Header = () => {
  const { pathname } = useLocation();
  const user = useSelector(selectUserData);
  const {
    hasDocumentTemplateListRole,
    hasAiConfigurationListRole,
    hasPermGroupListRole,
    hasPermissionAnywhere,
    hasUiBuildInfoRole,
  } = useUserHasPermission({});
  const [isModalOpened, setIsModalOpened] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpened(true);
  };

  const navDataList = [
    hasPermissionAnywhere(UserPermissions.ROLE_COMPANY_LIST) && {
      label: "Companies",
      active: pathname.startsWith("/companies"),
      path: "/companies",
    },
    hasPermissionAnywhere(UserPermissions.ROLE_USER_LIST) && {
      label: "Users",
      active: pathname.startsWith("/users"),
      path: "/users",
    },
    hasPermissionAnywhere(UserPermissions.ROLE_CAMPAIGN_LIST) && {
      label: "Campaigns",
      active: pathname.startsWith("/campaigns"),
      path: "/campaigns",
    },
    hasDocumentTemplateListRole && {
      label: "Templates",
      active: pathname.startsWith("/templates/"),
      path: "/templates/documents",
    },
    hasAiConfigurationListRole && {
      label: "Model Configs",
      active: pathname.startsWith("/model-configs"),
      path: "/model-configs",
    },
    {
      label: "Activity",
      active: pathname.startsWith("/activities"),
      path: "/activities",
    },
    hasPermGroupListRole && {
      label: "Security",
      active: pathname.startsWith("/security-groups"),
      path: "/security-groups",
    },
  ].filter(Boolean) as HeaderNavItem[];

  const navList = navDataList.map(({ path, label, active }) => {
    return (
      <Link to={path} key={path}>
        <div
          className={clsx(
            "inline-flex items-center font-sans font-semibold transition-colors text-[#334155] text-[14px] h-[64px] mx-[24px] border-y-transparent hover:text-[#4F46E5] border-y-[3px]",
            {
              "border-b-primary": active,
            },
          )}
        >
          <span className={active ? "text-primary" : ""}>{label}</span>
        </div>
      </Link>
    );
  });

  return (
    <>
      <div className="w-full h-[64px] flex items-center border-b border-[#E2E8F0] font-sans justify-between px-[48px]">
        <div className="flex gap-[8px]">
          <img src={logo} alt="App logo" />
          <div>{navList}</div>
        </div>
        <div>
          <div className="flex gap-[24px] items-center">
            <UserAvatar user={user} />
            {hasUiBuildInfoRole && (
              <VersionButton
                label={`v${packageJson.version}`}
                onClick={handleModalOpen}
              />
            )}
          </div>
        </div>
      </div>
      {isModalOpened && hasUiBuildInfoRole && (
        <ReleasesPopup
          setIsModalOpened={setIsModalOpened}
          isModalOpened={isModalOpened}
        />
      )}
    </>
  );
};

export default Header;
