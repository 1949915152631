import { MicrositeCompanyAssets, TMicroSite } from "src/globalTypes";
import axiosInstance from "src/utils/axios/axiosInstance";

export const createMicrositeApi = ({
  companyId,
  enableHttps,
  siteContext,
  subDomain,
}: {
  subDomain: string;
  siteContext: string;
  enableHttps: boolean;
  companyId: number | string;
}) => {
  return axiosInstance.post<TMicroSite>(
    `/api/secured/company/${companyId}/microsite/`,
    {
      subDomain,
      companyId: Number(companyId),
      siteContext,
      enableHttps,
    },
  );
};

export const deleteMicrositeApi = ({
  companyId,
  subDomain,
}: {
  subDomain: string;
  companyId: number | string;
}) => {
  return axiosInstance.delete<void>(
    `/api/secured/company/${companyId}/microsite/${subDomain}`,
  );
};

export const getMicrositeApi = ({
  companyId,
}: {
  companyId: number | string;
}) => {
  return axiosInstance.get<TMicroSite | null>(
    `/api/secured/company/${companyId}/microsite/`,
  );
};

export const getMicrositeCompanyAssetsApi = ({
  companyId,
}: {
  companyId: number | string;
}) => {
  return axiosInstance.get<MicrositeCompanyAssets>(
    `/api/secured/company/${companyId}/microsite/assets`,
  );
};
