import { Dispatch, SetStateAction, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { TCcVariable } from "../../../store/slices/ccVariablesSlice";
import {
  connectSocketThunk,
  disconnectSocketThunk,
  getUpdateMessages,
  setSocketShouldReconnect,
} from "../../../store/slices/computationMessagesSlice";
import { AppDispatch } from "../../../store/store";
import { TUpdateNodeProps } from "./useGetNodes.hook";

type Props = {
  changeNodeProps: ({
    nodeId,
    props,
  }: {
    nodeId: string;
    props: TUpdateNodeProps;
  }) => void;
  setInputNode: Dispatch<SetStateAction<TCcVariable | null>>;
  setTargetNode: Dispatch<SetStateAction<TCcVariable | null>>;
  inputNodeId: string | null;
  targetNodeId: string | null;
};

const useComputationEvents = ({
  changeNodeProps,
  setTargetNode,
  setInputNode,
  inputNodeId,
  targetNodeId,
}: Props) => {
  const { campaignId } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const eventMessagesMap = getUpdateMessages();

  useEffect(() => {
    if (campaignId) {
      dispatch(setSocketShouldReconnect(true));
      dispatch(
        connectSocketThunk({ campaignId: +campaignId, onlyCurrentStep: false }),
      );
    }

    return () => {
      dispatch(disconnectSocketThunk());
    };
  }, [campaignId]);

  useEffect(() => {
    Object.entries(eventMessagesMap).forEach(([eventKey, { item }]) => {
      if (
        item &&
        (eventKey.startsWith("CI_UPDATED") || eventKey.startsWith("CI_ERROR"))
      ) {
        const updatedNodeId = `${item.id.key}_${item.id.stepId}`;

        // Update target and input nodes in section
        if (updatedNodeId === targetNodeId) {
          setTargetNode(item);
        }

        if (updatedNodeId === inputNodeId) {
          setInputNode(item);
        }

        // Update node in graph
        changeNodeProps({
          nodeId: updatedNodeId,
          props: { touched: false, state: item.state },
        });
      }
    });
  }, [
    eventMessagesMap,
    targetNodeId,
    inputNodeId,
    setTargetNode,
    setInputNode,
    changeNodeProps,
  ]);
};

export default useComputationEvents;
