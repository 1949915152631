import React from "react";
import { Navigate, Route, Routes } from 'react-router-dom';
import GlobalLabels from "../../components/globalLabels/GlobalLabels/GlobalLabels";
import { UserPermissions } from "../../store/slices/userDataSlice";
import ProtectedByRoleRoute from "../ProtectedByRoleRoute";

const GlobalLabelsRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedByRoleRoute permission={UserPermissions.ROLE_G18N_LIST}>
            <GlobalLabels />
          </ProtectedByRoleRoute>
        }
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export default GlobalLabelsRoutes;
