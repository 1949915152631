import logo from "../../assets/images/logo_dark.svg";
import GoogleOauth from "../../components/login/GoogleOauth/GoogleOauth";
import "./style.scss";

const LoginPage = () => {
  return (
    <div className="page page_auth">
      <div className="page__login-section">
        <div className="login-content">
          <img src={logo} alt="logo" />

          <div className="login-title">
            <p className="pre-title">Welcome</p>

            <h3>Login to your account</h3>
          </div>

          <GoogleOauth />
        </div>

        <div className="no-account-link">
          Don’t have an account yet?{" "}
          <a
            href="mailto:hello@shastaelevate.com?subject=Account Troubleshooting"
            rel="noreferrer nofollower"
            target="_blank"
          >
            Send us a note
          </a>{" "}
        </div>
      </div>

      <div className="page__bg-section" />
    </div>
  );
};

export default LoginPage;
