import { Empty } from "antd";
import { FC } from "react";
import { cn } from "src/utils";
type EmptyResultProps = {
  message: string | React.ReactNode;
  className?: string;
};

export const EmptyResult: FC<EmptyResultProps> = ({ message, className }) => {
  return (
    <div
      className={cn(
        "h-full w-full flex justify-center items-center",
        className,
      )}
    >
      <Empty
        imageStyle={{ height: "30vh" }}
        description={
          <div>
            <span>{message}</span>
          </div>
        }
      />
    </div>
  );
};
