import { TLabelLanguage, TLabels } from "../globalTypes";
import axiosInstance from "../utils/axios/axiosInstance";

export const getGlobalLabelsApi = ({ lang }: { lang: TLabelLanguage }) => {
  return axiosInstance.get<TLabels>(`/api/secured/i18n/${lang}/`);
};

export const createGlobalLabelApi = ({
  lang,
  key,
  label,
}: {
  label: string;
  key: string;
  lang: TLabelLanguage;
}) => {
  return axiosInstance.post<string>(
    `/api/secured/i18n/${lang}/${key}/`,
    label,
    {
      headers: {
        "Content-Type": "text/plain",
      },
    },
  );
};

export const updateGlobalLabelApi = ({
  lang,
  key,
  label,
}: {
  label: string;
  key: string;
  lang: TLabelLanguage;
}) => {
  return axiosInstance.put<string>(`/api/secured/i18n/${lang}/${key}/`, label, {
    headers: {
      "Content-Type": "text/plain",
    },
  });
};

export const bulkDeleteGlobalLabelsApi = ({
  keys,
  lang,
}: {
  keys: Array<string>;
  lang: TLabelLanguage;
}) => {
  return axiosInstance.delete<string>(`/api/secured/i18n/${lang}/bulk/`, {
    data: keys,
  });
};
