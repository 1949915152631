import { Skeleton } from "antd";
import { Outlet } from "react-router-dom";
import { Icons } from "../../../components/common/Icons";
import Navigation from "../../../components/common/Navigation";
import { Button } from "../../../components/common/ui/button";
import useCampaignStorageData from "./hooks/useCampaignStorageData.hook";
import useRedirectToFirstChildOutlet from "../../../hooks/navigation/useRedirectToFirstChildOutlet";

const CampaignStoragePage = () => {
  const { isLoading, error, onGoBack, navigationItems, currentCampaign } =
    useCampaignStorageData();

  useRedirectToFirstChildOutlet({ navigationItems });

  const renderContent = () => {
    if (isLoading) {
      return <Skeleton active />;
    }

    if (error) {
      return <span className="text-red-500">Error: {error}</span>;
    }

    if (currentCampaign) {
      return (
        <>
          <div>
            <div className="flex gap-[12px] items-center">
              <Button
                variant={"ghost"}
                icon={Icons.back}
                onClick={onGoBack}
                className="p-[12px] h-auto"
              />
              <div className="flex items-end gap-[8px]">
                <h1 className="text-slate-900 text-2xl font-semibold font-sans leading-normal capitalize">
                  Campaign Storage:{" "}
                  <span className="font-normal">{currentCampaign.name}</span>
                </h1>
              </div>
            </div>

            <Navigation items={navigationItems} replace={true} />
            <Outlet />
          </div>
        </>
      );
    } else {
      return (
        <span className="text-red-500">
          There is no information about the campaign!
        </span>
      );
    }
  };

  return <div>{renderContent()}</div>;
};

export default CampaignStoragePage;
