import { Editor } from "@monaco-editor/react";
import { Select, Upload, UploadProps } from "antd";
import React, { FC, memo, useState } from "react";
import { generateTemplateApi } from "../../../api/document-templates.api";
import { getMessageApi } from "../../../store/slices/appSlice";
import { downloadTextFile } from "../../../utils/cm.utils";
import handleRequestError from "../../../utils/handleRequestError";
import { DownloadIcon, UploadIcon } from "../../common/Icons";
import { Button } from "../../common/ui/button";

type Props = {
  transformTemplatesOptions: { value: number; label: string }[];
  isFetchingTemplatesOptions: boolean;
  setXSLTCode: React.Dispatch<React.SetStateAction<string | undefined>>;
  className?: string;
};

const HtmlCodeEditor: FC<Props> = ({
  transformTemplatesOptions,
  isFetchingTemplatesOptions,
  setXSLTCode,
  className,
}) => {
  const [code, setCode] = useState<string | undefined>(undefined);
  const [template, setTemplate] = useState<string | undefined>(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const messageApi = getMessageApi();

  const props: UploadProps = {
    name: "file",
    multiple: false,
    accept: ".html",
    showUploadList: false,
    beforeUpload(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const content = e.target?.result;

        if (typeof content === "string") {
          setCode(content);
        }
      };

      reader.readAsText(file);

      return false;
    },
  };

  const onGenerateTemplate = async () => {
    if (!code || !template) return;

    try {
      setIsFetching(true);

      const { data } = await generateTemplateApi({
        content: code,
        templateId: template,
      });

      setXSLTCode(data);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const onDownload = () => {
    if (!code) {
      messageApi.error("Error, no data found!");
      return;
    }

    downloadTextFile({
      data: code,
      fileName: `HTML_template_${new Date().getTime()}.html`,
      type: "text/html",
    });
  };

  return (
    <div className={className || "w-[49%]"}>
      <h2 className="text-slate-900 text-1xl font-semibold font-sans leading-normal mb-[6px]">
        HTML editor
      </h2>

      <div className="flex justify-between mb-[8px] overflow-y-scroll">
        <div className="flex gap-[12px]">
          <Select
            loading={isFetchingTemplatesOptions}
            disabled={isFetchingTemplatesOptions}
            className="w-[180px] h-[40px]"
            placeholder="Select a template"
            onChange={setTemplate}
            value={template}
            options={transformTemplatesOptions}
          />
          <Button
            variant="primaryOutline"
            className="rounded-full self-start"
            onClick={onGenerateTemplate}
            disabled={!code || !template}
            loading={isFetching}
          >
            Generate XSLT
          </Button>
        </div>

        <div className="flex gap-[16px] items-center">
          <div className="h-[20px]">
            <Upload {...props}>
              <Button
                variant="text"
                size="text"
                className="text-[#475569]"
                iconClassName="h-4 w-4 mr-[2px]"
                icon={UploadIcon}
              >
                Upload
              </Button>
            </Upload>
          </div>

          <div className="h-[20px]">
            <Button
              variant="text"
              size="text"
              className="text-[#475569]"
              iconClassName="h-4 w-4 mr-[2px]"
              icon={DownloadIcon}
              onClick={onDownload}
              disabled={!code}
            >
              Download
            </Button>
          </div>
        </div>
      </div>
      <div className="h-[740px]">
        <Editor
          options={{
            minimap: {
              enabled: false,
            },
          }}
          theme="vs-dark"
          defaultLanguage="html"
          value={code}
          onChange={setCode}
        />
      </div>
    </div>
  );
};

export default memo(HtmlCodeEditor);
