import React from "react";
import { useSelector } from "react-redux";
import { TFileTreeItem, TStorageFileItem } from "../../../../globalTypes";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import { CopyFileParams } from "../../../../types";
import handleRequestError from "../../../../utils/handleRequestError";
import processFilesData from "../utils/processFilesData";

type Props = {
  treeData: TFileTreeItem[];
  setTreeData: React.Dispatch<React.SetStateAction<TFileTreeItem[]>>;
  copyFile: (params: CopyFileParams) => Promise<TStorageFileItem>;
};
const useCopyFile = ({ treeData, setTreeData, copyFile }: Props) => {
  const messageApi = useSelector(selectMessageApi);
  return async ({ sourcePath, targetPath }: CopyFileParams) => {
    try {
      const newFileData = await copyFile({
        sourcePath,
        targetPath,
      });
      //get folder path
      const lastSlashIndex = targetPath.lastIndexOf("/");
      const targetParentPath = targetPath.substring(0, lastSlashIndex + 1);
      //try to find parent folder
      const targetParentFolder = treeData.find(
        (fileItem) => targetParentPath === fileItem.fileName,
      );
      //if there is parent folder and expanded - update file tree
      if (targetParentFolder) {
        if (targetParentFolder.expanded) {
          setTreeData((prevState) => {
            const newTreeData = processFilesData({
              data: [newFileData],
              parentPath: targetParentFolder.fileName,
              level: targetParentFolder.level,
            });
            return [...prevState, ...newTreeData];
          });
        }
      } else {
        //if there is no parent folder - just add to root dir
        setTreeData((prevState) => {
          const newTreeData = processFilesData({ data: [newFileData] });
          return [...prevState, ...newTreeData];
        });
      }
      messageApi.success("The file was copied successfully");
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(e?.response?.data?.debugMessage || customError.message);
      console.error(customError);
    }
  };
};
export default useCopyFile;
