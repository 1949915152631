import { docTemplateStateBadgeSettings } from "src/constants";
import { useDocTemplatesList } from "src/hooks/useDocTemplatesList";
import { DocumentTemplateState } from "src/types/docTemplates";
import Pagination from "../common/Pagination";
import { Selector } from "./Selector";

const DocTemplatesPagination = () => {
  const {
    pageNumber,
    pageSize,
    total,
    updatePage,
    updatePageSize,
    updateStateFilters,
    states,
  } = useDocTemplatesList();

  const statesOptions = [
    { label: "All states", value: "all" },
    ...Object.keys(DocumentTemplateState).map((state) => ({
      label:
        docTemplateStateBadgeSettings[state as DocumentTemplateState].label,
      value: state,
    })),
  ];

  const handleStateChange = (state: any) => {
    if (state === "all") {
      updateStateFilters([]);
      return;
    }
    updateStateFilters([state] as DocumentTemplateState[]);
  };

  return (
    <div className="flex justify-between">
      <Selector
        onValueChange={handleStateChange}
        options={statesOptions}
        value={states?.[0] || "all"}
        placeholder={"Select State"}
      />
      <Pagination
        updatePageSize={updatePageSize}
        updatePage={updatePage}
        total={total}
        pageSize={pageSize}
        pageNumber={pageNumber}
      />
    </div>
  );
};

export default DocTemplatesPagination;
