import React, { FC, Fragment } from "react";
import { TVarType, VarTypeEnum } from "src/types";
import { TCcVariable } from "../../../store/slices/ccVariablesSlice";
import { VAR_IN_PROMPT_PLACEHOLDER } from "../../../utils/campaigns.constant";
import TemplatePromptVar from "./TemplatePromptVar";

type Props = {
  promptTemplate?: string;
  varKeys: Array<string | null>;
  setVarKeys: (keys: Array<string | null>) => void;
  gridItemSequence: number;
  postProcessingIndex: number;
  stepId: number;
  localKeys: TCcVariable[];
};

const TemplatePrompt: FC<Props> = ({
  promptTemplate,
  varKeys,
  setVarKeys,
  gridItemSequence,
  postProcessingIndex,
  stepId,
  localKeys,
}) => {
  if (!promptTemplate?.length) return <span>Prompt template is empty!</span>;

  const splitTemplate = promptTemplate.split(VAR_IN_PROMPT_PLACEHOLDER);

  if (splitTemplate.length === 1)
    return (
      <div className="!bg-[rgb(243,247,255)] rounded-[8px] py-[5px] px-[10px] mt-[10px]">
        {splitTemplate[0]}
      </div>
    );

  return (
    <div className="bg-[rgb(243,247,255)] rounded-[8px] py-[5px] px-[10px] mt-[10px]">
      {splitTemplate.map((item, index) => {
        if (index === splitTemplate.length - 1) {
          return (
            <Fragment key={`${promptTemplate}__${index}`}>{item}</Fragment>
          );
        }

        const varKey = varKeys[index];
        let varType: TVarType = VarTypeEnum.LOCAL_VAR;

        if (varKey) {
          if (/^".*"$/.test(varKey)) {
            varType = VarTypeEnum.RAW;
          } else if (varKey.includes("/")) {
            varType = VarTypeEnum.CAMPAIGN_VAR;
          }
        }

        return (
          <Fragment key={`${promptTemplate}__${index}`}>
            {item}
            <TemplatePromptVar
              varKeys={varKeys}
              varKey={varKey}
              varType={varType}
              setVarKeys={setVarKeys}
              index={index}
              localKeys={localKeys}
              gridItemSequence={gridItemSequence}
              postProcessingIndex={postProcessingIndex}
              stepId={stepId}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

export default TemplatePrompt;
