import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { useEffect, useState } from "react";
import { TUserCompanySecurityGroup } from "../../../globalTypes";
import { getCompanyPermissionGroupListApi } from "../../../api/user.api";
import handleRequestError from "../../../utils/handleRequestError";

const useGetData = () => {
  const { userId } = useParams();
  const messageApi = useSelector(selectMessageApi);
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState("");
  const [userCompanySecurityGroups, setUserCompanySecurityGroups] = useState<
    TUserCompanySecurityGroup[]
  >([]);

  useEffect(() => {
    if (userId !== undefined) {
      loadInitData({ userId });
    }
  }, [userId]);

  const loadInitData = async ({
    userId,
    update,
  }: {
    userId: number | string;
    update?: boolean;
  }) => {
    try {
      const { data } = await getCompanyPermissionGroupListApi({
        userId,
      });

      setUserCompanySecurityGroups(data);
      setError("");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      setError(customError.message);

      console.error(customError);
    } finally {
      if (!update) {
        setIsInitialized(true);
      }
    }
  };

  return {
    isInitialized,
    userCompanySecurityGroups,
    error,
    updateGroupsList: loadInitData,
  };
};

export default useGetData;
