import {
  CheckIcon,
  EyeSlashIcon,
  ListBulletIcon,
} from "@heroicons/react/16/solid";
import { EyeIcon } from "@heroicons/react/20/solid";
import { FormInstance, Tooltip } from "antd";
import { Rule } from "antd/es/form";
import clsx from "clsx";
import React, { FC, useEffect, useState } from "react";
import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { computationApi } from "src/api/computation.api";
import {
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  CloneIcon,
  DeleteIcon,
  DocumentIcon,
  ExecuteIcon,
  ExportDocIcon,
  GlobeIcon,
  GridIcon,
  Icons,
  InsertAfterIcon,
  InsertBeforeIcon,
} from "src/components/common/Icons";
import { MenuDropdownItem } from "src/components/common/MenuDropdown";
import StepMenuDropdown from "src/components/common/StepMenuDropdown";
import { ComputationType } from "src/constants";
import {
  selectCurrentCampaignView,
  setCurrentCampaignView,
  updateCampaignView,
} from "src/store/slices/campaignsSlice";
import {
  copyStepToApi,
  exportCSVStepApi,
  publishStepToMicroSiteApi,
} from "../../../api/steps.api";
import useConfirm from "../../../hooks/useConfirm";
import useModals from "../../../hooks/useModals";
import { getMessageApi } from "../../../store/slices/appSlice";
import {
  cloneStepThunk,
  deleteStepThunk,
  selectCurrentStep,
  setCurrentStep,
  TStep,
  updateHiddenDocViewThunk,
} from "../../../store/slices/stepsSlice";
import { AppDispatch } from "../../../store/store";
import { DocumentTemplateState } from "../../../types/docTemplates";
import { downloadTextFile } from "../../../utils/cm.utils";
import handleRequestError from "../../../utils/handleRequestError";
import CloneEntityModal, {
  TCloneEntityFormValues,
} from "../../common/modals/CloneEntityModal/CloneEntityModal";
import DeleteWithFilesModal from "../../common/modals/DeleteWithFilesModal/DeleteWithFilesModal";
import { TStepFormValues } from "../CampaignSteps/CampaignSteps";
import { TCloneToStepFormValues } from "../CloneToStepModal/CloneToStepModal";
import { CampaignViewTypes } from "../../../types";
import { useUiTemplatesOptions } from "../../../hooks/useTemplatesOptions";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";
import { resetComputationMessages } from "../../../store/slices/computationMessagesSlice";

type Props = {
  step: TStep;
  stepIndex: number;
  phaseId: number;
  companyId: number | undefined;
  campaignId: number;
  stepNameValidationRule: Rule;
  form: FormInstance<TStepFormValues>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEditStep: React.Dispatch<React.SetStateAction<TStep | null>>;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  onAddBefore?: () => void;
  onAddAfter?: () => void;
  onMoveBefore?: () => Promise<void>;
  onMoveAfter?: () => Promise<void>;
};

const Step: FC<Props> = ({
  step,
  stepIndex,
  form,
  setIsModalOpen,
  setEditStep,
  campaignId,
  phaseId,
  companyId,
  stepNameValidationRule,
  dragHandleProps,
  onAddBefore,
  onAddAfter,
  onMoveAfter,
  onMoveBefore,
}) => {
  const { execute, cloneToStep, stepTemplatePreview, modal } = useModals();
  const currentCampaignView = useSelector(selectCurrentCampaignView);
  const dispatch: AppDispatch = useDispatch();
  const currentStep = useSelector(selectCurrentStep);
  const confirm = useConfirm();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
  const messageApi = getMessageApi();
  const navigate = useNavigate();
  const isCurrentStep = currentStep?.id === step.id;
  const companyIdForUrl = companyId === undefined ? "global" : companyId;
  const { options, load, selectedOption } = useUiTemplatesOptions({
    displayTitle: false,
  });
  const {
    hasStepCreateRole,
    hasStepUpdateRole,
    hasStepDeleteRole,
    hasStepMoveRole,
    hasStepCloneRole,
    hasStepDeployRole,
    hasStepExportRole,
    hasStepCopyRole,
    hasCampaignStepComputationRole,
    hasStepTemplatePreviewRole,
    hasCampaignCcListRole,
    hasStepShowDocViewRole,
  } = useUserHasPermission({ companyId });

  useEffect(() => {
    if (currentStep?.id === step.id && hasStepTemplatePreviewRole) {
      load({
        states: [DocumentTemplateState.PUBLISHED],
        classes: step.classes || [],
        stepId: step.id,
      });
    }
  }, [currentStep?.id]);

  const onDelete = async (removeFiles: boolean) => {
    try {
      const { stepId } = await dispatch(
        deleteStepThunk({
          stepId: step.id,
          phaseId,
          removeFiles,
          stepIndex,
        }),
      ).unwrap();

      let path = `/campaigns/company/${companyIdForUrl}/campaign/${campaignId}/phase/${phaseId}`;

      if (stepId) {
        path += `/step/${stepId}`;
      }

      navigate(path, { replace: true });
      messageApi.success("The step has been successfully removed");
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(e);
    }
  };

  const handleSelectStep = async () => {
    dispatch(setCurrentStep(step));
    navigate(
      `/campaigns/company/${companyIdForUrl}/campaign/${campaignId}/phase/${phaseId}/step/${step.id}`,
      { replace: true },
    );
  };

  const onEdit = () => {
    form.setFieldsValue({
      name: step.name,
      hidden: step.hidden,
      microSiteContextFolder: step.microSiteContextFolder,
    });

    setEditStep(step);
    setIsModalOpen(true);
  };

  const onExportCSV = async () => {
    try {
      const { data } = await exportCSVStepApi({ phaseId, stepId: step.id });

      downloadTextFile({
        data,
        fileName: `${step.name}.csv`,
        type: "text/csv",
      });

      messageApi.success("The CSV file has been successfully downloaded");
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onToggleStepInDocVisible = async () => {
    dispatch(
      updateHiddenDocViewThunk({
        phaseId: step.phaseId,
        stepId: step.id,
        enable: !!currentStepHiddenDocView,
      }),
    );
  };

  const publishToMicroSite = async () => {
    try {
      await publishStepToMicroSiteApi({ phaseId, stepId: step.id });

      messageApi.success(
        "The step has been successfully published to micro site",
      );
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onClone = async (values: TCloneEntityFormValues) => {
    try {
      const res = await dispatch(cloneStepThunk({ step, params: values }));

      if ("error" in res) {
        messageApi.error(res.payload?.message);
      } else {
        setIsCloneModalOpen(false);
        messageApi.success("The step has been successfully cloned");
      }
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(e);
    }
  };

  const onCopyTo = async (values: TCloneToStepFormValues) => {
    try {
      const {
        companyId: targetCompanyId,
        campaignId: targetCampaignId,
        targetStepSeq,
        name,
        resetResult,
        resetOvr,
        phaseId: targetPhase,
      } = values;

      const { data } = await copyStepToApi({
        newSeq: targetStepSeq,
        name,
        phaseId,
        targetPhase,
        stepId: step.id,
        resetOvr,
        resetResult,
      });

      navigate(
        `/campaigns/company/${targetCompanyId}/campaign/${targetCampaignId}/phase/${data.phaseId}/step/${data.id}`,
        { replace: true },
      );
    } catch (e: any) {
      let error = e;

      if (e?.response?.data instanceof Blob) {
        try {
          error = JSON.parse(await e.response.data.text());
        } catch (e) {
          console.error("Can't get and parse error response from Blob:", e);
        }
      }

      const customError = handleRequestError(error);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onTemplatePreview = async () => {
    try {
      if (!options) {
        messageApi.error("No templates found");
        return;
      }

      messageApi.open({
        key: "get_preview_templates",
        type: "loading",
        content: "Loading templates...",
      });

      stepTemplatePreview({
        stepId: step.id,
        phaseId,
        options,
        companyId,
      });

      messageApi.open({
        key: "get_preview_templates",
        type: "success",
        content: "The templates have been loaded successfully",
      });
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      messageApi.open({
        key: "get_preview_templates",
        type: "error",
        content: customError.message,
      });
      console.error(customError);
    }
  };

  const itemsBeforeViews = [
    hasCampaignStepComputationRole && {
      label: "Execute",
      key: "execute",
      icon: ExecuteIcon,
      onClick: () =>
        execute({
          title: "Execute Step",
          executionType: "EXECUTION_STEP",
          requestExecute: async (
            computationType: ComputationType,
            republish: boolean,
            updateDownstreamDependencies: boolean,
          ) => {
            try {
              const { data: isExecuting } = await computationApi({
                campaignId,
              }).validateStep({
                phaseId,
                stepId: step.id,
              });

              if (!isExecuting) {
                await computationApi({ campaignId }).executeStep({
                  stepId: step.id,
                  computationType,
                  republish,
                  updateDownstreamDependencies,
                });

                dispatch(resetComputationMessages());

                messageApi.success("Execution started successfully");
              } else {
                messageApi.error(
                  "There are some active computation elements in progress, please wait before execute",
                );
              }
            } catch (e: any) {
              const customError = handleRequestError(e);

              messageApi.error(customError.message);
              console.error(customError);
            }
          },
        }),
      className: "!text-primary",
    },
    hasStepUpdateRole && {
      label: "Edit",
      key: "edit",
      onClick: onEdit,
      icon: Icons.edit,
    },
  ].filter(Boolean) as MenuDropdownItem[];

  const itemsAfterViews = [
    hasStepDeleteRole && {
      label: "Delete",
      key: "delete",
      icon: DeleteIcon,
      onClick: () => setIsDeleteModalOpen(true),
      className: "!text-red-600",
    },
  ].filter(Boolean) as MenuDropdownItem[];

  const isGridSelected = currentCampaignView === CampaignViewTypes.GRID;
  const currentStepHiddenDocView = currentStep?.hiddenDocView;
  const stepHiddenDocViewTitle = currentStepHiddenDocView
    ? "Show in Doc View"
    : "Hide in Doc View";
  const stepNotHidden = !step.hiddenDocView;

  const viewItems = [
    hasCampaignCcListRole && {
      label: `Grid`,
      key: "grid_view",
      icon: isGridSelected ? CheckIcon : GridIcon,
      onClick: () => {
        if (isGridSelected) return;
        dispatch(setCurrentCampaignView(CampaignViewTypes.GRID));
      },
      className: isGridSelected ? "!text-primary" : "",
    },
    stepNotHidden &&
      hasStepTemplatePreviewRole && {
        label: `Doc: ${selectedOption ? selectedOption.label : "No Doc Template Selected"}`,
        key: "doc_view",
        icon: !isGridSelected ? CheckIcon : DocumentIcon,
        onClick: () => {
          if (!isGridSelected) return;
          dispatch(updateCampaignView({ viewType: CampaignViewTypes.DOC }));
        },
        className: !isGridSelected ? "!text-primary" : "",
      },
    stepNotHidden &&
      hasStepTemplatePreviewRole && {
        label: "Select Doc Template",
        key: "select_doc_template",
        icon: ListBulletIcon,
        onClick: () => onTemplatePreview(),
      },
    hasStepShowDocViewRole && {
      label: stepHiddenDocViewTitle,
      key: "visible_in_doc_view",
      icon: currentStepHiddenDocView ? EyeIcon : EyeSlashIcon,
      onClick: () =>
        confirm({
          action: onToggleStepInDocVisible,
          title: stepHiddenDocViewTitle,
        }),
    },
  ].filter(Boolean) as MenuDropdownItem[];

  const moreItems = [
    hasStepExportRole && {
      label: "Export CSV",
      key: "export_csv",
      icon: ExportDocIcon,
      onClick: () => confirm({ action: onExportCSV, title: "Export as CSV" }),
    },
    hasStepMoveRole && {
      label: "Move Left",
      key: "move_left",
      icon: InsertBeforeIcon,
      disabled: !onMoveBefore,
      onClick: onMoveBefore,
    },
    hasStepMoveRole && {
      label: "Move Right",
      key: "move_right",
      icon: InsertAfterIcon,
      disabled: !onMoveAfter,
      onClick: onMoveAfter,
    },
    hasStepCreateRole && {
      label: "Add Before",
      key: "add_before",
      icon: ArrowUturnLeftIcon,
      onClick: onAddBefore,
    },
    hasStepCreateRole && {
      label: "Add After",
      key: "add_after",
      icon: ArrowUturnRightIcon,
      onClick: onAddAfter,
    },
    hasStepCloneRole && {
      label: "Clone",
      key: "clone",
      icon: CloneIcon,
      onClick: () => setIsCloneModalOpen(true),
    },
    hasStepCopyRole && {
      label: "Copy To",
      key: "copy_to",
      icon: CloneIcon,
      onClick: () =>
        cloneToStep({
          entityTitle: step.name,
          onSubmitForm: onCopyTo,
        }),
    },
    hasStepDeployRole &&
      companyId !== undefined && {
        label: "Micro Site Publish",
        key: "micro_site_publish",
        onClick: () =>
          confirm({
            action: publishToMicroSite,
            title: "Publish to Micro Site",
          }),
        icon: GlobeIcon,
      },
  ].filter(Boolean) as MenuDropdownItem[];

  const disabledStepButton =
    currentCampaignView === CampaignViewTypes.DOC ? step.hiddenDocView : false;
  const isMenuListNotEmpty =
    !!viewItems.length || !!moreItems.length || !!itemsBeforeViews.length;

  return (
    <>
      {modal}
      {isCurrentStep ? (
        <div className="relative bg-indigo-50">
          <StepMenuDropdown
            beforeViewsItems={itemsBeforeViews}
            afterViewsItems={itemsAfterViews}
            moreItems={moreItems}
            viewItems={viewItems as any}
          >
            {(ref, setOpen, DropdownMenuTrigger) => (
              <>
                {isMenuListNotEmpty && (
                  <div
                    className={clsx(
                      "absolute right-[10px] flex items-center justify-center rounded-full cursor-pointer hover:bg-primary/10 transition-colors duration-200 size-6 top-3",
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpen(true);
                    }}
                  >
                    <Icons.MoreDots className="size-5" />
                  </div>
                )}
                <div
                  className={clsx(
                    "h-12 pr-10 bg-indigo-50 border-b-[3px] border-primary justify-between items-center gap-2 inline-flex cursor-pointer flex-col",
                    {
                      "hover:bg-indigo-100": isCurrentStep,
                    },
                  )}
                  {...dragHandleProps}
                >
                  <div className="text-slate-700 pl-4 h-full text-sm !font-semibold !font-sans leading-[14px] line-clamp-1 whitespace-nowrap inline-flex flex-col justify-center pt-[6px]">
                    {step.name}
                  </div>
                  <DropdownMenuTrigger asChild ref={ref}>
                    <div className="h-0 w-full" />
                  </DropdownMenuTrigger>
                </div>
              </>
            )}
          </StepMenuDropdown>

          {isCloneModalOpen && (
            <CloneEntityModal
              entity="Step"
              entityTitle={step.name}
              entityTitleTooltip="Please enter a string between 4 and 26 characters."
              onSubmitForm={onClone}
              isModalOpen={isCloneModalOpen}
              onCancel={() => setIsCloneModalOpen(false)}
            />
          )}
          {isDeleteModalOpen && (
            <DeleteWithFilesModal
              isModalOpen={isDeleteModalOpen}
              entity="Step"
              title={step.name}
              setIsModalOpen={setIsDeleteModalOpen}
              onDelete={onDelete}
            />
          )}
        </div>
      ) : (
        <Tooltip title={disabledStepButton ? "Hidden for Doc View" : undefined}>
          <div
            className={clsx(
              `h-12 bg-indigo-50 px-[16px] py-[19px] justify-start items-center gap-2 inline-flex cursor-pointer border-y-[3px] border-transparent hover:border-b-primary transition-colors hover:bg-primary/10 tab`,
              {
                "!cursor-not-allowed !text-slate-200 !bg-gray-100 !border-b-gray-100":
                  disabledStepButton,
              },
            )}
            onClick={disabledStepButton ? undefined : handleSelectStep}
            {...dragHandleProps}
          >
            <span className="text-slate-700 text-sm font-semibold font-sans leading-[14px] line-clamp-1 whitespace-nowrap inline-flex items-center">
              {step.name}

              {isMenuListNotEmpty && (
                <div>
                  <Icons.MoreDots
                    className={clsx("size-5 ml-2 tab-icon", {
                      "!bg-gray-100": disabledStepButton,
                    })}
                  />
                </div>
              )}
            </span>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default Step;
