import clsx from "clsx";
import { Handle, Position } from "reactflow";
import { TComputationState } from "../../store/slices/ccVariablesSlice";
import NodeMenu from "./NodeMenu";
import StateIcon from "./StateIcon";

type Props = {
  //set data props for each node in useGetNodes hook
  data: {
    label: string;
    touched: boolean;
    state: TComputationState | undefined;
    setTarget: () => Promise<void>;
    setInput: () => Promise<void>;
    setTargetWithInput: () => Promise<void>;
    goToKeyDefinition: () => Promise<void>;
    reRun: () => Promise<void>;
  };
};

export const DefaultNode = ({ data }: Props) => {
  const {
    label,
    touched,
    state,
    setTarget,
    setInput,
    setTargetWithInput,
    goToKeyDefinition,
    reRun,
  } = data;

  return (
    <div className="w-full h-full pl-[8px]">
      <Handle
        className="!left-[-1px] opacity-0"
        type="target"
        position={Position.Left}
        isConnectable={false}
      />
      <div className="relative w-full h-full flex justify-between items-center">
        <div className="absolute -top-[9px] -left-[29px]">
          <StateIcon state={state} />
        </div>
        <div
          className={clsx(
            "w-[85%] text-[#334155] text-xs overflow-hidden text-ellipsis whitespace-nowrap",
            {
              "underline !text-yellow-600 text-bold": touched,
            }
          )}
        >
          {label}
        </div>
        <div className="absolute top-[6px] right-[4px]">
          <NodeMenu
            setTarget={setTarget}
            setInput={setInput}
            setTargetWithInput={setTargetWithInput}
            goToKeyDefinition={goToKeyDefinition}
            reRun={reRun}
          />
        </div>
      </div>
      <Handle
        className="!right-[-2px] opacity-0"
        type="source"
        position={Position.Right}
        isConnectable={false}
      />
    </div>
  );
};

export const GroupNode = ({ data }: any) => {
  return (
    <div className="absolute">
      <strong className="relative top-[-5px] text-info">{data.label}</strong>
    </div>
  );
};
