import { AxiosResponse } from "axios";
import { TCampaignListOptionType } from "../globalTypes";
import { TCampaign, TCampaignType } from "../store/slices/campaignsSlice";
import axiosInstance from "../utils/axios/axiosInstance";
import { TCampaignData } from "../utils/transformCampaignData";

type GetCampaignsRes = {
  items: TCampaign[];
  totalItems: number;
};

export const getCampaignsApi = ({
  currentPage = 1,
  pageSize,
  companyId,
  campaignType,
  name,
  aiModelId,
}: {
  currentPage?: number;
  pageSize?: number;
  companyId?: number | null;
  campaignType?: TCampaignListOptionType;
  name?: string;
  aiModelId?: number;
}) => {
  return axiosInstance.get<GetCampaignsRes>(`/api/secured/global/campaign/`, {
    params: {
      companyId,
      pageNum: currentPage - 1,
      types: campaignType !== "ALL" ? campaignType : undefined,
      pageSize,
      name,
      aiModelId,
    },
  });
};

export const getCampaignsDataApi = ({ campaignId }: { campaignId: number }) => {
  return axiosInstance.get<TCampaignData>(
    `/api/secured/campaign/${campaignId}/data`,
    {
      params: {
        initProps: "I18N",
      },
    },
  );
};

export const createCampaignApi = ({
  name,
  companyId,
  type,
}: {
  name: string;
  type: TCampaignType;
  companyId: number;
}) => {
  return axiosInstance.post<TCampaign>(`/api/secured/campaign/`, {
    type,
    name,
    companyId,
  });
};

export const deleteCampaignApi = ({
  campaignId,
  removeFiles,
}: {
  campaignId: number;
  removeFiles: boolean;
}) => {
  return axiosInstance.delete<string>(`/api/secured/campaign/${campaignId}`, {
    params: {
      removeFiles,
    },
  });
};

export const cloneCampaignApi = ({
  campaignId,
  name,
  resetResult,
  resetOvr,
}: {
  campaignId: number;
  name: string;
  resetResult: boolean;
  resetOvr: boolean;
}) => {
  return axiosInstance.put<{ campaign: TCampaign; errorMessages: string[] }>(
    `/api/secured/campaign/${campaignId}/clone/`,
    {},
    {
      params: {
        name,
        resetResult,
        resetOvr,
      },
    },
  );
};

export const publishCampaignApi = ({ campaignId }: { campaignId: number }) => {
  return axiosInstance.put<string>(
    `/api/secured/campaign/${campaignId}/publish/`,
    {},
  );
};

export const unPublishCampaignApi = ({
  campaignId,
}: {
  campaignId: number;
}) => {
  return axiosInstance.put<string>(
    `/api/secured/campaign/${campaignId}/un-publish/`,
    {},
  );
};

export const createCampaignFromTemplateApi = ({
  campaignId,
  campaignName,
  companyIdToCreate,
  resetOvr,
  resetResult,
}: {
  campaignId: number;
  companyIdToCreate: number;
  campaignName: string;
  resetOvr: boolean;
  resetResult: boolean;
}) => {
  return axiosInstance.post<TCampaign>(
    `/api/secured/campaign/${campaignId}/startCampaign/${companyIdToCreate}`,
    {},
    {
      params: {
        name: campaignName,
        resetOvr,
        resetResult,
      },
    },
  );
};

export const importCampaignApi = ({
  formData,
  companyId,
}: {
  formData: FormData;
  companyId: number;
}) => {
  return axiosInstance.post<TCampaign>(
    `/api/secured/campaign/${companyId}/import/`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
};

export const stopExecuteCampaignApi = ({
  campaignId,
}: {
  campaignId: number;
}) => {
  return axiosInstance.post<string>(
    `/api/secured/${campaignId}/computation/campaign/stop`,
    {},
  );
};

export const editCampaignApi = ({
  campaignData,
  rebuild,
}: {
  campaignData: TCampaign;
  rebuild?: boolean;
}): Promise<AxiosResponse<string>> => {
  return axiosInstance.put(`/api/secured/campaign/`, campaignData, {
    params: {
      rebuild,
    },
  });
};

export const exportCampaignApi = ({
  campaignId,
  params,
}: {
  campaignId: number;
  params: string;
}) => {
  return axiosInstance.get<Blob>(`/api/secured/campaign/${campaignId}/export`, {
    responseType: "blob",
    params: { initProps: params || undefined },
  });
};

export const publishCampaignToMicroSiteApi = ({
  campaignId,
}: {
  campaignId: number;
}) => {
  return axiosInstance.put<string>(
    `/api/secured/campaign/${campaignId}/microsite-deploy/`,
    {},
  );
};

export const changeCampaignCompanyApi = ({
  campaignId,
  companyId,
}: {
  campaignId: number;
  companyId: number;
}) => {
  return axiosInstance.put<string>(
    `/api/secured/campaign/${campaignId}/company/`,
    {
      companyId,
    },
  );
};

export const getCampaignDataApi = ({
  campaignId,
}: {
  campaignId: number | string;
}) => {
  return axiosInstance.get<TCampaign>(`/api/secured/campaign/${campaignId}`);
};
