import React from "react";
import { TableCell, TableRow } from "../../common/ui/table";
import { TCompanyUser } from "../../../globalTypes";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";
import useNavigateWithOutletRedirect from "../../../hooks/navigation/useNavigateWithOutletRedirect";
import UserMenuTableCell from "../../users/UserMenuTableCell";
import clsx from "clsx";
import { format } from 'date-fns';

type PropsType = {
  user: TCompanyUser;
  loadUsers: () => Promise<void>;
};
const CompanyUsersListItem: React.FC<PropsType> = ({ user, loadUsers }) => {
  const { securityGroups, userName, userId, disabled, position, email, lastLoginTime } = user;
  const { hasUserReadRole } = useUserHasPermission({});
  const { navigateWithOutletRedirect } = useNavigateWithOutletRedirect();

  const onNavigateToUserDetails = () => {
    navigateWithOutletRedirect(`/users/${userId}/details`);
  };

  return (
    <>
      <TableRow
        onClick={hasUserReadRole ? onNavigateToUserDetails : undefined}
        className={clsx("cursor-pointer text-[#475569] font-sans h-[48px]", {
          "opacity-30": disabled,
        })}
      >
        <TableCell className="font-medium text-[#0F172A]">{userName}</TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>{position}</TableCell>
        <TableCell>{securityGroups}</TableCell>
        <TableCell>
          {lastLoginTime
            ? format(lastLoginTime, "MMMM d, yyyy h:mm a zzz")
            : "-"}
        </TableCell>
        <UserMenuTableCell
          userId={userId}
          updateUsers={loadUsers}
          userName={userName}
          disabled={disabled}
          onNavigateToUserDetails={onNavigateToUserDetails}
        />
      </TableRow>
    </>
  );
};

export default CompanyUsersListItem;
