import { Select } from "antd";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../store/slices/appSlice";
import handleRequestError from "../../utils/handleRequestError";

export type TSelectOptionWithData<T> = {
  label: string;
  value: number;
  data: T;
};

type Props = {
  onSelect: (
    companyId: number | undefined,
    option: TSelectOptionWithData<any> | undefined
  ) => void | Promise<void>;
  fetchOptions: () => Promise<TSelectOptionWithData<any>[]>;
  className?: string;
  disabled?: boolean;
  allowClear?: boolean;
  optionLabel?: string;//this is a prop from option (TSelectOptionWithData) data
};

const SelectWithHighlightSearch: FC<Props> = ({
  onSelect,
  className,
  disabled,
  allowClear,
  fetchOptions,
  optionLabel,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const messageApi = useSelector(selectMessageApi);
  const [options, setOptions] = useState<TSelectOptionWithData<any>[]>([]);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    const getOptions = async () => {
      try {
        setIsFetching(true);

        const options = await fetchOptions();
        setOptions(options);
      } catch (e: any) {
        const customError = handleRequestError(e);
        messageApi.error(customError.message);
        console.error(customError);
      } finally {
        setIsFetching(false);
      }
    };

    getOptions();
  }, []);

  const highlightText = (text: string, highlight: string) => {
    try {
      if (!highlight) return [text];
      const escapedHighlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      return text.split(new RegExp(`(${escapedHighlight})`, "gi"));
    } catch (e) {
      console.warn(e);
      return [text];
    }
  };

  const filterOption = (input: string, option?: TSelectOptionWithData<any>) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onChange = (
    value: number | undefined,
    option: TSelectOptionWithData<any> | undefined
  ) => {
    setSearchInput("");

    onSelect(value, option);
  };

  return (
    <Select
      placeholder="Start typing to search"
      showSearch
      loading={isFetching}
      optionRender={(option) => {
        const { data } = option;
        const parts = highlightText(data.label, searchInput);
        let label = <></>;

        if (optionLabel) {
          label = (
            <span className="text-[#4F46E5] text-[10px] opacity-0.1">
              {data.data[optionLabel]}
            </span>
          );
        }

        return (
          <div className="flex flex-col">
            {label}
            <div>
              <>
                {parts.map((part, index) =>
                  part.toLowerCase() === searchInput.toLowerCase() ? (
                    <span
                      key={index}
                      className="bg-amber-300 whitespace-pre-wrap"
                    >
                      {part}
                    </span>
                  ) : (
                    <span key={index} className="whitespace-pre-wrap">{part}</span>
                  )
                )}
              </>
            </div>
          </div>
        );
      }}
      filterOption={filterOption}
      disabled={disabled || isFetching}
      options={options}
      allowClear={allowClear}
      onSearch={setSearchInput}
      onChange={(value, option) =>
        onChange(value, option as TSelectOptionWithData<any>)
      }
      className={className}
    />
  );
};

export default SelectWithHighlightSearch;
