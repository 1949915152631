import { Drawer } from "antd";
import TextArea from "antd/es/input/TextArea";
import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMessageApi } from "src/store/slices/appSlice";
import {
  loadCcVarsThunk,
  TCcVariable,
} from "src/store/slices/ccVariablesSlice";
import { selectCurrentPhase } from "src/store/slices/phasesSlice";
import { selectCurrentStep } from "src/store/slices/stepsSlice";
import { AppDispatch } from "src/store/store";
import { KeyPanelMode } from "src/types";
import handleRequestError from "src/utils/handleRequestError";
import { Button } from "../../common/ui/button";
import EditPrompt from "../../graph/EditPrompt/EditPrompt";
import { NodeType } from "../../graph/EditorPane";
import PaneDynamicForm from "../../graph/PaneDynamicForm/PaneDynamicForm";
import PaneExecuteButton from "../../graph/PaneExecuteButton";
import PaneInfoSection from "../../graph/PaneInfoSection";
import PaneResultOverride from "../../graph/PaneResultOverride";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import { ActiveTab, NodeNavItem } from "../types";
import { updateCcVarApi } from "../../../api/cc-variables.api";

export type TVarEditPanelProps = {
  variableData?: TCcVariable | undefined;
  onClose: () => void;
  onSaved: () => void;
  mode?: KeyPanelMode;
  ccVariables: TCcVariable[];
  isOpen?: boolean;
};

export const VarKeyPanel: FC<TVarEditPanelProps> = ({
  variableData,
  onClose,
  onSaved,
  isOpen,
}) => {
  const { companyId } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const [item, setItem] = useState<TCcVariable | null>(variableData || null);
  const currentStep = useSelector(selectCurrentStep);
  const currentPhase = useSelector(selectCurrentPhase);
  const [activeComponent, setActiveComponent] = useState(ActiveTab.Result);
  const { hasCampaignCcUpdateStateRole, hasCampaignEnvItemUpdateRole } =
    useUserHasPermission({ companyId });
  const messageApi = getMessageApi();
  const dispatch: AppDispatch = useDispatch();
  const [result, setResult] = useState<string | undefined>(
    variableData?.result || undefined,
  );

  useEffect(() => {
    setActiveComponent(item?.resultOvr ? ActiveTab.Override : ActiveTab.Result);
  }, [item]);

  if (!item) {
    return (
      <div className="text-red-600">Error: Unable to get computation item</div>
    );
  }
  const allowedTypesForModel =
    item.type === "prompt" ||
    item.type === "asset" ||
    item.type === "web_scraper" ||
    item.type === "function";

  const isString = item.type === "string";

  const onCreate = async () => {
    try {
      setIsFetching(true);

      const valuesToSave = {
        key: item.id.key,
        type: item.type,
        result: result || "",
        modifiedTime: item.modifiedTime,
      };

      await updateCcVarApi({
        ccItem: item,
        values: valuesToSave,
        rebuild: false,
      });

      await dispatch(loadCcVarsThunk()).unwrap();

      onSaved?.();
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const handleOverrideSaved = () => {
    onSaved();
  };

  const navDataList = [
    !isString &&
      item && {
        label: ActiveTab.Override,
        active: activeComponent === ActiveTab.Override,
        renderComponent: () => (
          <PaneResultOverride
            ccItem={item}
            setItem={setItem}
            onOverrideSave={handleOverrideSaved}
          />
        ),
      },
    {
      label: ActiveTab.Result,
      active: activeComponent === ActiveTab.Result,
      renderComponent: () =>
        item?.type !== "string" ? (
          <div className="flex-grow overflow-y-auto">
            {item?.result || "There is no value in the item"}
          </div>
        ) : (
          <div className="flex-grow overflow-y-auto">
            <TextArea
              value={result || ""}
              onChange={(e) => {
                setResult(e.target.value);
              }}
              placeholder="Enter result"
              allowClear
              disabled={isFetching}
              rows={4}
            />
            <Button
              className="mt-3"
              onClick={onCreate}
              disabled={isFetching}
              loading={isFetching}
            >
              Save
            </Button>
          </div>
        ),
    },
    hasCampaignCcUpdateStateRole &&
      allowedTypesForModel && {
        label: ActiveTab.Model,
        active: activeComponent === ActiveTab.Model,
        renderComponent: () => (
          <PaneDynamicForm
            nodeType={NodeType.TARGET}
            ccItem={item!}
            ccItemPhaseId={currentPhase?.id!}
            setItem={setItem}
          />
        ),
      },
    hasCampaignEnvItemUpdateRole &&
      item?.type === "prompt" && {
        label: ActiveTab.Prompt,
        active: activeComponent === ActiveTab.Prompt,
        renderComponent: () => <EditPrompt ccItem={item!} />,
      },
  ].filter(Boolean) as NodeNavItem[];

  return (
    <Drawer
      placement={"bottom"}
      closable={false}
      open={isOpen}
      onClose={onClose}
      height={340}
    >
      <div className="flex justify-between">
        <PaneInfoSection
          stepName={currentStep?.name!}
          phaseName={currentPhase?.name!}
          nodeType={"EDIT"}
          phaseId={currentPhase?.id!}
          type={item.type}
          state={item.state}
          stepId={item.id.stepId}
          ccItemKey={item.id.key}
        />
        <PaneExecuteButton
          campaignId={item.id.campaignId}
          ccItemKey={item.id.key}
          stepId={item.id.stepId}
        />
      </div>
      <div>
        {navDataList.map(({ label, active }) => (
          <div
            key={label}
            className={clsx(
              "cursor-pointer capitalize inline-flex items-center font-sans font-semibold transition-colors text-[#334155] text-[14px] h-[34px] mr-[24px] border-y-transparent hover:text-[#4F46E5] border-y-[3px]",
              {
                "border-b-primary": active,
              },
            )}
            onClick={() => setActiveComponent(label)}
          >
            <span className={active ? "text-primary" : ""}>{label}</span>
          </div>
        ))}
        <div className="h-[1px] bg-[#E2E8F0]" />
      </div>
      <div className="mt-2">
        {navDataList.map(({ label, active, renderComponent }) => {
          if (active) {
            return (
              <div key={label} className="flex-grow overflow-y-auto h-[220px]">
                {active && renderComponent && renderComponent()}
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </Drawer>
  );
};
