import { TLabelLanguage, TLabels } from "../globalTypes";
import axiosInstance from "../utils/axios/axiosInstance";

export const getCampaignLabelsApi = ({
  campaignId,
  lang,
}: {
  campaignId: number;
  lang: TLabelLanguage;
}) => {
  return axiosInstance.get<TLabels>(
    `/api/secured/campaign/${campaignId}/i18n/${lang}/`,
  );
};

export const saveCampaignLabelApi = ({
  campaignId,
  lang,
  label,
  key,
  action,
}: {
  campaignId: string;
  label: string;
  key: string;
  lang: TLabelLanguage;
  action: "create" | "update";
}) => {
  const method = action === "create" ? "post" : "put";

  return axiosInstance[method]<string>(
    `/api/secured/campaign/${campaignId}/i18n/${lang}/${key}/`,
    label,
    {
      headers: {
        "Content-Type": "text/plain",
      },
    },
  );
};

export const bulkDeleteCampaignLabelsApi = ({
  campaignId,
  lang,
  keys,
}: {
  campaignId: string;
  keys: Array<string>;
  lang: TLabelLanguage;
}) => {
  return axiosInstance.delete<string>(
    `/api/secured/campaign/${campaignId}/i18n/${lang}/bulk/`,
    {
      data: keys,
    },
  );
};
