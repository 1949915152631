import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCompaniesListState,
  setCompanies,
  setPageNumber,
  setPageSize,
} from "src/store/slices/companiesListSlice";
import { createCompanyApi, getCompaniesApi } from "../../../api/companies.api";
import { selectMessageApi } from "../../../store/slices/appSlice";
import handleRequestError from "../../../utils/handleRequestError";
import { TCompanyForm } from "../../../components/companies/CompanyDrawer/CompanyDrawer";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";
import useNavigateWithOutletRedirect from "../../../hooks/navigation/useNavigateWithOutletRedirect";

const useCompaniesListHook = () => {
  const dispatch = useDispatch();
  const messageApi = useSelector(selectMessageApi);
  const { hasCompanyReadRole } = useUserHasPermission({});
  const { list, total, pageNumber, pageSize } = useSelector(
    selectCompaniesListState,
  );
  const [loading, setLoading] = useState(true);
  const { navigateWithOutletRedirect } = useNavigateWithOutletRedirect();

  useEffect(() => {
    loadCompanies({ pageNumber, pageSize });
  }, [pageNumber, pageSize]);

  const loadCompanies = async ({
    pageNumber,
    pageSize,
  }: {
    pageNumber: number;
    pageSize: number;
  }) => {
    try {
      setLoading(true);

      const { data } = await getCompaniesApi({
        name: "",
        pageSize,
        pageNumber,
      });

      dispatch(setCompanies(data));
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setLoading(false);
    }
  };

  const updatePage = (pageNumber: number) => {
    dispatch(setPageNumber(pageNumber));
  };

  const updatePageSize = (pageSize: number) => {
    dispatch(setPageNumber(1));
    dispatch(setPageSize(pageSize));
  };

  const onCreateCompany = async ({ values }: { values: TCompanyForm }) => {
    // error handling takes place at the call location
    const { data } = await createCompanyApi({ payload: values });

    if (hasCompanyReadRole) {
      navigateWithOutletRedirect(`/companies/${data.id}/details`);
    } else {
      await loadCompanies({ pageNumber, pageSize });
    }
  };

  return {
    list,
    total,
    pageSize,
    pageNumber,
    updatePage,
    updatePageSize,
    loading,
    onCreateCompany,
  };
};

export default useCompaniesListHook;
