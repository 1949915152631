import { Tooltip } from "antd";
import clsx from "clsx";
import { FC } from "react";
import { useSelector } from "react-redux";
import { DocIcon, TableIcon } from "src/components/common/Icons";
import { Button } from "src/components/common/ui/button";
import { selectDocVisibleSteps } from "src/store/slices/stepsSlice";
import { CampaignViewTypes } from "src/types";
import { useUserHasPermission } from "../../../../hooks/useUserHasPermission";
import { useParams } from "react-router-dom";

type TMenuItem = {
  viewType: CampaignViewTypes;
  icon: any;
  disabled: boolean;
};

type Props = {
  currentViewType: CampaignViewTypes;
  onViewTypeChange: (viewType: CampaignViewTypes) => void;
};

const CampaignViewSelector: FC<Props> = ({
  currentViewType,
  onViewTypeChange,
}) => {
  const { companyId } = useParams();
  const docVisibleList = useSelector(selectDocVisibleSteps);
  const { hasStepTemplatePreviewRole, hasCampaignCcListRole } =
    useUserHasPermission({ companyId });
  const isDocVisibleListEmpty = docVisibleList.length === 0;

  const items: TMenuItem[] = [
    {
      viewType: CampaignViewTypes.DOC,
      icon: DocIcon,
      disabled: !hasStepTemplatePreviewRole || isDocVisibleListEmpty,
    },
    {
      viewType: CampaignViewTypes.GRID,
      icon: TableIcon,
      disabled: !hasCampaignCcListRole,
    },
  ];

  const handleSelect = (viewType: CampaignViewTypes) => {
    onViewTypeChange(viewType);
  };

  return (
    <div className="flex flex-col border-r border-[#E2E8F0]">
      {items.map(({ viewType, icon: Icon, disabled }, i) => {
        const isActive = currentViewType === viewType;

        return (
          <div
            key={viewType}
            className={clsx(
              "bg-slate-50 h-[48px] items-center flex transition-colors text-[#334155] text-sm font-semibold font-sans leading-[14px] border-t-[3px] border-transparent p-[8px]",
              {
                "rounded-tl-[3px]": i === 0,
                "rounded-bl-[3px]": i === items.length - 1,
              },
            )}
          >
            <Tooltip
              title={
                disabled && isDocVisibleListEmpty
                  ? `You don't have visible steps in Doc view`
                  : ""
              }
            >
              <div>
                <Button
                  className="w-full h-full flex items-center justify-center size-[32px]"
                  size="icon"
                  disabled={disabled}
                  onClick={() => handleSelect(viewType)}
                  variant={isActive ? "primary" : "ghostPrimary2"}
                >
                  <Icon className="h-4 w-4" />
                </Button>
              </div>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

export default CampaignViewSelector;
