import { Empty } from "antd";
import React, { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDocTemplatesList } from "src/hooks/useDocTemplatesList";
import {
  DocumentTemplateField,
  SortingDirection,
  TDocTemplateTableHeaderItem,
} from "src/types/docTemplates";
import { Table, TableBody } from "../common/ui/table";
import { TableHeader } from "../table/Header";
import DocTemplateListItem from "./ListItem";
import { useUserHasPermission } from "../../hooks/useUserHasPermission";
import DocTemplatesPagination from "./DocTemplatesPagination";
import SkeletonList from "../common/SkeletonList";

type Props = {};

const DocTemplatesList: FC<Props> = () => {
  const {
    list,
    loading,
    load,
    pageNumber,
    pageSize,
    states,
    updateSorting,
    sorting,
    total,
  } = useDocTemplatesList();
  const { hasDictionarySearchRole, hasDocumentTemplateCreateRole } =
    useUserHasPermission({});

  useEffect(() => {
    load();
  }, [pageNumber, pageSize, states, sorting.field, sorting.direction]);

  const tableHeaders = [
    {
      title: "Name",
      name: "name",
      className: "font-sans text-black",
      isSortable: true,
    },
    {
      title: "Media Type",
      name: "mediaType",
      className: "font-sans text-black",
      isSortable: false,
    },
    {
      title: "State",
      className: "font-sans text-black",
      name: "state",
      isSortable: false,
    },
    hasDictionarySearchRole && {
      title: "Step Classes",
      className: "font-sans text-black",
      name: "classes",
      isSortable: false,
    },
    null,
  ].filter((item) => item !== false) as TDocTemplateTableHeaderItem[];

  const handleUpdateSorting = (field: string) => {
    updateSorting(
      field as DocumentTemplateField,
      sorting.direction === SortingDirection.ASC
        ? SortingDirection.DESC
        : SortingDirection.ASC,
    );
  };

  return (
    <>
      {list.length ? (
        <>
          <DocTemplatesPagination />
          {loading ? (
            <SkeletonList quantity={Math.ceil(total / 3)} />
          ) : (
            <div className="rounded-md border w-full mx-auto">
              <Table>
                <TableHeader
                  items={tableHeaders}
                  sorting={sorting}
                  handleUpdateSorting={handleUpdateSorting}
                />
                <TableBody className="font-sans">
                  {list.map((item) => (
                    <DocTemplateListItem key={item.id} item={item} />
                  ))}
                </TableBody>
              </Table>
            </div>
          )}

          <DocTemplatesPagination />
        </>
      ) : (
        <>
          {loading ? (
            <SkeletonList quantity={3} />
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              <Empty
                imageStyle={{ height: "30vh" }}
                description={
                  <div className="font-sans font-bold text-[#0F172A]">
                    <span>
                      There are no document templates in your profile.
                    </span>
                    {hasDocumentTemplateCreateRole && (
                      <span>
                        <Link to="/templates/documents/create">
                          {" "}
                          Create
                        </Link>{" "}
                        a new document template.
                      </span>
                    )}
                  </div>
                }
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DocTemplatesList;
