import { Edge, Node, useReactFlow } from "reactflow";
import getUpdatedEdgeStyles from "../utils/getUpdatedEdgeStyles";
import getUpdatedNodeStyles from "../utils/getUpdatedNodeStyles";

const useUpdateNodesAndEdges = () => {
  const { getNodes, getEdges } = useReactFlow();

  return ({
    targetNodeId,
    inputNodeId,
  }: {
    targetNodeId: string;
    inputNodeId: string;
  }) => {
    const updatedEdges: Edge[] = getEdges().map((edge) => ({
      ...edge,
      ...getUpdatedEdgeStyles({
        nodeId: edge.target,
        targetNodeId,
        inputNodeId,
      }),
    }));

    const updatedNodes: Node<any>[] = getNodes().map((node) => {
      if (node.type === "group") return node;

      return {
        ...node,
        style: {
          ...node.style,
          ...getUpdatedNodeStyles({
            nodeId: node.id,
            targetNodeId,
            inputNodeId,
          }),
        },
      };
    });

    return {
      updatedEdges,
      updatedNodes,
    };
  };
};

export default useUpdateNodesAndEdges;
