import { CSSProperties } from "react";
import { NodeColor } from "../hooks/useGetNodes.hook";

const getUpdatedNodeStyles = ({
  nodeId,
  inputNodeId,
  targetNodeId,
}: {
  nodeId: string;
  targetNodeId: string;
  inputNodeId: string;
}) => {
  const isTargetOrInput = nodeId === targetNodeId || nodeId === inputNodeId;
  const styles: CSSProperties = {
    padding: 0,
    boxShadow: "none",
    borderColor: "#000"
  };

  if (isTargetOrInput) {
    const color = nodeId === targetNodeId ? NodeColor.TARGET : NodeColor.INPUT;

    styles.boxShadow = `inset 0 0 0 2px ${color}`;
    styles.borderColor = color;
  }

  return styles;
};

export default getUpdatedNodeStyles;