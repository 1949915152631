import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useGoBack } from "src/hooks/useGoBack";
import { Icons } from "./Icons";
import { Button } from "./ui/button";

type Props = {
  title: string | React.ReactNode;
  showBackButton?: boolean;
  backUrl?: string;
};
export const PageHeader: FC<Props> = ({
  title,
  showBackButton = false,
  backUrl,
}) => {
  const goBack = useGoBack();
  const navigate = useNavigate();
  const handleGoBack = () => {
    if (backUrl) {
      navigate(backUrl);
    } else {
      goBack();
    }
  };
  return (
    <div className="flex gap-[12px] items-center">
      {showBackButton && (
        <Button
          variant={"ghost"}
          icon={Icons.back}
          onClick={handleGoBack}
          className="p-[12px] h-auto"
        />
      )}
      <div className="flex items-end gap-[8px]">
        <h1 className="text-slate-900 text-2xl font-semibold font-sans leading-normal capitalize">
          {title}
        </h1>
      </div>
    </div>
  );
};
