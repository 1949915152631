import { ComputationType } from "src/constants";
import axiosInstance from "src/utils/axios/axiosInstance";

export const computationApi = ({ campaignId }: { campaignId: number }) => ({
  executeCampaign({
    computationType,
    republish,
  }: {
    computationType: ComputationType;
    republish: boolean;
  }) {
    return axiosInstance.post(
      `/api/secured/${campaignId}/computation/campaign`,
      {},
      {
        params: {
          computationType,
          republish,
        },
      },
    );
  },
  executePhase({
    phaseId,
    computationType,
    republish,
    updateDownstreamDependencies,
  }: {
    phaseId: number;
    computationType: ComputationType;
    republish: boolean;
    updateDownstreamDependencies: boolean;
  }) {
    return axiosInstance.post(
      `/api/secured/${campaignId}/computation/phase/${phaseId}`,
      {},
      {
        params: {
          computationType,
          republish,
          updateDownstreamDependencies,
        },
      },
    );
  },
  executeStep({
    stepId,
    computationType,
    republish,
    updateDownstreamDependencies,
  }: {
    stepId: number;
    computationType: ComputationType;
    republish: boolean;
    updateDownstreamDependencies: boolean;
  }) {
    return axiosInstance.post(
      `/api/secured/${campaignId}/computation/step/${stepId}`,
      {},
      {
        params: {
          computationType,
          republish,
          updateDownstreamDependencies,
        },
      },
    );
  },
  validateCampaign() {
    return axiosInstance.get<boolean>(
      `/api/secured/campaign/execute/validate/${campaignId}/`,
    );
  },
  validatePhase({ phaseId }: { phaseId: number }) {
    return axiosInstance.get<boolean>(
      `/api/secured/${campaignId}/phase/execute/validate/${phaseId}/`,
    );
  },
  validateStep({ phaseId, stepId }: { phaseId: number; stepId: number }) {
    return axiosInstance.get<boolean>(
      `/api/secured/${phaseId}/step/execute/validate/${stepId}/`,
    );
  },
});
