import { Empty } from "antd";
import React from "react";
import { Icons } from "../../components/common/Icons";
import Pagination from "../../components/common/Pagination";
import { Button } from "../../components/common/ui/button";
import useGetUsersHook from "./hooks/useGetUsers.hook";
import { useUserHasPermission } from "../../hooks/useUserHasPermission";
import SkeletonList from "../../components/common/SkeletonList";
import { Table, TableBody } from "../../components/common/ui/table";
import { TableHeader } from "../../components/table/Header";
import UsersListItem from "../../components/users/UsersListItem/UsersListItem";
import UserDrawer2 from "../../components/users/UserDrawer/UserDrawer";

const headerClassName = "font-sans font-bold text-[#0F172A]";
const tableHeaderItems = [
  {
    title: "User",
    className: headerClassName,
  },
  {
    title: "Email",
    className: headerClassName,
  },
  {
    title: "Company",
    className: headerClassName,
  },
  {
    title: "Department",
    className: headerClassName,
  },
  {
    title: "Last Login Time",
    className: headerClassName,
  },
  null,
];

const UsersPage: React.FC = () => {
  const {
    users,
    isFetching,
    updatePageSize,
    updatePage,
    total,
    pageSize,
    pageNumber,
    onCreateUser,
    toggleOpenDrawer,
    drawerVisible,
  } = useGetUsersHook();
  const { hasUserCreateRole } = useUserHasPermission({});

  const renderPagination = () => (
    <Pagination
      updatePageSize={updatePageSize}
      updatePage={updatePage}
      total={total}
      pageSize={pageSize}
      pageNumber={pageNumber}
    />
  );

  return (
    <div className=" flex flex-col gap-[24px]">
      <div className="flex justify-between">
        <h1 className="text-slate-900 text-2xl font-semibold font-sans leading-normal">
          Users
        </h1>

        {hasUserCreateRole && (
          <Button
            icon={Icons.Plus}
            className="rounded-full"
            disabled={isFetching}
            onClick={() => {
              toggleOpenDrawer(true);
            }}
          >
            Add New User
          </Button>
        )}
      </div>

      {users.length ? (
        <>
          {renderPagination()}

          {isFetching ? (
            <SkeletonList quantity={Math.ceil(total / 3)} />
          ) : (
            <div className="rounded-md border w-full mx-auto">
              <Table>
                <TableHeader items={tableHeaderItems} />
                <TableBody className="font-sans">
                  {users.map((item) => (
                    <UsersListItem key={item.user.id} item={item} />
                  ))}
                </TableBody>
              </Table>
            </div>
          )}

          {renderPagination()}
        </>
      ) : (
        <>
          {isFetching ? (
            <SkeletonList quantity={3} />
          ) : (
            <div className="flex flex-col justify-center items-center w-full h-full">
              <Empty
                imageStyle={{ height: "30vh" }}
                description={
                  <span className="font-sans font-bold text-[#0F172A]">
                    There are no users in your workspace.
                  </span>
                }
              ></Empty>
            </div>
          )}
        </>
      )}

      {hasUserCreateRole && (
        <UserDrawer2
          visible={drawerVisible}
          onClose={() => toggleOpenDrawer(false)}
          initialFormValues={null}
          onCreateUser={onCreateUser}
        />
      )}
    </div>
  );
};

export default UsersPage;
