import { Navigate, Route, Routes } from 'react-router-dom';
import CompanyStoragePage from "../../pages/storage/companyStoragePage/CompanyStoragePage";
import ProtectedByRoleRoute from "../ProtectedByRoleRoute";
import { UserPermissions } from "../../store/slices/userDataSlice";
import CompanyPublicStorage from "../../components/storage/companyStorage/CompanyPublicStorage/CompanyPublicStorage";
import CompanyPrivateStorage from "../../components/storage/companyStorage/CompanyPrivateStorage/CompanyPrivateStorage";
import MicrositeStorage from "../../components/storage/micrositeStorage/MicrositeStorage";
import React from "react";

const CompanyStorageRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<CompanyStoragePage />}>
        <Route
          path="public"
          element={
            <ProtectedByRoleRoute
              permission={UserPermissions.ROLE_PUBLIC_FS_FILE_LIST}
              isCompanyRole={true}
            >
              <CompanyPublicStorage />
            </ProtectedByRoleRoute>
          }
        />
        <Route
          path="private"
          element={
            <ProtectedByRoleRoute
              permission={UserPermissions.ROLE_PRIVATE_FS_FILE_LIST}
              isCompanyRole={true}
            >
              <CompanyPrivateStorage />
            </ProtectedByRoleRoute>
          }
        />
        <Route
          path="microsite"
          element={
            <ProtectedByRoleRoute
              permission={UserPermissions.ROLE_MS_FS_FILE_LIST}
              isCompanyRole={true}
            >
              <MicrositeStorage />
            </ProtectedByRoleRoute>
          }
        />
      </Route>
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export default CompanyStorageRoutes;
