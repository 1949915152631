import { Spin, Tooltip } from "antd";
import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editEnvVarKeyThunk,
  getEnvVariables,
} from "../../../store/slices/envVariablesSlice";
import { AppDispatch } from "../../../store/store";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import ModifiedBy from "../../common/ModifiedBy";
import handleRequestError from "../../../utils/handleRequestError";
import { selectMessageApi } from "../../../store/slices/appSlice";
import TextArea from "antd/es/input/TextArea";

type Props = {
  itemKey: string;
  modifiedBy: null | number;
  modifiedTime: string;
};

const VariableItemKey: FC<Props> = ({ itemKey, modifiedTime, modifiedBy }) => {
  const { companyId } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const envVars = getEnvVariables();
  const [value, setValue] = useState(itemKey);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState("");
  const messageApi = useSelector(selectMessageApi);
  const { hasCampaignEnvItemRenameRole } = useUserHasPermission({ companyId });

  const onBlur = async () => {
    if (isOpened) {
      setValue(itemKey);
      setIsOpened(false);

      return;
    }

    if (value === itemKey) return;

    try {
      setIsLoading(true);

      await dispatch(
        editEnvVarKeyThunk({ currentKey: itemKey, newKey: value }),
      ).unwrap();

      messageApi.success("The key was successfully renamed");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);

      setValue(itemKey);
      setValue(itemKey);
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    const lowerCaseValue = value.toLowerCase();

    if (!value?.length) {
      setValue(value);
      setTooltipTitle(
        "The value should not be empty. The changes will not be saved!",
      );
      setIsOpened(true);

      return;
    }

    if (!/^[a-zA-Z_$][a-zA-Z0-9_$-]*$/.test(value)) {
      setValue(value);
      setTooltipTitle(
        "Invalid Javascript variable name. The changes will not be saved!",
      );
      setIsOpened(true);

      return;
    }

    if (
      itemKey.toLowerCase() !== lowerCaseValue &&
      envVars.map((item) => item.id.key.toLowerCase()).includes(lowerCaseValue)
    ) {
      setValue(value);
      setTooltipTitle(
        "The key already exists in this grid. The changes will not be saved!",
      );
      setIsOpened(true);

      return;
    }

    setValue(value);
    setTooltipTitle("");
    setIsOpened(false);
  };

  return (
    <div>
      {hasCampaignEnvItemRenameRole ? (
        <Spin spinning={isLoading} size="small">
          <Tooltip title={tooltipTitle} color={"red"} open={isOpened}>
            <TextArea
              autoSize
              value={value.length ? value : undefined}
              placeholder="not defined"
              onBlur={onBlur}
              onChange={onChange}
              disabled={isLoading}
              className="hover:border-indigo-600 border-transparent"
            />
          </Tooltip>
        </Spin>
      ) : (
        <span className="p-2">{value}</span>
      )}
      <ModifiedBy
        className="mt-3"
        modifiedBy={modifiedBy}
        modifiedTime={modifiedTime}
      />
    </div>
  );
};

export default VariableItemKey;
