import { TAsset, TLabels, TPhase } from "../globalTypes";
import {
  TCampaign,
  TCampaignResponseData,
} from "../store/slices/campaignsSlice";
import { TStep } from "../store/slices/stepsSlice";
import { TCcVariable } from "../store/slices/ccVariablesSlice";
import { TEnvVariable } from "../store/slices/envVariablesSlice";

type TStepData = TStep & {
  assets: TAsset[];
  ccItems: TCcVariable[];
};

export type TPhasesData = TPhase & {
  steps: TStepData[];
};

export type TCampaignData = TCampaignResponseData & {
  environment: TEnvVariable[];
  i18n: {
    id: { campaignId: number; lang: string; key: string };
    label: string;
  }[];
  ccItems: TCcVariable[];
  phases: TPhasesData[];
};

type TCampaignTransformedData = {
  steps: TStep[];
  phases: TPhase[];
  labels: TLabels;
  campaign: TCampaign;
  phase: TPhase | null;
  step: TStep | null;
  assets: TAsset[];
  stepCcItems: TCcVariable[];
};

const transformCampaignData = (
  data: TCampaignData,
  phaseId: number | undefined,
  stepId: number | undefined,
): TCampaignTransformedData => {
  const {
    id,
    companyId,
    aiModelId,
    type,
    name,
    phases,
    naturalId,
    microSiteContextFolder,
    i18n,
    modifiedBy,
    modifiedTime,
    companyLogo,
  } = data;

  const labels: TLabels = {};

  i18n.forEach(({ label, id }) => (labels[id.key] = label));

  const transformedData: TCampaignTransformedData = {
    steps: [],
    phases: [],
    campaign: {
      id,
      companyId,
      companyLogo,
      type,
      name,
      naturalId,
      microSiteContextFolder,
      aiModelId,
      modifiedBy,
      modifiedTime,
      companyName: null,
      modifiedById: null,
      modifiedByName: null,
    },
    phase: null,
    step: null,
    labels,
    assets: [],
    stepCcItems: [],
  };

  phases.forEach((phase, index) => {
    const { steps, ...restPhase } = phase;
    transformedData.phases.push({ ...restPhase });

    if (phaseId === undefined) {
      if (index === 0) {
        transformedData.phase = restPhase;

        steps.forEach((step, index) => {
          const { assets, ccItems, ...restStep } = step;
          transformedData.steps.push({ ...restStep });

          if (stepId === undefined && index === 0) {
            transformedData.assets = assets;
            transformedData.stepCcItems = ccItems;
            transformedData.step = restStep;
          }
        });
      }
    } else {
      if (restPhase.id === phaseId) {
        transformedData.phase = restPhase;

        steps.forEach((step) => {
          const { assets, ccItems, ...restStep } = step;
          transformedData.steps.push({ ...restStep });

          if (restStep.id === stepId) {
            transformedData.assets = assets;
            transformedData.stepCcItems = ccItems;
            transformedData.step = restStep;
          }
        });
      }
    }
  });

  return transformedData;
};

export default transformCampaignData;
