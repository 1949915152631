import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useList } from "react-use";
import { getSFTPUsersApi } from "src/api/sftp-users.api";
import { CardDescription, CardTitle } from "src/components/common/ui/card";
import { Table, TableBody } from "src/components/common/ui/table";
import { TableHeader } from "src/components/table/Header";
import { getMessageApi } from "src/store/slices/appSlice";
import handleRequestError from "src/utils/handleRequestError";
import { SftpUser } from "../../../globalTypes";
import { CreateSftpUserDialog } from "../CreateSftpUserDialog/CreateSftpUserDialog";
import SftpUserItem from "../SftpUser/SftpUserItem";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";
import { useParams } from "react-router-dom";

const headerClassName = "font-sans font-bold text-[#0F172A] text-[14px]";
const tableHeaderItems = [
  {
    title: "Username",
    className: headerClassName,
  },
  {
    title: "Password",
    className: headerClassName,
  },
  {
    title: "SFTP Endpoint",
    className: headerClassName,
  },
  {
    title: "",
    className: "w-12",
  },
];

export const SftpUsers = ({ micrositeId }: { micrositeId: number }) => {
  const { companyId } = useParams();
  const messageApi = getMessageApi();
  const [loading, setLoading] = useState<boolean>(false);
  const [sftpUsers, { set, push, filter, updateAt }] = useList<SftpUser>([]);
  const { hasCompanyMicrositeUserCreateRole } = useUserHasPermission({
    companyId,
  });

  useEffect(() => {
    const getUsers = async () => {
      try {
        setLoading(true);

        const { data } = await getSFTPUsersApi({ micrositeId });
        if (data) set(data);
      } catch (error: any) {
        const customError = handleRequestError(error);

        messageApi.error(customError.message);
        console.error(customError);
      } finally {
        setLoading(false);
      }
    };

    getUsers();
  }, []);

  return (
    <Spin spinning={loading}>
      <div className="flex flex-col w-full gap-4">
        <div className="flex justify-between">
          <div>
            <CardTitle className="font-sans text-[#0F172A] mb-2">
              SFTP Users
            </CardTitle>
            <CardDescription className="font-geist text-[#475569]">
              Manage SFTP users
            </CardDescription>
          </div>
          <div>
            {hasCompanyMicrositeUserCreateRole && micrositeId && (
              <CreateSftpUserDialog
                micrositeId={micrositeId}
                onCreated={(u) => push(u)}
              />
            )}
          </div>
        </div>
        <div className="rounded-md border w-full mx-auto">
          <Table>
            <TableHeader items={tableHeaderItems} />
            <TableBody>
              {sftpUsers.map((user, i) => (
                <SftpUserItem
                  key={user.id.username}
                  user={user}
                  i={i}
                  micrositeId={micrositeId}
                  filter={filter}
                  updateAt={updateAt}
                />
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </Spin>
  );
};
