import axios, { AxiosError } from "axios";

export type TCustomError = {
  message: string;
  originalError: Error;
  errorCode?: string;
  status?: number;
};

type TCMError = {
  stackTraceContent: string;
  timestamp: string;
  message: string;
  debugMessage: string;
};

export const handleRequestError = (error: Error | AxiosError): TCustomError => {
  if (axios.isAxiosError(error)) {
    const axiosError: AxiosError = error;

    if (axiosError.response) {
      const CMError = axiosError.response.data as TCMError;

      return {
        message: CMError.message || CMError.debugMessage || axiosError.message,
        errorCode: axiosError.code || undefined,
        status: axiosError.response.status,
        originalError: error,
      };
    } else if (axiosError.request) {
      return {
        message: `No response received from the server. Please try again later. Error: ${axiosError.message}`,
        originalError: error,
      };
    }
  }

  return {
    message: error.message,
    originalError: error,
  };
};

export default handleRequestError;
