import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

//goes to GridItemKey.tsx
const useScrollToAnchor = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const elementId = location.hash.substring(1);
      const element = document.getElementById(elementId);

      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
}

export default useScrollToAnchor;