import { Checkbox, Form, FormInstance, Input, InputRef, Modal } from "antd";
import { Rule } from "antd/es/form";
import { FC, useRef, useState } from "react";
import { TPhase, TPhaseFormValues } from "../../../../globalTypes";
import useSetFocus from "../../../../hooks/useSetFocus";
import useSubmitFormOnEnter from "../../../../hooks/useSubmitFormOnEnter";
import { customizeRequiredMark } from "../../../companies/CompanySite/CustomerSite";
import { useUserHasPermission } from "../../../../hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import { minMax } from "../../../../utils/validations";
import micrositeContextFolderTooltip from "../../../../constants/micrositeContextFolderTooltip";
import { MICROSITE_INPUT_PLACEHOLDER } from "../../../../constants";

type Props = {
  editPhase: TPhase | null;
  onSubmitForm: () => Promise<void>;
  onCancel: () => void;
  form: FormInstance<TPhaseFormValues>;
  isModalOpen: boolean;
  phaseNameValidationRule: Rule;
};

const PhaseModal: FC<Props> = ({
  onSubmitForm,
  form,
  isModalOpen,
  onCancel,
  editPhase,
  phaseNameValidationRule,
}) => {
  const { companyId } = useParams();
  const inputRef = useRef<InputRef>(null);
  const { hasPhaseShowHiddenRole } = useUserHasPermission({ companyId });
  const [isFetching, setIsFetching] = useState(false);

  const onSavePhase = async () => {
    setIsFetching(true);
    await onSubmitForm();
    setIsFetching(false);
  };

  useSubmitFormOnEnter(onSavePhase, { condition: !isFetching });
  useSetFocus(inputRef);

  return (
    <Modal
      title={`${editPhase ? "Edit" : "Add"} Phase`}
      open={isModalOpen}
      onOk={onSavePhase}
      confirmLoading={isFetching}
      okText={editPhase ? "Update" : "Save"}
      width={600}
      onCancel={onCancel}
    >
      <Form
        requiredMark={customizeRequiredMark}
        form={form}
        layout="vertical"
        name="phase_form"
        initialValues={
          !editPhase
            ? {
                // eslint-disable-next-line no-template-curly-in-string
                microSiteContextFolder: "${phase.name}",
              }
            : undefined
        }
      >
        <Form.Item
          name="name"
          label="Phase Name"
          tooltip="Please enter a string up to 255 characters long"
          rules={[
            phaseNameValidationRule,
            {
              type: "string",
              required: true,
              whitespace: true,
              message: "Required field!",
            },
            {
              max: 255,
              message: "Phase name must contain no more than 255 characters!",
            },
          ]}
        >
          <Input
            className="h-[48px]"
            placeholder="Enter Phase name"
            ref={inputRef}
          />
        </Form.Item>
        <Form.Item
          name="microSiteContextFolder"
          label="Microsite Context Folder"
          tooltip={micrositeContextFolderTooltip}
          rules={[minMax({ max: 255, text: "Path" })]}
        >
          <Input
            className="h-[48px]"
            placeholder={MICROSITE_INPUT_PLACEHOLDER}
          />
        </Form.Item>
        {hasPhaseShowHiddenRole && (
          <Form.Item name="hidden" valuePropName="checked">
            <Checkbox>Switch visibility</Checkbox>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default PhaseModal;
