import React from "react";
import { Button } from "../../../components/common/ui/button";
import { Icons } from "../../../components/common/Icons";
import { Link } from "react-router-dom";
import PermissionsList from "../../../components/securityGroups/PermissionsList";
import useGetPermissionsGroupList from "./useGetPermissionsGroupList.hook";
import SkeletonList from "../../../components/common/SkeletonList";

const SecurityPage: React.FC = () => {
  const {
    isFetching,
    permissionsGroupList,
    error,
    getUserPermissions,
    hasPermGroupCreateRole,
  } = useGetPermissionsGroupList();

  return (
    <div className="w-full flex flex-col gap-[24px]">
      <div className="flex justify-between">
        <h1 className=" text-slate-900 text-2xl font-semibold font-sans leading-normal">
          Security Groups
        </h1>

        {hasPermGroupCreateRole && (
          <Link to="/security-groups/create">
            <Button icon={Icons.Plus} className="rounded-full">
              Add Group
            </Button>
          </Link>
        )}
      </div>

      {error && <span className="text-red-600">{error}</span>}

      {isFetching ? (
        <SkeletonList quantity={3} />
      ) : (
        <PermissionsList
          userPermissions={permissionsGroupList}
          updateList={getUserPermissions}
        />
      )}
    </div>
  );
};

export default SecurityPage;
