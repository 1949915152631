import { GraphMetaInfo, TStepPhaseMetaInfo } from "../../../globalTypes";

const getStepPhaseMetaInfo = (metaInfo: GraphMetaInfo) => {
  const stepPhaseMetaInfoData: TStepPhaseMetaInfo = {};

  metaInfo.steps.forEach(({ id, phaseId, name }) => {
    const phase = metaInfo.phases.find((phase) => phase.id === phaseId);
    stepPhaseMetaInfoData[id] = {
      stepId: id,
      stepName: name,
      phaseId: phase?.id,
      phaseName: phase?.name,
    };
  });

  return stepPhaseMetaInfoData;
};

export default getStepPhaseMetaInfo;
