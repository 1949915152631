import { Link } from "react-router-dom";

const footerList = [
  {
    label: "Terms",
    path: "/terms",
  },
  {
    label: "Privacy",
    path: "/privacy",
  },
  {
    label: "Status",
    path: "/status",
  },
  {
    label: "Blog",
    path: "/blog",
  },
  {
    label: "Help",
    path: "/help",
  },
];

const Footer = () => {
  return (
    <div className="w-full h-[106px]">
      <div className="w-full h-[106px]">
        <div className="w-full h-[106px] bg-white border-t border-slate-200 flex justify-between items-center px-[48px]">
          <div className="h-8 justify-start items-center gap-2 inline-flex">
            <div className="w-8 h-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <g clipPath="url(#clip0_238_179)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.9423 1.89746C26.4641 1.11069 24.7687 0.830381 23.116 1.09951C21.4632 1.36864 19.9444 2.17235 18.7922 3.38745C17.64 4.60254 16.918 6.16196 16.7371 7.82666C16.5561 9.49137 16.926 11.1695 17.7901 12.6038C18.1093 13.1336 18.4894 13.6193 18.9202 14.0524L13.138 24.1714L6.59452 12.7202H13.138V8.89327H3.29726H0L1.63589 11.7561L11.4766 28.9775L13.138 31.885L14.7994 28.9775L22.2124 16.0045C23.5765 16.4005 25.029 16.4104 26.4062 16.0255C28.0189 15.5748 29.4393 14.6076 30.4497 13.2723C31.4601 11.937 32.0047 10.3071 32 8.6326L31.9946 6.71913L30.0812 6.72447L29.8659 6.72507L27.9525 6.73041L27.9578 8.64389C27.96 9.43407 27.703 10.2032 27.2262 10.8333C26.7495 11.4634 26.0792 11.9198 25.3182 12.1325C25.0006 12.2212 24.6744 12.2655 24.3489 12.2657L24.6401 11.7561L27.7235 6.36011L27.7322 6.36476L27.8868 6.07424L27.9203 6.01562L27.9186 6.01461L28.6312 4.67562L28.7324 4.48562L29.6314 2.7965L27.9423 1.89746ZM24.0625 5.05337C23.9634 5.06118 23.8643 5.0731 23.7656 5.08917C22.9858 5.21616 22.269 5.59541 21.7253 6.16879C21.1816 6.74217 20.841 7.47803 20.7556 8.26358C20.6817 8.94302 20.8023 9.6272 21.1001 10.2376L21.3173 9.85741L24.0625 5.05337Z"
                    fill="url(#paint0_linear_238_179)"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_238_179"
                    x1="0"
                    y1="16.4425"
                    x2="32.0016"
                    y2="16.4425"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#8602ED" />
                    <stop offset="1" stopColor="#F30066" />
                  </linearGradient>
                  <clipPath id="clip0_238_179">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="text-[#64748B] text-sm font-medium font-sans">
              Copyright © 2024 Velocity Engine Inc.
            </div>
          </div>
          <div className="h-2.5 justify-end items-center gap-[33px] inline-flex">
            {footerList.map((item) => {
              return (
                <Link
                  key={item.path}
                  to={item.path}
                  className="text-[#64748B] text-sm font-medium font-sans cursor-pointer"
                >
                  {item.label}
                </Link>
              );
            })}
          </div>
        </div>
        <div className="w-full h-1 bg-gradient-to-tr from-purple-700 to-rose-600" />
      </div>
    </div>
  );
};

export default Footer;
