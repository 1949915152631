import { useMemo, useState } from "react";
import { getPermissionsGroupApi } from "../../../api/permissions-group.api";
import handleRequestError from "../../../utils/handleRequestError";
import { TUserPermissionGroup } from "../../../types/securityGroupes";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { useParams } from "react-router-dom";

const useGetSecurityGroups = ({
  addGroupAndUpdateList,
}: {
  addGroupAndUpdateList: ({
    permissionsGroupId,
    userId,
  }: {
    userId: string | number;
    permissionsGroupId: number;
    companyId: number | string | null;
  }) => Promise<void>;
}) => {
  const { userId, companyId } = useParams();
  const [open, setOpen] = useState(false);
  const [securityGroupsList, setSecurityGroupsList] = useState<
    TUserPermissionGroup[]
  >([]);
  const messageApi = useSelector(selectMessageApi);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isFetchingAddGroup, setIsFetchingAddGroup] = useState(false);
  const [isFetchingGroups, setIsFetchingGroups] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const [error, setError] = useState("");

  const loadPermissions = async () => {
    try {
      setIsFetchingGroups(true);
      const { data } = await getPermissionsGroupApi();

      setSecurityGroupsList(data);
      setError("");
      setOpen(true);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      setError(customError.message);

      console.error(customError);
    } finally {
      setIsFetchingGroups(false);
      setIsInitialized(true);
    }
  };

  const addPermission = async (selectedGroupId: number | null) => {
    try {
      if (selectedGroupId === null || userId === undefined) return;

      setIsFetchingAddGroup(true);

      await addGroupAndUpdateList({
        userId,
        permissionsGroupId: selectedGroupId,
        //companyId is null in global permissions
        companyId: companyId || null,
      });

      handleOpenChange(false);
      setError("");
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      setError(customError.message);

      console.error(customError);
    } finally {
      setIsFetchingAddGroup(false);
    }
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
    setSelectedGroupId(null);
    setSecurityGroupsList([]);
  };

  const memoizedOptions = useMemo(
    () =>
      securityGroupsList.map((group) => ({
        value: group.id,
        label: group.title,
      })),
    [securityGroupsList],
  );

  return {
    isInitialized,
    error,
    options: memoizedOptions,
    setSelectedGroupId,
    selectedGroupId,
    addPermission,
    isFetchingAddGroup,
    loadPermissions,
    open,
    isFetchingGroups,
    handleOpenChange,
  };
};

export default useGetSecurityGroups;
