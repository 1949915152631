import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import handleRequestError from "../../../utils/handleRequestError";
import { getUserDataApi, updateUserApi } from "../../../api/user.api";
import { useParams } from "react-router-dom";
import { TUser, TUserForm } from "../../../globalTypes";

const useGetUserInfo = () => {
  const { userId } = useParams();
  const messageApi = useSelector(selectMessageApi);
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState("");
  const [userInfo, setUserInfo] = useState<TUser | null>(null);
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    if (userId) {
      loadUserInfo({ userId });
    }
  }, [userId]);

  const loadUserInfo = async ({ userId }: { userId: string }) => {
    try {
      const { data } = await getUserDataApi({
        userId,
      });

      setUserInfo(data);
      setError("");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      setError(customError.message);
      console.error(customError);
    } finally {
      setIsInitialized(true);
    }
  };

  const onUpdateUser = async ({ values }: { values: TUserForm }) => {
    if (!userId) {
      console.warn("There is no user ID for updating!");
      return;
    }

    const { data } = await updateUserApi({
      userData: values,
      userId,
    });

    setUserInfo(data);
  };

  const toggleOpenDrawer = (open: boolean) => {
    setDrawerVisible(open);
  };

  return {
    userInfo,
    isInitialized,
    error,
    onUpdateUser,
    drawerVisible,
    toggleOpenDrawer,
  };
};

export default useGetUserInfo;
