import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { useEffect, useState } from "react";
import handleRequestError from "../../../utils/handleRequestError";
import { useParams } from "react-router-dom";
import {
  addSecurityGroupToUserApi,
  deleteSecurityGroupFromUserApi,
  getUserGlobalPermissionsApi,
} from "../../../api/user.api";
import { TUserGlobalPermissions } from "../../../globalTypes";

const useGetGlobalPermissions = () => {
  const { userId } = useParams();
  const messageApi = useSelector(selectMessageApi);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState("");
  const [globalPermissions, setGlobalPermissions] =
    useState<TUserGlobalPermissions | null>(null);

  useEffect(() => {
    if (userId !== undefined) {
      loadPermissions({ userId });
    }
  }, [userId]);

  const loadPermissions = async ({ userId }: { userId: number | string }) => {
    try {
      const { data: userGroups } = await getUserGlobalPermissionsApi({
        userId,
      });

      setGlobalPermissions(userGroups);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      setError(customError.message);

      console.error(customError);
    } finally {
      setIsInitialized(true);
    }
  };

  const addGroupAndUpdateList = async ({
    permissionsGroupId,
    userId,
    companyId,
  }: {
    userId: number | string;
    permissionsGroupId: number;
    companyId: number | string | null;
  }) => {
    await addSecurityGroupToUserApi({
      userId,
      companyId,
      permissionsGroupId,
    });
    await updatePermissionsList();
  };

  const updatePermissionsList = async () => {
    try {
      if (userId === undefined) return;

      const { data: userGroups } = await getUserGlobalPermissionsApi({
        userId,
      });

      setGlobalPermissions(userGroups);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      setError(customError.message);

      console.error(customError);
    }
  };

  const deleteGroup = async ({
    permissionsGroupId,
  }: {
    permissionsGroupId: number;
  }) => {
    try {
      if (userId === undefined) return;

      setIsFetching(true);

      await deleteSecurityGroupFromUserApi({
        userId,
        permissionsGroupId,
      });

      const { data: userGroups } = await getUserGlobalPermissionsApi({
        userId,
      });

      setGlobalPermissions(userGroups);
      setError("");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      setError(customError.message);

      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isInitialized,
    globalPermissions,
    error,
    deleteGroup,
    updatePermissionsList,
    addGroupAndUpdateList,
    isFetching,
  };
};

export default useGetGlobalPermissions;
