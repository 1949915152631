import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TLabelLanguage, TLabels } from "../../globalTypes";
import { RootState } from "../store";
import {
  handleRequestError,
  TCustomError,
} from "../../utils/handleRequestError";
import { toggleIsFetching } from "./appSlice";
import {
  bulkDeleteGlobalLabelsApi,
  createGlobalLabelApi,
  getGlobalLabelsApi,
  updateGlobalLabelApi,
} from "../../api/global-labels.api";
import { useSelector } from "react-redux";

const initialState = {
  labels: {} as TLabels,
};

type InitialStateType = typeof initialState;

const globalLabelsSlice = createSlice({
  name: "globalLabels",
  initialState,
  reducers: {
    setGlobalLabels: (
      state: InitialStateType,
      action: PayloadAction<TLabels>,
    ) => {
      state.labels = action.payload;
    },
    addGlobalLabel: (
      state: InitialStateType,
      action: PayloadAction<{ key: string; value: string }>,
    ) => {
      const { key, value } = action.payload;

      state.labels[key] = value;
    },
    deleteGlobalLabels: (
      state: InitialStateType,
      action: PayloadAction<Array<string>>,
    ) => {
      action.payload.forEach((key) => {
        delete state.labels[key];
      });
    },
  },
});

export const { setGlobalLabels, addGlobalLabel, deleteGlobalLabels } =
  globalLabelsSlice.actions;

export default globalLabelsSlice.reducer;

/* eslint-disable*/
export const getGlobalLabels = (): TLabels =>
  useSelector((state: RootState) => state.globalLabels.labels);

//THUNK

export const getGlobalLabelsThunk = createAsyncThunk<
  undefined,
  undefined,
  { rejectValue: TCustomError }
>(
  "globalLabels/getGlobalLabelsThunk",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));
      const { data: globalLabels } = await getGlobalLabelsApi({
        lang: "en",
      });

      dispatch(setGlobalLabels(globalLabels));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to get global labels:`, e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  },
);

type TSaveLabelProps = {
  label: string;
  key: string;
  lang: TLabelLanguage;
  action: "create" | "update";
};
export const saveGlobalLabelThunk = createAsyncThunk<
  undefined,
  TSaveLabelProps,
  { rejectValue: TCustomError }
>("globalLabels/saveLabel", async (props, { rejectWithValue, dispatch }) => {
  try {
    const { label, lang, key, action } = props;

    dispatch(toggleIsFetching(true));

    if (action === "create") {
      await createGlobalLabelApi({ lang, key, label });
    } else {
      await updateGlobalLabelApi({ lang, key, label });
    }

    dispatch(addGlobalLabel({ key, value: label }));
  } catch (e: any) {
    const customError = handleRequestError(e);
    console.error(`An error occurred while trying to save global label:`, e);

    return rejectWithValue(customError);
  } finally {
    dispatch(toggleIsFetching(false));
  }
});

export type TDeleteLabelsProps = {
  keys: Array<string>;
  lang: TLabelLanguage;
};

export const deleteGlobalLabelsThunk = createAsyncThunk<
  undefined,
  TDeleteLabelsProps,
  { rejectValue: TCustomError }
>(
  "globalLabels/deleteLabels",
  async ({ keys, lang }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      await bulkDeleteGlobalLabelsApi({ keys, lang });

      dispatch(deleteGlobalLabels(keys));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to delete global label:`,
        e,
      );

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  },
);
