import { Empty, Skeleton } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { deleteAssetApi, uploadAssetApi } from "src/api/assets.api";
import { MenuDropdownItem } from "src/components/common/MenuDropdown";
import { TAsset } from "src/globalTypes";
import useConfirm from "src/hooks/useConfirm";
import { getMessageApi } from "src/store/slices/appSlice";
import handleRequestError from "src/utils/handleRequestError";
import FileTree from "../staticStorage/FileTree";
import { GetMenuActions } from "../staticStorage/hooks/types";
import useStaticStorage from "../staticStorage/hooks/useStaticStorage.hook";
import { convertAssetToFileTreeItem, getCampaignViewFileTree } from "./helpers";
import { AssetsFileTreeItem } from "./types";
import { menuOptions } from "../dynamicStorage/constants";
import { TStep } from "../../../store/slices/stepsSlice";
import { Icons } from "../../common/Icons";
import AssetModal from "../../campaignAssets/AssetModal/AssetModal";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";
import UploadAssetsModal from "../../common/modals/UploadAssetsModal";

const CampaignAssetsStorage = () => {
  const { campaignId } = useParams();
  const message = getMessageApi();
  const confirm = useConfirm();
  const [isAddAssetModalOpen, setIsAddAssetModalOpen] = useState(false);
  const [isUploadAssetsModalOpen, setIsUploadAssetsModalOpen] = useState(false);
  const [selectedStep, setSelectedStep] = useState<TStep | null>(null);
  const [editAssetData, setEditAssetData] = useState<TAsset | null>(null);
  const { companyId } = useParams();
  const { hasAssetReadRole, hasAssetDeleteRole, hasAssetCreateRole } =
    useUserHasPermission({
      companyId,
    });

  const getFileTree = React.useCallback(() => {
    if (!campaignId) {
      return Promise.resolve([]);
    }
    return getCampaignViewFileTree({
      campaignId,
    });
  }, [campaignId]);

  const handleGetLink = (item: AssetsFileTreeItem) => {
    if (!item.assetData?.ref) {
      return;
    }
    try {
      navigator.clipboard.writeText(item.assetData.ref);
      message.success("Link copied to clipboard");
    } catch (e: any) {
      const customError = handleRequestError(e);
      message.error(customError.message);
      console.error(customError);
    }
  };

  const handleDeleteFile = async (
    item: AssetsFileTreeItem,
    setTree: (tree: AssetsFileTreeItem[]) => void,
  ) => {
    if (!item.assetData?.id) {
      return;
    }
    confirm({
      action: async () => {
        try {
          await deleteAssetApi({
            campaignId: Number(campaignId),
            assetId: item.assetData!.id,
          });
          setTree(
            (treeData as AssetsFileTreeItem[]).filter((treeItem) => {
              if (treeItem.assetData) {
                return treeItem.assetData.id !== item.assetData!.id;
              }
              return true;
            }),
          );
          message.success("The asset has been deleted");
        } catch (e: any) {
          const customError = handleRequestError(e);
          message.error(customError.message);
          console.error(customError);
        }
      },
      title: `Delete asset "${item.assetData.title}"`,
    });
  };

  const handleAddAsset = (item: AssetsFileTreeItem) => {
    setIsAddAssetModalOpen(true);
    setEditAssetData(null);
    if (item.folder && item.stepData) {
      setSelectedStep(item.stepData);
    }
  };
  const handleUploadAsset = (item: AssetsFileTreeItem) => {
    setIsUploadAssetsModalOpen(true);
    if (item.stepData) {
      setSelectedStep(item.stepData);
    }
  };

  const getCampaignViewItemActions: GetMenuActions<AssetsFileTreeItem> = ({
    item,
    setTree,
  }) => {
    return [
      hasAssetCreateRole &&
        item?.stepData && {
          key: "addAsset",
          label: "Add Asset",
          icon: Icons.Plus,
          onClick: () => handleAddAsset(item),
        },
      hasAssetCreateRole &&
        item?.stepData && {
          key: "uploadAsset",
          label: "Upload Asset",
          icon: Icons.UploadIcon,
          onClick: () => handleUploadAsset(item),
        },
      hasAssetReadRole &&
        !item.folder && {
          ...menuOptions.getLink,
          label: (item as AssetsFileTreeItem).assetData?.ref?.includes(
            "amazonaws.com",
          )
            ? "Get Public AWS Link"
            : "Get Link",
          onClick: () => handleGetLink(item),
        },
      hasAssetDeleteRole &&
        !item.folder && {
          ...menuOptions.delete,
          onClick: () => handleDeleteFile(item, setTree),
          className: "!text-red-600",
        },
    ].filter(Boolean) as MenuDropdownItem[];
  };

  const handleAssetAdded = (asset: TAsset) => {
    handleTreeDataChange((treeData) => [
      ...treeData,
      convertAssetToFileTreeItem(asset, selectedStep!),
    ]);
  };

  const {
    selectedItem,
    treeData,
    isLoading,
    error,
    handleExpandFolder,
    handleTreeDataChange,
    handleSelectedItem,
    handleUnselectItems,
  } = useStaticStorage({
    getFileTree,
    getItemMenuActions: getCampaignViewItemActions,
  });

  const onUploadAsset = async (file: File) => {
    if (!campaignId || !selectedStep) {
      message.error("Unable to load the required data");
      console.error("Unable to get [campaignId] or [selectedStep]");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    const { data: newAsset } = await uploadAssetApi({
      campaignId,
      stepId: selectedStep.id,
      formData,
    });

    handleTreeDataChange((treeData) => [
      ...treeData,
      convertAssetToFileTreeItem(newAsset, selectedStep!),
    ]);
  };

  const renderContent = () => {
    if (isLoading) {
      return <Skeleton active />;
    }
    if (error) {
      return <span className="text-red-500">Error: {error}</span>;
    }
    if (treeData.length === 0) {
      return (
        <Empty
          imageStyle={{ height: "30vh" }}
          description={
            <span className="font-sans font-semibold text-[14px]">
              There are no files in the storage
            </span>
          }
        />
      );
    }
    return (
      <FileTree
        onExpandFolder={handleExpandFolder}
        treeData={treeData}
        onSelectedItemChange={handleSelectedItem}
        selectedItem={selectedItem}
        getItemMenuActions={getCampaignViewItemActions}
        setTreeData={handleTreeDataChange}
      />
    );
  };
  return (
    <div
      className="w-full max-h-[600px] overflow-y-scroll mt-[24px]"
      onClick={handleUnselectItems}
    >
      {renderContent()}

      {isAddAssetModalOpen && selectedStep && (
        <AssetModal
          editAsset={editAssetData}
          onAssetCreated={handleAssetAdded}
          isModalOpen={isAddAssetModalOpen}
          setIsModalOpen={(open) => {
            setIsAddAssetModalOpen(open);
            if (!open) {
              setSelectedStep(null);
              setEditAssetData(null);
            }
          }}
          campaignId={campaignId!}
          stepId={selectedStep!.id.toString()}
        />
      )}

      {isUploadAssetsModalOpen && selectedStep && (
        <UploadAssetsModal
          uploadAsset={onUploadAsset}
          setIsModalOpen={(open) => {
            setIsUploadAssetsModalOpen(open);
            if (!open) {
              setSelectedStep(null);
            }
          }}
          isModalOpen={isUploadAssetsModalOpen}
        />
      )}
    </div>
  );
};
export default React.memo(CampaignAssetsStorage);
