import axiosInstance from "src/utils/axios/axiosInstance";
import { SftpUser } from "../globalTypes";

export const getSFTPUsersApi = ({ micrositeId }: { micrositeId: number }) => {
  return axiosInstance.get<SftpUser[] | undefined>(
    `/api/secured/microsite/${micrositeId}/user/`,
  );
};

export const createSFTPUserApi = ({
  micrositeId,
  username,
}: {
  micrositeId: number;
  username: string;
}) => {
  return axiosInstance.post<SftpUser>(
    `/api/secured/microsite/${micrositeId}/user/`,
    { username },
  );
};

export const resetPasswordSFTPUserApi = ({
  micrositeId,
  username,
}: {
  micrositeId: number;
  username: string;
}) => {
  return axiosInstance.put<SftpUser>(
    `/api/secured/microsite/${micrositeId}/user/${username}/reset-password/`,
  );
};

export const deleteSFTPUserApi = ({
  micrositeId,
  username,
}: {
  micrositeId: number;
  username: string;
}) => {
  return axiosInstance.delete<void>(
    `/api/secured/microsite/${micrositeId}/user/${username}/`,
  );
};
