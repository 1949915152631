import { LoadingOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "src/components/common/ui/button";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import {
  TCcVariableId,
  TComputationState,
  updateCcItemStateThunk,
} from "../../../../store/slices/ccVariablesSlice";
import { AppDispatch } from "../../../../store/store";
import {
  ComputedIcon,
  CreatedIcon,
  SolidErrorIcon,
} from "../../../common/Icons";
import handleRequestError from "../../../../utils/handleRequestError";
import { useUserHasPermission } from "../../../../hooks/useUserHasPermission";
import { useParams } from "react-router-dom";
import useConfirm from "../../../../hooks/useConfirm";

type Props = {
  executable: boolean;
  varId: TCcVariableId;
  state: TComputationState;
};
const GridItemState: React.FC<Props> = (props) => {
  const { companyId } = useParams();
  const { executable, varId, state } = props;
  const dispatch: AppDispatch = useDispatch();
  const messageApi = useSelector(selectMessageApi);
  const { hasCampaignCcUpdateStateRole } = useUserHasPermission({ companyId });
  const confirm = useConfirm();

  const onUpdateCcItemState = async (state: TComputationState) => {
    try {
      await dispatch(updateCcItemStateThunk({ varId, state })).unwrap();

      messageApi.success(`State successfully updated!`);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const generateTitle = (status: string, action?: string) => (
    <div>
      <strong>State: </strong>
      <span>{status}</span>
      {action && (
        <>
          <br />
          <span>{action}</span>
        </>
      )}
    </div>
  );

  if (!executable) {
    return (
      <div className="flex w-full justify-center">
        <Tooltip title={generateTitle("Non-executable")}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-primary"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12"
            />
          </svg>
        </Tooltip>
      </div>
    );
  }

  switch (state) {
    case "computed":
      return hasCampaignCcUpdateStateRole ? (
        <Tooltip
          title={generateTitle("Computed", "Click to switch to Created state")}
        >
          <Button
            onClick={() => onUpdateCcItemState("created")}
            variant={"ghost"}
            className="p-3 text-[#16A34A] hover:text-[#16A34A]"
            icon={ComputedIcon}
          />
        </Tooltip>
      ) : (
        <Tooltip
          title={generateTitle("Computed")}
          className="text-[#16A34A] hover:text-[#16A34A]"
        >
          <ComputedIcon className="text-[#16A34A]" />
        </Tooltip>
      );
    case "created":
      return hasCampaignCcUpdateStateRole ? (
        <Tooltip
          title={generateTitle("Created", "Click to switch to Computed state")}
        >
          <Button
            onClick={() => onUpdateCcItemState("computed")}
            icon={CreatedIcon}
            variant={"ghost"}
            className="p-3 text-[#D97706] hover:text-[#D97706]"
          />
        </Tooltip>
      ) : (
        <Tooltip title={generateTitle("Created")}>
          <CreatedIcon className="text-[#D97706]" />
        </Tooltip>
      );
    case "error":
      return (
        <Tooltip title={generateTitle("Error")}>
          <span className="text-red-600">
            <SolidErrorIcon className="mx-auto" />
          </span>
        </Tooltip>
      );
    case "processing":
      return hasCampaignCcUpdateStateRole ? (
        <Tooltip
          title={generateTitle(
            "Processing",
            "Click to switch to Created state",
          )}
        >
          <Button
            onClick={() =>
              confirm({
                action: () => onUpdateCcItemState("created"),
                title: "Reset State",
                content:
                  "Are you sure that you want to change the state to created, this may affect the correct execution of the process?",
              })
            }
            icon={LoadingOutlined}
            iconClassName="text-[20px] text-[brown]"
            variant={"ghost"}
            className="p-3 text-[#D97706] hover:text-[#D97706]"
          />
        </Tooltip>
      ) : (
        <Tooltip title={generateTitle("Processing")}>
          <LoadingOutlined style={{ color: "brown", fontSize: "20px" }} />
        </Tooltip>
      );
    default:
      return <span>Error! No state!</span>;
  }
};

export default GridItemState;
