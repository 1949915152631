import { LockOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Modal, Spin, Tooltip, Typography } from "antd";
import { FC, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "@hello-pangea/dnd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TabsCarousel from "src/components/common/TabsCarousel";
import { Button } from "src/components/common/ui/button";
import {
  TMovePhaseStepType,
  TPhase,
  TPhaseFormValues,
} from "../../../globalTypes";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { TCampaign } from "../../../store/slices/campaignsSlice";
import {
  movePhaseThunk,
  savePhaseThunk,
  updatePhaseThunk,
} from "../../../store/slices/phasesSlice";
import { AppDispatch } from "../../../store/store";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import { getPhaseNameValidationRule } from "../../../utils/validators";
import PhaseModal from "../../common/modals/PhaseModal/PhaseModal";
import Phase from "../Phase/Phase";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";
import handleRequestError from "../../../utils/handleRequestError";
import { getConfirmationMessage } from "../../../utils/getConfirmationMessage";

type Props = {
  currentCampaign: TCampaign;
  phases: TPhase[];
  currentPhase: TPhase | null;
  companyId?: number;
};

const CampaignPhases: FC<Props> = ({
  currentCampaign,
  phases,
  currentPhase,
  companyId,
}) => {
  const { id: campaignId, name: campaignName } = currentCampaign;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPhaseMoving, setIsPhaseMoving] = useState(false);
  const [editPhase, setEditPhase] = useState<TPhase | null>(null);
  const [form] = Form.useForm<TPhaseFormValues>();
  const [addBeforeAfterProps, setAddBeforeAfterProps] = useState<{
    targetPhaseId: number;
    addType: TMovePhaseStepType;
  } | null>(null);
  const dispatch: AppDispatch = useDispatch();
  const messageApi = useSelector(selectMessageApi);
  const navigate = useNavigate();
  const { hasPhaseCreateRole, hasPhaseListRole, hasPhaseMoveRole } =
    useUserHasPermission({ companyId });
  const phaseNameValidationRule = getPhaseNameValidationRule({
    editPhaseName: editPhase?.name,
    phases,
  });

  const onDragEnd: OnDragEndResponder = async (result) => {
    try {
      if (!result.destination) {
        return;
      }
      setIsPhaseMoving(true);

      const {
        source: { index: sourceIndex },
        destination: { index: destinationIndex },
      } = result;

      if (sourceIndex === destinationIndex) return;

      const moveType = destinationIndex < sourceIndex ? "BEFORE" : "AFTER";
      const targetPhaseId = phases[destinationIndex].id;
      const { id, campaignId } = phases[sourceIndex];

      await dispatch(
        movePhaseThunk({ targetPhaseId, moveType, campaignId, phaseId: id }),
      ).unwrap();
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsPhaseMoving(false);
    }
  };

  async function onCreatePhase(values: TPhaseFormValues) {
    try {
      const newPhase = await dispatch(
        savePhaseThunk({
          phaseName: values.name,
          hidden: values.hidden,
          microSiteContextFolder: values.microSiteContextFolder,
          targetPhaseId: addBeforeAfterProps
            ? addBeforeAfterProps.targetPhaseId
            : undefined,
          addType: addBeforeAfterProps ? addBeforeAfterProps.addType : "AFTER",
        }),
      ).unwrap();

      //if companyId === undefined it implies the company is global
      const companyIdForUrl = companyId === undefined ? "global" : companyId;
      const path = `/campaigns/company/${companyIdForUrl}/campaign/${campaignId}/phase/${newPhase.id}`;

      onCancel();
      navigate(path, { replace: true });

      messageApi.success("The phase has been successfully created");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  }

  const onMove = async ({
    moveType,
    targetPhaseId,
    phaseId,
  }: {
    moveType: TMovePhaseStepType;
    targetPhaseId: number;
    phaseId: number;
  }) => {
    try {
      setIsPhaseMoving(true);

      await dispatch(
        movePhaseThunk({
          targetPhaseId,
          moveType,
          campaignId,
          phaseId,
        }),
      ).unwrap();

      messageApi.success("The phase has been successfully moved");
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsPhaseMoving(false);
    }
  };

  const updatePhase = async (values: TPhaseFormValues, rebuild: boolean) => {
    try {
      await dispatch(
        updatePhaseThunk({
          phase: {
            ...editPhase!,
            name: values.name,
            hidden: values.hidden,
            microSiteContextFolder: values.microSiteContextFolder,
          },
          rebuild,
        }),
      ).unwrap();

      onCancel();
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error("An error occurred while trying to update the phase:", e);
    }
  };

  const onEditPhase = async (values: TPhaseFormValues) => {
    const isMicroSiteContextFolderChanged =
      values.microSiteContextFolder !== editPhase?.microSiteContextFolder;

    const isPhaseNameChanged = values.name !== editPhase?.name;
    const confirmationMessage = getConfirmationMessage(
      isMicroSiteContextFolderChanged,
      isPhaseNameChanged,
      "Phase Name",
    );

    if (confirmationMessage) {
      Modal.confirm({
        title: "Confirm phase change",
        content: confirmationMessage,
        okText: "Confirm",
        cancelText: "Cancel",
        onOk: () => updatePhase(values, true),
      });
    } else {
      await updatePhase(values, false);
    }
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TPhaseFormValues) => {
      editPhase ? await onEditPhase(values) : await onCreatePhase(values);
    },
  });

  const onCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
    setEditPhase(null);
    setAddBeforeAfterProps(null);
  };

  return (
    <Spin spinning={isPhaseMoving}>
      <div className="flex items-center">
        {hasPhaseListRole && (
          <TabsCarousel
            className="flex rounded-tr-[8px] items-center overflow-x-scroll relative bg-slate-50"
            rightButtonClassName={"rounded-tr-[8px]"}
            leftButtonClassName={"rounded-tl-[8px]"}
          >
            {!!phases.length ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided, _snap) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="flex"
                    >
                      {phases.map((phase, index) => {
                        const prevPhase = phases[index - 1];
                        const nextPhase = phases[index + 1];

                        const onAddBefore = () => {
                          setAddBeforeAfterProps({
                            targetPhaseId: phase.id,
                            addType: "BEFORE",
                          });
                          setIsModalOpen(true);
                        };

                        const onAddAfter = () => {
                          setAddBeforeAfterProps({
                            targetPhaseId: phase.id,
                            addType: "AFTER",
                          });
                          setIsModalOpen(true);
                        };

                        const onMoveBefore = prevPhase
                          ? async () => {
                              await onMove({
                                moveType: "BEFORE",
                                targetPhaseId: prevPhase.id,
                                phaseId: phase.id,
                              });
                            }
                          : undefined;

                        const onMoveAfter = nextPhase
                          ? async () => {
                              await onMove({
                                moveType: "AFTER",
                                targetPhaseId: nextPhase.id,
                                phaseId: phase.id,
                              });
                            }
                          : undefined;

                        return (
                          <Draggable
                            isDragDisabled={!hasPhaseMoveRole}
                            key={phase.id}
                            draggableId={String(phase.id)}
                            index={index}
                          >
                            {(provided, snap) => (
                              <div
                                style={{
                                  position: "relative",
                                  backgroundColor: snap.isDragging
                                    ? "lightblue"
                                    : "white",
                                }}
                                className="relative"
                                key={phase.id}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <Phase
                                  onMoveBefore={onMoveBefore}
                                  onMoveAfter={onMoveAfter}
                                  onAddBefore={onAddBefore}
                                  onAddAfter={onAddAfter}
                                  campaignId={campaignId}
                                  campaignName={campaignName}
                                  phase={phase}
                                  companyId={companyId}
                                  isCurrentPhase={currentPhase?.id === phase.id}
                                  form={form}
                                  setIsModalOpen={setIsModalOpen}
                                  phaseNameValidationRule={
                                    phaseNameValidationRule
                                  }
                                  setEditPhase={setEditPhase}
                                  dragHandleProps={provided.dragHandleProps}
                                />
                                {phase.hidden && (
                                  <Tooltip
                                    title={
                                      <span>
                                        This Phase is hidden from users.
                                        <br />
                                        To switch the state, go to the Edit
                                        Paste modal.
                                      </span>
                                    }
                                  >
                                    <LockOutlined className="absolute top-[5px] left-[5px]" />
                                  </Tooltip>
                                )}
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <Typography.Text>
                There are no phases in this campaign yet
              </Typography.Text>
            )}
            {hasPhaseCreateRole && (
              <Button
                className="border-transparent bg-transparent border-b-2 hover:bg-primary/10 rounded-none h-[48px]"
                onClick={() => setIsModalOpen(true)}
                variant="primaryOutline"
                icon={PlusOutlined}
              >
                Add Phase
              </Button>
            )}
          </TabsCarousel>
        )}
      </div>
      {isModalOpen && (
        <PhaseModal
          onCancel={onCancel}
          onSubmitForm={submitForm}
          form={form}
          isModalOpen={isModalOpen}
          editPhase={editPhase}
          phaseNameValidationRule={phaseNameValidationRule}
        />
      )}
    </Spin>
  );
};

export default CampaignPhases;
