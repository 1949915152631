import React from "react";
import useGetGlobalPermissions from "./useGetGlobalPermissions.hook";
import { Button, Empty, Skeleton, Spin } from "antd";
import UserGlobalPermissionsTree from "./UserGlobalPermissionsTree";
import AddSecurityGroup from "../AddSecurityGroup/AddSecurityGroup";
import { useSelector } from "react-redux";
import { selectPermissionsTree } from "../../../store/slices/userDataSlice";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";

const UserGlobalPermissions = () => {
  const {
    isInitialized,
    globalPermissions,
    error,
    updatePermissionsList,
    deleteGroup,
    isFetching,
    addGroupAndUpdateList,
  } = useGetGlobalPermissions();
  const {
    globalLevel: globalPermissionsTree,
    companyLevel: companyPermissionsTree,
  } = useSelector(selectPermissionsTree);
  const { hasUserGlobalPermCreateRole, hasUserGlobalPermDeleteRole } =
    useUserHasPermission({});

  if (!isInitialized) return <Skeleton active className="mt-[24px]" />;

  const globalPermissionGroups = globalPermissions?.permissionGroups || [];

  return (
    <div className="flex justify-between mt-[24px]">
      <div className="w-[25%] flex flex-col gap-[12px]">
        <div className="font-bold text-[16px] text-[#475569]">
          Permission Groups
        </div>
        {hasUserGlobalPermCreateRole && (
          <AddSecurityGroup addGroupAndUpdateList={addGroupAndUpdateList} />
        )}
        {error && <div className="text-red-600">{error}</div>}
        <Spin spinning={isFetching}>
          {globalPermissionGroups.length ? (
            <div className="flex flex-col w-full gap-[6px]">
              {globalPermissionGroups.map(({ id, title }) => {
                return (
                  <div key={id}>
                    <div key={id} className="flex justify-between gap-[12px]">
                      <div>{title}</div>

                      {hasUserGlobalPermDeleteRole && (
                        <Button
                          danger
                          size="small"
                          onClick={() =>
                            deleteGroup({
                              permissionsGroupId: id,
                            })
                          }
                          className="w-[60px]"
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                    <div className="h-[1px] bg-[#E2E8F0] w-[calc(100%-80px)] mt-[6px]" />
                  </div>
                );
              })}
            </div>
          ) : (
            <Empty
              description={
                <div>
                  <span className="font-sans font-medium text-[#0F172A]">
                    There are no security groups.
                  </span>
                </div>
              }
            />
          )}
        </Spin>
      </div>
      <div className="w-[1px] bg-[#E2E8F0]" />
      {globalPermissions && (
        <div className="w-[73%] flex justify-between">
          <UserGlobalPermissionsTree
            permissions={globalPermissions.permissions}
            permissionOverrides={globalPermissions.permissionOverrides}
            updatePermissionsList={updatePermissionsList}
            permissionsTree={globalPermissionsTree}
            level="GLOBAL"
          />
          <div className="w-[1px] bg-[#E2E8F0] shrink-0" />
          <UserGlobalPermissionsTree
            permissions={globalPermissions.permissions}
            permissionOverrides={globalPermissions.permissionOverrides}
            updatePermissionsList={updatePermissionsList}
            permissionsTree={companyPermissionsTree}
            level="COMPANY"
          />
        </div>
      )}
    </div>
  );
};

export default UserGlobalPermissions;
