import React from "react";
import { Navigate, Route, Routes } from 'react-router-dom';
import ProtectedByRoleRoute from "../ProtectedByRoleRoute";
import { UserPermissions } from "../../store/slices/userDataSlice";
import { ReactFlowProvider } from "reactflow";
import GraphPage from "../../pages/graph/GraphPage";

const GraphRoutes = () => {
  return (
    <Routes>
      <Route
        path=":companyId/:campaignId/:phaseId/:stepId/:key"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_CAMPAIGN_CC_GRAPH}
            isCompanyRole={true}
          >
            <ReactFlowProvider>
              <GraphPage />
            </ReactFlowProvider>
          </ProtectedByRoleRoute>
        }
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export default GraphRoutes;
