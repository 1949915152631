import { usePostHog } from "posthog-js/react";
import { useDispatch } from "react-redux";
import { logoutThunk } from "src/store/slices/userDataSlice";
import { AppDispatch } from "src/store/store";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import * as React from "react";
import { TUser } from "../../globalTypes";

const UserAvatar = ({ user }: { user: TUser }) => {
  const dispatchRedux: AppDispatch = useDispatch();
  const posthog = usePostHog();

  const handleLogout = () => {
    posthog?.capture("The user logged out");

    dispatchRedux(logoutThunk());
  };

  return (
    <>
      <div className="font-sans">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="size-[32px] rounded-full flex items-center justify-center bg-[#F472B6] cursor-pointer">
              <span className="text-[#0D0A37] text-[12px] font-sans">
                {(user.firstName[0] + user.lastName[0]).toUpperCase()}
              </span>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            <DropdownMenuLabel className="font-sans text-lg font-bold">
              My Account
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <div className="px-2 py-1.5 text-sm font-semibold">
              <div className="flex items-center space-x-2 w-[50%]">
                <span className="break-words max-w-full">{`${user.firstName} ${user.lastName}`}</span>
              </div>
              <div className="mt-1 text-xs text-gray-600 break-words max-w-full">
                {user.email}
              </div>
            </div>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuItem
                className="font-sans cursor-pointer text-red-500"
                onClick={handleLogout}
              >
                Logout
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </>
  );
};

export default UserAvatar;
