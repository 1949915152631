import { AxiosResponse } from "axios";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import { downloadFileUtil } from "../../../../utils/cm.utils";
import handleRequestError from "../../../../utils/handleRequestError";

type Props = {
  download: (path: string) => Promise<AxiosResponse<ArrayBuffer | Blob, any>>;
};
const useDownload = ({ download }: Props) => {
  const messageApi = useSelector(selectMessageApi);
  return async (path: string) => {
    try {
      messageApi.open({
        key: "download_file",
        type: "loading",
        content: "Downloading...",
      });
      const response = await download(path);
      downloadFileUtil(response);
      messageApi.open({
        key: "download_file",
        type: "success",
        content: "Download completed successfully",
      });
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(customError);
      messageApi.open({
        key: "download_file",
        type: "error",
        content: customError.message,
      });
    }
  };
};
export default useDownload;
