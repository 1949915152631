import { Form, Modal } from "antd";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { importCampaignApi } from "../../../api/campaigns.api";
import { TImportCampaignFormValues } from "../../../globalTypes";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import { getMessageApi } from "../../../store/slices/appSlice";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import ImportCampaignForm from "../ImportCampaignForm/ImportCampaignForm";

type Props = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
};

const ImportCampaignModal: FC<Props> = (props) => {
  const { setIsModalOpen, isModalOpen } = props;
  const [isFetching, setIsFetching] = useState(false);
  const messageApi = getMessageApi();
  const [form] = Form.useForm<TImportCampaignFormValues>();
  const navigate = useNavigate();

  useSubmitFormOnEnter(() => submitForm());

  const onCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const onImport = async (values: TImportCampaignFormValues) => {
    try {
      setIsFetching(true);

      const { file, companyId } = values;
      const formData = new FormData();

      formData.append("file", file.file);

      const { data: newCampaign } = await importCampaignApi({
        formData,
        companyId,
      });

      navigate(
        `/campaigns/company/${newCampaign.companyId}/campaign/${newCampaign.id}`,
      );

      messageApi.success("Successfully imported");
    } catch (e: any) {
      messageApi.error(e.response.data.message);
      console.error("Can't import campaign", e);
    } finally {
      setIsModalOpen(false);
      setIsFetching(false);
    }
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TImportCampaignFormValues) => {
      await onImport(values);
    },
  });

  return (
    <Modal
      title="Import Campaign"
      open={isModalOpen}
      okText="Import"
      cancelText="Cancel"
      confirmLoading={isFetching}
      onOk={submitForm}
      onCancel={onCancel}
    >
      <ImportCampaignForm form={form} />
    </Modal>
  );
};

export default ImportCampaignModal;
