import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Upload, UploadFile, UploadProps } from "antd";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { uploadPackageTemplateApi } from "src/api/document-templates.api";
import { UploadTemplatePackage } from "src/globalTypes";
import useSubmitFormOnEnter from "src/hooks/useSubmitFormOnEnter";
import { selectMessageApi } from "src/store/slices/appSlice";
import { fetchSystemTemplatesOptions } from "src/utils/apiUtils";
import submitFormWithTrim from "src/utils/submitFormWithTrim";
import handleRequestError from "../../../utils/handleRequestError";
import SelectWithHighlightSearch from "../SelectWithHighlightSearch";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const UploadTemplatePackageModal: FC<Props> = ({ open, setOpen }) => {
  const messageApi = useSelector(selectMessageApi);
  const navigate = useNavigate();
  const [fileList, setFileList] = useState<Array<UploadFile<any>>>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [form] = Form.useForm<UploadTemplatePackage>();

  useSubmitFormOnEnter(() => submitForm(), { condition: !isFetching });

  //need timeout to reset form, research how to improve
  const resetFile = () => {
    setTimeout(() => {
      form.resetFields(["file"]);
      setFileList([]);
    }, 0);
  };

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: "application/zip",
    fileList,
    onChange(info) {
      setFileList(info.fileList);
    },
    onRemove(_) {
      resetFile();
    },
    beforeUpload(file) {
      const maxSize = 100 * 1024 * 1024;

      if (file.size > maxSize) {
        messageApi.error("The file size exceeds the allowed limit (100 MB)");
        resetFile();
      } else {
        form.setFieldValue("file", file);
      }

      return false;
    },
  };

  const onImport = async (values: UploadTemplatePackage) => {
    try {
      setIsFetching(true);

      const { file, templateId } = values;
      const formData = new FormData();

      formData.append("file", file.file);

      const { data } = await uploadPackageTemplateApi({ formData, templateId });

      navigate(`/templates/documents/edit/${data.id}`);

      messageApi.success("Successfully imported");
      setOpen(false);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(e);
    } finally {
      setIsFetching(false);
    }
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: UploadTemplatePackage) => {
      await onImport(values);
    },
  });

  //TODO move to util and reuse
  const customizeRequiredMark = (
    label: React.ReactNode,
    { required }: { required: boolean },
  ) => (
    <span className="font-sans font-bold text-[#475569] text-[14px] flex">
      {label}
      {required && <span className="text-red-500 ml-1">*</span>}
    </span>
  );

  return (
    <Modal
      title={`Upload Template Package`}
      open={open}
      onOk={submitForm}
      onCancel={() => setOpen(false)}
      className="w-[700px]"
      okText="Upload"
      cancelText="Cancel"
      confirmLoading={isFetching}
    >
      <Form
        form={form}
        layout="vertical"
        name="upload_template_form"
        requiredMark={customizeRequiredMark}
      >
        <Form.Item
          name="file"
          tooltip={
            <span>
              Supported formats: <strong>.zip</strong>.<br />
            </span>
          }
          label="File"
          rules={[{ required: true, message: "Required field!" }]}
        >
          <Upload {...uploadProps} disabled={isFetching}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="templateId"
          label="Choose System Template"
          rules={[{ required: true, message: "Required field!" }]}
        >
          <SelectWithHighlightSearch
            onSelect={(templateId: number | undefined) => {
              form.setFieldValue("templateId", templateId);
              form.validateFields(["templateId"]);
            }}
            fetchOptions={fetchSystemTemplatesOptions}
            optionLabel="mediaType"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
