import { getNumOfVarsInPrompt } from "../../../utils/cm.utils";
import { TVarType, VarTypeEnum } from "../../../types";
import { PostProcessingItem } from "./PostProcessingItems";

export const prepareTemplate = (template: string) => {
  const count = getNumOfVarsInPrompt(template);
  const varKeysArr = Array(count).fill(null);

  return {
    count,
    varKeysArr,
    template,
  };
};

export const getTypeBasedOnSegmented = (
  varType: VarTypeEnum,
): PostProcessingItem["type"] => {
  if (varType === VarTypeEnum.CAMPAIGN_VAR) {
    return "cc";
  }

  if (varType === VarTypeEnum.ENVIRONMENT_VAR) {
    return "env";
  }

  return "text";
};

export const getSegmentedBasedOnType = (type: string): TVarType => {
  if (type === "cc") {
    return VarTypeEnum.CAMPAIGN_VAR;
  }

  if (type === "env") {
    return VarTypeEnum.ENVIRONMENT_VAR;
  }

  return VarTypeEnum.RAW;
};