import React from "react";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../../store/slices/appSlice";

type Props = {
  setFileData: React.Dispatch<React.SetStateAction<string | undefined>>;
  openEditDrawer: () => void;
  getFileData: (path: string) => Promise<any>;
};
const useEditFile = ({ openEditDrawer, setFileData, getFileData }: Props) => {
  const messageApi = useSelector(selectMessageApi);
  return async (path: string) => {
    try {
      messageApi.open({
        key: "load_file",
        type: "loading",
        content: "Loading the file...",
      });
      const data = await getFileData(path);
      if (typeof data !== "string") {
        setFileData(JSON.stringify(data, null, 2));
      } else {
        setFileData(data);
      }
      openEditDrawer();
      messageApi.open({
        key: "load_file",
        type: "success",
        content: "The file has been loaded successfully",
      });
    } catch (e: any) {
      console.error(e);
      messageApi.open({
        key: "load_file",
        type: "error",
        content: "Failed to load the file",
      });
    }
  };
};
export default useEditFile;
