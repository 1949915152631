import { Form } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCcVarsApi } from "../../../api/cc-variables.api";
import { selectMessageApi } from "../../../store/slices/appSlice";
import {
  TCcVariable,
  useCcDynamicFormItems,
} from "../../../store/slices/ccVariablesSlice";
import handleRequestError from "../../../utils/handleRequestError";
import { TitleModal } from "../../CampaignGrid/Grid/CampaignGrid";

const useGetDynamicData = (ccItem: TCcVariable) => {
  const { id, type } = ccItem;
  const dynamicFormItems = useCcDynamicFormItems();
  const { companyId } = useParams();
  const messageApi = useSelector(selectMessageApi);
  const [form] = Form.useForm();
  const [stepCCVars, setStepCCVars] = useState<TCcVariable[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const companyIdValidated =
    companyId && companyId !== "global" ? +companyId : undefined;

  useEffect(() => {
    getCCStepVars();
  }, [ccItem]);

  const getCCStepVars = async () => {
    try {
      setIsFetching(true);

      const { data } = await getCcVarsApi({
        campaignId: id.campaignId,
        stepId: id.stepId,
      });

      setStepCCVars(data);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const dynamicItems = useMemo(
    () =>
      dynamicFormItems[type]
        ? [...dynamicFormItems[type].items].sort((a, b) => a.order - b.order)
        : [],
    [dynamicFormItems],
  );

  const ccVarData = useMemo(
    () => ({
      variableData: ccItem,
      actionType: TitleModal.EDIT,
    }),
    [ccItem],
  );

  return {
    dynamicItems,
    companyId: companyIdValidated,
    form,
    ccVarData,
    stepCCVars,
    isFetching,
    setIsFetching,
  };
};

export default useGetDynamicData;
