import { Drawer, Form, Spin } from "antd";
import React, { FC, useEffect, useState } from "react";
import handleRequestError from "../../../utils/handleRequestError";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import { Button } from "../../common/ui/button";
import UserForm from "../UserForm/UserForm";
import { TUserForm } from "../../../globalTypes";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";

type Props = {
  visible: boolean;
  onClose: () => void;
  initialFormValues: TUserForm | null;
  onUpdateUser?: ({ values }: { values: TUserForm }) => Promise<void>;
  onCreateUser?: ({ values }: { values: TUserForm }) => Promise<void>;
};

const UserDrawer: FC<Props> = ({
  visible,
  onCreateUser,
  onUpdateUser,
  onClose,
  initialFormValues,
}) => {
  const [form] = Form.useForm<TUserForm>();
  const messageApi = useSelector(selectMessageApi);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (visible) {
      if (initialFormValues) {
        form.setFieldsValue(initialFormValues);
      } else {
        form.resetFields();
      }
    }
  }, [initialFormValues, visible]);

  const submitForm = () => {
    return submitFormWithTrim({
      form,
      onSuccessValidationCb: async (values: TUserForm) => {
        await onFormSubmit(values);
      },
    });
  };

  const onFormSubmit = async (values: TUserForm) => {
    try {
      setIsFetching(true);

      if (initialFormValues) {
        onUpdateUser && (await onUpdateUser({ values }));
      } else {
        onCreateUser && (await onCreateUser({ values }));
      }

      onClose();

      messageApi.success(
        initialFormValues
          ? "The user has been successfully updated"
          : "The user has been successfully created",
      );
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Drawer
      title={
        <div className="font-sans text-white text-base">
          {initialFormValues
            ? `${initialFormValues.firstName} ${initialFormValues.lastName}`
            : "Create User"}
        </div>
      }
      placement="right"
      onClose={onClose}
      open={visible}
      styles={{
        header: { background: "#0D0A37", borderBottom: "0" },
        footer: { borderTop: "1px solid #CBD5E1", paddingLeft: "40px" },
        body: { padding: "40px" },
      }}
      width={533}
      footer={
        <div className="flex justify-start gap-[8px]">
          <Button
            className="rounded-full"
            onClick={submitForm()}
            disabled={isFetching}
          >
            {initialFormValues ? "Save" : "Create"}
          </Button>
          <Button
            className="border border-gray-600 text-gray-600 w-[94px]"
            variant="primaryOutline"
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      }
    >
      <Spin spinning={isFetching}>
        <UserForm form={form} />
      </Spin>
    </Drawer>
  );
};

export default UserDrawer;
