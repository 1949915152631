import { EditOutlined, SettingTwoTone } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import {
  TEnvVarData,
  TEnvVariable,
} from "../../../store/slices/envVariablesSlice";
import React from "react";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";
import { useParams } from "react-router-dom";

type PropsType = {
  variable: TEnvVarData;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setEditModeProps: React.Dispatch<React.SetStateAction<TEnvVariable | null>>;
};

const VariableItemSettings = (props: PropsType) => {
  const { companyId } = useParams();

  const { variable, setIsOpened, setEditModeProps } = props;
  const { hasCampaignEnvItemUpdateRole } = useUserHasPermission({ companyId });

  const onEditEnvVar = () => {
    setEditModeProps(variable.variable);
    setIsOpened(true);
  };

  const items = [
    hasCampaignEnvItemUpdateRole && {
      key: "1",
      label: "Edit",
      style: { width: "100px" },
      icon: <EditOutlined />,
      onClick: onEditEnvVar,
    },
  ].filter(Boolean) as MenuProps["items"];

  return (
    <>
      {!!items?.length && (
        <Dropdown menu={{ items }} placement="bottom">
          <SettingTwoTone style={{ cursor: "pointer" }} />
        </Dropdown>
      )}
    </>
  );
};

export default VariableItemSettings;
