import clsx from "clsx";
import { UIEventHandler, useEffect, useRef, useState } from "react";
import { Button } from "./ui/button";

const ArrowRightIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.22007 5.22001C8.3607 5.07956 8.55132 5.00067 8.75007 5.00067C8.94882 5.00067 9.13945 5.07956 9.28007 5.22001L13.5301 9.47001C13.6705 9.61064 13.7494 9.80126 13.7494 10C13.7494 10.1988 13.6705 10.3894 13.5301 10.53L9.28007 14.78C9.1379 14.9125 8.94985 14.9846 8.75555 14.9812C8.56125 14.9778 8.37586 14.899 8.23845 14.7616C8.10104 14.6242 8.02233 14.4388 8.0189 14.2445C8.01547 14.0502 8.08759 13.8622 8.22007 13.72L11.9401 10L8.22007 6.28001C8.07962 6.13939 8.00073 5.94876 8.00073 5.75001C8.00073 5.55126 8.07962 5.36064 8.22007 5.22001Z"
      fill="#475569"
    />
  </svg>
);

const TabsCarousel = (props: any) => {
  const { children, rightButtonClassName, leftButtonClassName, ...rest } =
    props;
  const [isShowRightButton, setIsShowRightButton] = useState<boolean>(false);
  const [isShowLeftButton, setIsShowLeftButton] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleScroll: UIEventHandler = (e) => {
    if (
      e.currentTarget?.scrollLeft + e.currentTarget?.clientWidth + 100.5 >=
      e.currentTarget?.scrollWidth
    ) {
      setIsShowRightButton(false);
    } else {
      setIsShowRightButton(true);
    }
    // display left button
    if (e.currentTarget?.scrollLeft > 100) {
      setIsShowLeftButton(true);
    } else {
      setIsShowLeftButton(false);
    }
  };

  const handleRightButtonClick: UIEventHandler = () => {
    wrapperRef.current!.scrollTo({
      left: wrapperRef.current!.scrollLeft + wrapperRef.current!.offsetWidth,
      behavior: "smooth",
    });
  };

  const handleLeftButtonClick: UIEventHandler = () => {
    wrapperRef.current!.scrollTo({
      left: wrapperRef.current!.scrollLeft - wrapperRef.current!.offsetWidth,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (
      !wrapperRef?.current?.scrollWidth &&
      !wrapperRef?.current?.offsetWidth
    ) {
      return;
    }

    if (wrapperRef?.current?.scrollWidth > wrapperRef?.current?.offsetWidth) {
      setIsShowRightButton(true);
    }

    if (wrapperRef?.current?.scrollLeft > 100) {
      setIsShowLeftButton(true);
    }

    if (wrapperRef?.current?.scrollLeft < 100) {
      setIsShowLeftButton(false);
    }

    if (wrapperRef?.current?.scrollWidth < wrapperRef?.current?.offsetWidth) {
      setIsShowRightButton(false);
    }

    return () => {
      setIsShowRightButton(false);
    };
  }, [wrapperRef?.current?.scrollWidth, wrapperRef?.current?.offsetWidth]);

  return (
    <>
      <div className="w-full relative overflow-hidden">
        <div
          {...rest}
          className={props.className}
          onScroll={handleScroll}
          ref={wrapperRef}
        >
          {children}
        </div>
        {isShowLeftButton && (
          <div
            className={clsx(
              "bg-slate-50 absolute left-0 top-0 h-[48px] flex items-center shadow",
              leftButtonClassName
            )}
          >
            <Button
              variant={"ghost"}
              className={clsx(
                "p-[14px] size-[48px] rounded-none",
                leftButtonClassName
              )}
              onClick={handleLeftButtonClick}
            >
              <ArrowRightIcon className="rotate-180" />
            </Button>
          </div>
        )}
        {isShowRightButton && (
          <div
            className={clsx(
              "bg-slate-50 absolute right-0 top-0 h-[48px] flex items-center shadow",
              rightButtonClassName
            )}
          >
            <Button
              variant={"ghost"}
              className={clsx(
                "p-[14px] size-[48px] rounded-none",
                rightButtonClassName
              )}
              onClick={handleRightButtonClick}
            >
              <ArrowRightIcon />
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default TabsCarousel;