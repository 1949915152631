import { FC } from "react";
import { TableHeader } from "src/components/table/Header";
import { CampaignsListRequestParams } from "src/pages/campaigns/hooks/useCampaignsList";
import { TCampaignListElem } from "../../globalTypes";
import { useCampaigns } from "../../store/slices/campaignsSlice";
import { Table, TableBody } from "../common/ui/table";
import CampaignListItem from "./CampaignListItem";
import { EmptyResult } from "../common/EmptyResult";

type PropsType = {
  requestParams: CampaignsListRequestParams;
  requestCampaignsList: (params: CampaignsListRequestParams) => void;
};

export const tableHeaderItems = [
  { title: "Campaign", className: "font-sans w-1/4" },
  { title: "Company", className: "font-sans w-1/4" },
  { title: "Campaign Type", className: "font-sans w-1/4" },
  { title: "Last Modified", className: "font-sans w-1/4" },
  null,
];

const CampaignsList: FC<PropsType> = ({
  requestParams,
  requestCampaignsList,
}) => {
  const campaigns = useCampaigns();
  const handleReloadCurrentList = () => {
    requestCampaignsList(requestParams);
  };
  if (!campaigns.length) {
    const campaignTypeMessage =
      requestParams?.campaignType === undefined ||
      requestParams.campaignType === "ALL"
        ? ""
        : ` with type ${requestParams.campaignType}`;
    return (
      <EmptyResult
        message={`There are no campaigns ${campaignTypeMessage} in this company.`}
      />
    );
  }
  const campaignsData: TCampaignListElem[] = campaigns.map((campaign) => {
    return {
      id: campaign.id,
      title: campaign.name,
      companyId: campaign.companyId,
      companyName: campaign.companyName,
      campaignType: campaign.type,
      companyLogo: campaign.companyLogo,
      modifiedTime: campaign.modifiedTime,
    };
  });

  return (
    <div className="rounded-md border w-full mx-auto">
      <Table>
        <TableHeader items={tableHeaderItems} />
        <TableBody className="font-sans">
          {campaignsData.map((item) => (
            <CampaignListItem
              key={item.id}
              item={item}
              onReloadCurrentList={handleReloadCurrentList}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
export default CampaignsList;
