import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  FormInstance,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import React, { FC, useState } from "react";
import { fetchCompaniesOptions } from "src/utils/apiUtils";
import { TImportCampaignFormValues } from "../../../globalTypes";
import SelectWithHighlightSearch from "../../common/SelectWithHighlightSearch";

type PropsType = {
  form: FormInstance<TImportCampaignFormValues>;
};
const ImportCampaignForm: FC<PropsType> = (props) => {
  const { form } = props;
  const [fileList, setFileList] = useState<Array<UploadFile<any>>>([]);

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: "application/zip",
    fileList,
    onChange(info) {
      setFileList(info.fileList);
    },
    onRemove(_) {
      //need timeout to reset form, research how to improve
      setTimeout(() => {
        form.resetFields(["file"]);
        setFileList([]);
      }, 0);
    },
    beforeUpload(file) {
      form.setFieldValue("file", file);
      return false;
    },
  };

  //TODO move to util and reuse
  const customizeRequiredMark = (
    label: React.ReactNode,
    { required }: { required: boolean }
  ) => (
    <span className="font-sans font-bold text-[#475569] text-[14px] flex">
      {label}
      {required && <span className="text-red-500 ml-1">*</span>}
    </span>
  );

  return (
    <Form
      form={form}
      layout="vertical"
      name="campaign_import_form"
      requiredMark={customizeRequiredMark}
    >
      <Form.Item
        name="file"
        tooltip={
          <span>
            Supported formats: <strong>.zip</strong>.<br />
            To export a campaign in .zip format, go to the campaign and select{" "}
            <strong>Actions &gt; Export Campaign</strong>
          </span>
        }
        label="File"
        rules={[{ required: true, message: "Required field!" }]}
      >
        <Upload {...uploadProps}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        name="companyId"
        label="Choose Company"
        rules={[{ required: true, message: "Required field!" }]}
      >
        <SelectWithHighlightSearch
          onSelect={(companyId: number | undefined, _) =>
            form.setFieldValue("companyId", companyId)
          }
          fetchOptions={fetchCompaniesOptions}
          allowClear={true}
        />
      </Form.Item>
    </Form>
  );
};

export default ImportCampaignForm;
