import { Checkbox, Form, Input, InputRef, Modal } from "antd";
import React, { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createCampaignFromTemplateApi } from "../../../api/campaigns.api";
import useSetFocus from "../../../hooks/useSetFocus";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import { getMessageApi } from "../../../store/slices/appSlice";
import {
  fetchCompaniesOptions,
  fetchPublishedCampaignTemplatesOptions,
} from "../../../utils/apiUtils";
import handleRequestError from "../../../utils/handleRequestError";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import SelectWithHighlightSearch from "../../common/SelectWithHighlightSearch";
import { Button } from "../../common/ui/button";
import { campaignNameValidationRule } from "src/utils/validators";
import { customizeRequiredMark } from "src/components/companies/CompanySite/CustomerSite";
import { required } from "src/utils/validations";

type TStartCampaignFormValues = {
  campaignName: string;
  companyId: number;
  resetOvr: boolean;
  resetResult: boolean;
  campaignId?: number;
};

type Props = {
  isModalOpen: boolean;
  defaultCampaignId?: number;
  campaignTemplateName?: string;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const StartCampaignModal: FC<Props> = ({
  isModalOpen,
  defaultCampaignId,
  campaignTemplateName,
  setIsModalOpen,
}) => {
  const navigate = useNavigate();
  const messageApi = getMessageApi();
  const [form] = Form.useForm<TStartCampaignFormValues>();
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef<InputRef>(null);

  useSubmitFormOnEnter(() => submitForm(), { condition: !isLoading });
  useSetFocus(inputRef);

  const onCreateCampaign = async (values: TStartCampaignFormValues) => {
    try {
      setIsLoading(true);

      const { companyId, campaignName, resetOvr, resetResult } = values;

      const campaignId = values?.campaignId || defaultCampaignId;

      if (!campaignId) {
        messageApi.error("Campaign ID is required");
        return;
      }

      const { data: newCampaign } = await createCampaignFromTemplateApi({
        campaignId,
        campaignName,
        companyIdToCreate: companyId,
        resetOvr,
        resetResult,
      });

      setIsModalOpen(false);
      navigate(
        `/campaigns/company/${newCampaign.companyId}/campaign/${newCampaign.id}`,
      );
      messageApi.success("The campaign has been successfully created");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TStartCampaignFormValues) => {
      await onCreateCampaign(values);
    },
  });

  return (
    <Modal
      title="Create a campaign based on a template"
      open={isModalOpen}
      destroyOnClose
      width={500}
      footer={() => {
        return (
          <div className="flex gap-3 justify-end">
            <Button variant="outline" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button
              loading={isLoading}
              disabled={isLoading}
              onClick={submitForm}
            >
              Create Campaign
            </Button>
          </div>
        );
      }}
      onCancel={() => setIsModalOpen(false)}
    >
      <Form
        form={form}
        layout="vertical"
        name="campaign_import_form"
        requiredMark={customizeRequiredMark}
        initialValues={{
          campaignName: campaignTemplateName
            ? `Campaign from ${campaignTemplateName}`
            : "",
          resetOvr: true,
          resetResult: false,
        }}
      >
        <Form.Item
          name="campaignName"
          label="Campaign name"
          tooltip="Please enter a string between 4 and 255 characters."
          rules={[campaignNameValidationRule]}
        >
          <Input placeholder="Enter Campaign name" ref={inputRef} />
        </Form.Item>
        <Form.Item name="companyId" label="Company" rules={[required()]}>
          <SelectWithHighlightSearch
            onSelect={(companyId: number | undefined, _) =>
              form.setFieldValue("companyId", companyId)
            }
            fetchOptions={fetchCompaniesOptions}
            allowClear={true}
          />
        </Form.Item>

        {!defaultCampaignId && (
          <Form.Item
            name="campaignId"
            label="Published Campaign Template"
            rules={[required()]}
          >
            <SelectWithHighlightSearch
              onSelect={(campaignId: number | undefined, _) =>
                form.setFieldValue("campaignId", campaignId)
              }
              fetchOptions={fetchPublishedCampaignTemplatesOptions}
              allowClear={true}
            />
          </Form.Item>
        )}
        <div className="flex flex-col gap-[6px]">
          <Form.Item name="resetOvr" valuePropName="checked" noStyle>
            <Checkbox>Reset override</Checkbox>
          </Form.Item>
          <Form.Item name="resetResult" valuePropName="checked" noStyle>
            <Checkbox>Reset result</Checkbox>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default StartCampaignModal;
