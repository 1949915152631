import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  DocumentTemplate,
  DocumentTemplateField, DocumentTemplateListItem,
  DocumentTemplateState,
  SortingDirection,
} from 'src/types/docTemplates';
import { RootState } from "../store";

type DocumentTemplateSliceState = {
  list: DocumentTemplateListItem[];
  pageNumber: number;
  pageSize: number;
  total: number;
  sortingField?: DocumentTemplateField;
  sortingDirection?: SortingDirection;
  states?: DocumentTemplateState[];
};

const initialState: DocumentTemplateSliceState = {
  list: [],
  pageNumber: 1,
  pageSize: 50,
  total: 0,
  sortingField: "name",
  sortingDirection: SortingDirection.ASC,
};

const documentTemplatesSlice = createSlice({
  name: "docTemplates",
  initialState,
  reducers: {
    setList: (
      state: DocumentTemplateSliceState,
      action: PayloadAction<{ list: DocumentTemplateListItem[]; total: number }>
    ) => {
      state.list = action.payload.list;
      state.total = action.payload.total;
    },
    setPageSize: (
      state: DocumentTemplateSliceState,
      action: PayloadAction<number>
    ) => {
      state.pageSize = action.payload;
    },
    setPageNumber: (
      state: DocumentTemplateSliceState,
      action: PayloadAction<number>
    ) => {
      state.pageNumber = action.payload;
    },
    updateClasses: (
      state: DocumentTemplateSliceState,
      action: PayloadAction<{ templateId: number; classes: string[] }>
    ) => {
      const template = state.list.find(
        (item) => item.id === action.payload.templateId
      );

      if (template) {
        template.classes = action.payload.classes;
      }
    },
    update: (
      state: DocumentTemplateSliceState,
      action: PayloadAction<Partial<DocumentTemplate>>
    ) => {
      const template = state.list.find((item) => item.id === action.payload.id);

      if (template) {
        Object.assign(template, action.payload);
      }
    },
    deleteTemplate: (
      state: DocumentTemplateSliceState,
      action: PayloadAction<number>
    ) => {
      state.list = state.list.filter((item) => item.id !== action.payload);
    },
    updateSorting: (
      state: DocumentTemplateSliceState,
      action: PayloadAction<{
        field: DocumentTemplateField;
        direction: SortingDirection;
      }>
    ) => {
      state.sortingField = action.payload.field;
      state.sortingDirection = action.payload.direction;
    },
    updateFilterByStates: (
      state: DocumentTemplateSliceState,
      action: PayloadAction<DocumentTemplateState[]>
    ) => {
      state.states = action.payload;
    },
  },
});

export const docTemplatesActions = documentTemplatesSlice.actions;

export default documentTemplatesSlice.reducer;

export const selectDocTemplatesState = (state: RootState) => state.docTemplates;
