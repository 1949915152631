import clsx from "clsx";
import React, { FC } from "react";
import { TableCell, TableRow } from "../../common/ui/table";
import { Badge } from "../../common/ui/badge";
import { TUsersListItem } from "../../../globalTypes";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";
import { format } from "date-fns";
import useNavigateWithOutletRedirect from "../../../hooks/navigation/useNavigateWithOutletRedirect";
import UserMenuTableCell from "../UserMenuTableCell";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUsersListState,
  setUsers,
} from "../../../store/slices/usersListSlice";
import { AppDispatch } from "../../../store/store";
import { getUsersListApi } from "../../../api/user.api";

type Props = {
  item: TUsersListItem;
};

const UsersListItem: FC<Props> = ({ item }) => {
  const { user, companies, allCompaniesAccess } = item;
  const { id, email, firstName, lastName, position, lastLoginTime, disabled } =
    user;
  const { navigateWithOutletRedirect } = useNavigateWithOutletRedirect();
  const { hasUserReadRole } = useUserHasPermission({});
  const userName = `${firstName} ${lastName}`;
  const { pageNumber, pageSize } = useSelector(selectUsersListState);
  const dispatch = useDispatch<AppDispatch>();

  const onNavigateToUserDetails = () => {
    navigateWithOutletRedirect(`/users/${id}/details`);
  };
//TODO pass from parent
  const updateUsers = async () => {
    const { data } = await getUsersListApi({
      pageSize,
      pageNumber,
    });
    const { items, totalItems } = data;
    dispatch(setUsers({ users: items, total: totalItems }));
  };

  return (
    <>
      <TableRow
        key={id}
        className={clsx(
          "relative cursor-pointer text-[#475569] font-sans h-[48px]",
          {
            "opacity-30": disabled,
          },
        )}
        onClick={hasUserReadRole ? onNavigateToUserDetails : undefined}
      >
        <TableCell className="font-medium text-[#0F172A]">{userName}</TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>
          {allCompaniesAccess ? (
            <Badge className={"!text-green-500 !bg-green-100"}>
              All Companies Access
            </Badge>
          ) : (
            companies.map(({ name }) => {
              return <div key={name}>{name}</div>;
            })
          )}
        </TableCell>
        <TableCell>{position}</TableCell>
        <TableCell>
          {lastLoginTime
            ? format(lastLoginTime, "MMMM d, yyyy h:mm a zzz")
            : "-"}
        </TableCell>
        <UserMenuTableCell
          userId={id}
          updateUsers={updateUsers}
          userName={userName}
          disabled={disabled}
          onNavigateToUserDetails={onNavigateToUserDetails}
        />
      </TableRow>
    </>
  );
};

export default UsersListItem;
