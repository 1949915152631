import { FormInstance } from "antd";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import {
  createTemplateApi,
  updateTemplateApi,
} from "../../api/document-templates.api";
import { selectMessageApi } from "../../store/slices/appSlice";
import { XSLTEditorEnum } from "../../types/docTemplates";
import handleRequestError from "../../utils/handleRequestError";
import submitFormWithTrim from "../../utils/submitFormWithTrim";
import { Icons } from "../common/Icons";
import { Button } from "../common/ui/button";
import { TemplateSettingForm } from "./TemplateSettingsForm";
import { useNavigate } from "react-router-dom";

type Props = {
  form: FormInstance<TemplateSettingForm>;
  content: string;
  setTab?: React.Dispatch<React.SetStateAction<XSLTEditorEnum>>;
  templateId?: string;
};
const SaveTemplateButton: FC<Props> = ({
  templateId,
  form,
  content,
  setTab,
}) => {
  const isCreateMode = templateId === undefined;
  const [isFetching, setIsFetching] = useState(false);
  const messageApi = useSelector(selectMessageApi);
  const navigate = useNavigate();

  const onSaveTemplate = async (values: TemplateSettingForm) => {
    try {
      setIsFetching(true);

      if (isCreateMode) {
        const { data } = await createTemplateApi({
          ...values,
          content,
        });
        navigate(`/templates/documents/edit/${data.id}`);
      } else {
        await updateTemplateApi({
          ...values,
          content,
          id: +templateId,
        });
      }

      messageApi.success(
        `The template was successfully ${isCreateMode ? "created" : "updated"}`,
      );
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TemplateSettingForm) =>
      await onSaveTemplate(values),
    onErrorValidationCb: () => {
      messageApi.error("Please fill in all required fields!");
      setTab && setTab(XSLTEditorEnum.SETTINGS);
    },
  });

  return (
    <Button
      icon={isCreateMode && Icons.Plus}
      className="rounded-full"
      onClick={submitForm}
      loading={isFetching}
      disabled={!content || isFetching}
    >
      {isCreateMode ? "Create Template" : "Save Template"}
    </Button>
  );
};

export default SaveTemplateButton;
