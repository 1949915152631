import { Spin, Tooltip } from "antd";
import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { getMessageApi } from "../../../../store/slices/appSlice";
import {
  deleteCampaignLabelsThunk,
  saveCampaignLabelThunk,
} from "../../../../store/slices/campaignLabelsSlice";
import { AppDispatch } from "../../../../store/store";
import { TGridItemTitle } from "../../Grid/CampaignGrid";
import TextArea from "antd/es/input/TextArea";
import handleRequestError from "../../../../utils/handleRequestError";
import { GRID_TITLE_MAX_LENGTH } from "../../../../constants";
import { useParams } from "react-router-dom";

type Props = {
  itemKey: string;
  title: TGridItemTitle;
};

const GridItemTitle: FC<Props> = ({ itemKey, title }) => {
  const { campaignId } = useParams();
  const { value: titleValue, scope } = title;
  const dispatch: AppDispatch = useDispatch();
  const messageApi = getMessageApi();
  const [value, setValue] = useState(titleValue);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  const onBlur = async () => {
    setIsOpened(false);

    if (!campaignId) {
      console.error("campaignId is undefined!");
      messageApi.error("Could not save the value");
      setValue(titleValue);

      return;
    }

    if (value === titleValue) return;

    try {
      setIsLoading(true);

      const lang = "en";

      if (!value) {
        await dispatch(
          deleteCampaignLabelsThunk({ keys: [itemKey], lang, campaignId }),
        ).unwrap();
      } else {
        //if global label and !titleValue.length - need POST request, otherwise - PUT
        const action =
          scope === "global"
            ? "create"
            : titleValue.length
              ? "update"
              : "create";

        await dispatch(
          saveCampaignLabelThunk({
            label: value,
            key: itemKey,
            lang,
            action,
            campaignId,
          }),
        ).unwrap();
      }

      messageApi.success("Successfully saved");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);

      setValue(titleValue);
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > GRID_TITLE_MAX_LENGTH) {
      setValue(e.target.value.substring(0, GRID_TITLE_MAX_LENGTH));
      setIsOpened(true);
    } else {
      setValue(e.target.value);
      setIsOpened(false);
    }
  };

  return (
    <div className="p-[3px]">
      <Tooltip
        title={`The maximum allowed number of characters is ${GRID_TITLE_MAX_LENGTH}.`}
        color="red"
        open={isOpened}
      >
        <TextArea
          autoSize
          value={value.length ? value : undefined}
          placeholder="not defined"
          onBlur={onBlur}
          onChange={onChange}
          disabled={isLoading}
          className="hover:border-indigo-600 border-transparent"
        />
      </Tooltip>
      <Spin
        spinning={isLoading}
        className={
          "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        }
      />
    </div>
  );
};

export default GridItemTitle;
