import { Button, Checkbox, Modal } from "antd";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { executeCcVarApi, validateCcVarApi } from "../../api/cc-variables.api";
import { selectMessageApi } from "../../store/slices/appSlice";
import handleRequestError from "../../utils/handleRequestError";
import localStorageProvider from "../../utils/localStorageProvider";
import { resetComputationMessages } from "../../store/slices/computationMessagesSlice";
import { AppDispatch } from "../../store/store";

type Props = {
  campaignId: number;
  stepId: number;
  ccItemKey: string;
};

const PaneExecuteButton: FC<Props> = ({ campaignId, ccItemKey, stepId }) => {
  const [isFetching, setIsFetching] = useState(false);
  const messageApi = useSelector(selectMessageApi);
  const dispatch = useDispatch<AppDispatch>();
  const [updateDownstreamDependencies, setUpdateDownstreamDependencies] =
    useState(localStorageProvider.getUpdateDownstreamDependencies());
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleModalOk = () => {
    setIsModalVisible(false);
    executeItem();
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const executeItem = async () => {
    try {
      setIsFetching(true);

      try {
        const { data: isExecuting } = await validateCcVarApi({ campaignId });

        if (!isExecuting) {
          await executeCcVarApi({
            campaignId,
            key: ccItemKey,
            stepId,
            updateDownstreamDependencies,
          });

          dispatch(resetComputationMessages());

          messageApi.success("Execution started successfully");
        } else {
          messageApi.error(
            "There are some active computation elements in progress, please wait before execute",
          );
        }
      } catch (e: any) {
        const customError = handleRequestError(e);

        messageApi.error(customError.message);
        console.error(customError);
      }
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const onExecute = () => {
    showModal();
  };

  return (
    <>
      <Button size="small" loading={isFetching} onClick={onExecute}>
        Execute
      </Button>
      <Modal
        title="Execute Item"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <p>Are you sure you want to continue?</p>
        <Checkbox
          checked={updateDownstreamDependencies}
          onChange={({ target: { checked } }) => {
            setUpdateDownstreamDependencies(checked);
            localStorageProvider.setUpdateDownstreamDependencies(checked);
          }}
          className="mt-2"
        >
          Update downstream dependencies
        </Checkbox>
      </Modal>
    </>
  );
};

export default PaneExecuteButton;
