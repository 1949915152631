import { getCompaniesApi } from "src/api/companies.api";
import { getDocumentTemplatesApi } from "src/api/document-templates.api";
import {
  DocumentTemplateListItem,
  DocumentTemplateState,
} from "src/types/docTemplates";
import { getCampaignsApi } from "../api/campaigns.api";
import { dictionaryApi } from "../api/dictionary.api";
import { TSelectOptionWithData } from "../components/common/SelectWithHighlightSearch";
import { SelectOption } from "../globalTypes";
import { TCampaignResponseData } from "../store/slices/campaignsSlice";
import handleRequestError from "./handleRequestError";

export const fetchCompaniesOptions = async () => {
  const { data } = await getCompaniesApi({
    name: "",
    pageNumber: 1,
    pageSize: 10000,
  });

  return data.items.map((company) => ({
    label: company.name,
    value: company.id,
    data: company,
  }));
};

export const fetchTemplateClassesOptions = async (
  wordMatch: string,
): Promise<SelectOption[]> => {
  try {
    const { data } = await dictionaryApi({ dictionaryType: "CLASS" }).find({
      wordMatch: `*${wordMatch}*`,
    });

    return data.map((item) => ({ label: item, value: item }));
  } catch (e: any) {
    const customError = handleRequestError(e);
    console.error(customError);
    return [];
  }
};

export const fetchSystemTemplatesOptions = async (): Promise<
  TSelectOptionWithData<DocumentTemplateListItem>[]
> => {
  const { data } = await getDocumentTemplatesApi({
    wordMatch: `**`,
    pageNumber: 0,
    pageSize: 1000,
    states: DocumentTemplateState.SYSTEM,
    classes: "",
  });

  return data.items.map((template) => ({
    label: template.name,
    value: template.id,
    data: template,
  }));
};

export const fetchCampaignsOptions = async (): Promise<
  TSelectOptionWithData<TCampaignResponseData>[]
> => {
  const { data } = await getCampaignsApi({
    name: "",
    currentPage: 1,
    pageSize: 10000,
    campaignType: "ALL",
  });

  const sortedData = data.items.sort((a, b) => {
    const nameA = a.companyName || "";
    const nameB = b.companyName || "";

    return nameA.localeCompare(nameB);
  });

  return sortedData.map((campaign) => {
    const { id, companyName, name } = campaign;

    return {
      label: name,
      value: id,
      data: {
        ...campaign,
        companyName: companyName || "Global",
      },
    };
  });
};

export const fetchPublishedCampaignTemplatesOptions = async (): Promise<
  TSelectOptionWithData<TCampaignResponseData>[]
> => {
  const { data } = await getCampaignsApi({
    name: "",
    currentPage: 1,
    pageSize: 10000,
    campaignType: "PUBLISHED_TEMPLATE",
  });

  return data.items.map((campaign) => ({
    label: campaign.name,
    value: campaign.id,
    data: campaign,
  }));
};
