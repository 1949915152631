import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import {
  TComputationMessage,
  TComputationStatus,
  TExecutionType,
  TSocketMsg,
  TSocketMsgType,
} from "../../globalTypes";
import {
  handleRequestError,
  TCustomError,
} from "../../utils/handleRequestError";
import { RootState } from "../store";
import { bulkUpdateCcVariables } from "./ccVariablesSlice";
import Pako from "pako";
import processEventMessages from "../../utils/processEventMessages";
import { ComputationType } from "../../constants";

export type TEventMessagesMap = { [key: string]: TComputationMessage };

const initialState = {
  socket: null as WebSocket | null,
  socketShouldReconnect: true,
  reconnectDelay: 1000,
  messagesStack: {} as TEventMessagesMap,
  progress: 0,
  isConnected: false,
  computationStatus: null as TComputationStatus | null,
  computationType: null as ComputationType | null,
  executionType: null as TExecutionType | null,
};

type InitialStateType = typeof initialState;

const slice = createSlice({
  name: "computationMessages",
  initialState,
  reducers: {
    setSocket: (
      state: InitialStateType,
      action: PayloadAction<WebSocket | null>,
    ) => {
      state.socket = action.payload;
    },
    setSocketShouldReconnect: (
      state: InitialStateType,
      action: PayloadAction<boolean>,
    ) => {
      state.socketShouldReconnect = action.payload;
    },
    setReconnectDelay: (
      state: InitialStateType,
      action: PayloadAction<number>,
    ) => {
      state.reconnectDelay = action.payload;
    },
    setProgress: (state: InitialStateType, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
    setComputationStatus: (
      state: InitialStateType,
      action: PayloadAction<TComputationStatus | null>,
    ) => {
      state.computationStatus = action.payload;
    },
    resetComputationMessages: (state: InitialStateType) => {
      state.messagesStack = {};
    },
    addBulkComputationMessages: (
      state: InitialStateType,
      action: PayloadAction<TEventMessagesMap>,
    ) => {
      state.messagesStack = { ...state.messagesStack, ...action.payload };
    },
    setIsConnected: (
      state: InitialStateType,
      action: PayloadAction<boolean>,
    ) => {
      state.isConnected = action.payload;
    },
    setComputationType: (
      state: InitialStateType,
      action: PayloadAction<ComputationType | null>,
    ) => {
      state.computationType = action.payload;
    },
    setExecutionType: (
      state: InitialStateType,
      action: PayloadAction<TExecutionType | null>,
    ) => {
      state.executionType = action.payload;
    },
  },
});

export const {
  setSocket,
  resetComputationMessages,
  setProgress,
  setSocketShouldReconnect,
  setReconnectDelay,
  setComputationStatus,
  addBulkComputationMessages,
  setIsConnected,
  setComputationType,
  setExecutionType,
} = slice.actions;

export default slice.reducer;

/* eslint-disable*/
export const getUpdateMessages = (): TEventMessagesMap =>
  useSelector((state: RootState) => state.computationMessages.messagesStack);
export const getMessagesCount = (): number =>
  useSelector(
    (state: RootState) =>
      Object.keys(state.computationMessages.messagesStack).length,
  );
export const getProgress = (): number =>
  useSelector((state: RootState) => state.computationMessages.progress);
export const getComputationStatus = (): TComputationStatus | null =>
  useSelector(
    (state: RootState) => state.computationMessages.computationStatus,
  );
export const getIsSocketConnected = (): boolean =>
  useSelector((state: RootState) => state.computationMessages.isConnected);
export const useComputationType = (): ComputationType | null =>
  useSelector((state: RootState) => state.computationMessages.computationType);
export const useExecutionType = (): TExecutionType | null =>
  useSelector((state: RootState) => state.computationMessages.executionType);

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const connectSocketThunk = createAsyncThunk<
  undefined,
  //onlyCurrentStep - need when inside of campaign
  { campaignId: number; onlyCurrentStep?: boolean },
  { state: RootState; rejectValue: TCustomError }
>(
  "computationMessages/connectSocket",
  async (
    { campaignId, onlyCurrentStep = true },
    { getState, rejectWithValue, dispatch },
  ) => {
    const protocol = window.location.protocol === "https:" ? "wss" : "ws";
    const host =
      window.location.hostname === "localhost" &&
      window.location.port === "3000"
        ? "localhost:8080"
        : window.location.host;

    try {
      const ws = new WebSocket(
        `${protocol}://${host}/secured/ws-integration?campaignId=${campaignId}`,
      );
      let isProcessing = false;
      const messageQueue: TSocketMsg<TSocketMsgType>[][] = [];

      ws.onopen = function () {
        console.log("Socket connected");
        dispatch(setSocket(ws));
        dispatch(setReconnectDelay(1000));
      };

      ws.onmessage = async function (e) {
        const data = e.data;
        let messages: TSocketMsg<TSocketMsgType>[] = [];

        if (data instanceof Blob) {
          try {
            const arrayBuffer = await data.arrayBuffer();
            const compressedData = new Uint8Array(arrayBuffer);
            const decompressedData = Pako.inflate(compressedData, {
              to: "string",
            });

            messages = JSON.parse(decompressedData);
          } catch (e) {
            console.error("Error parsing decompressed JSON", e);
          }
        } else if (typeof data === "string") {
          if (data === "Connected") {
            dispatch(setIsConnected(true));
          } else {
            try {
              messages = JSON.parse(data);
            } catch (e) {
              console.error("Error parsing JSON", e);
            }
          }
        } else {
          console.warn("Unexpected data type received:", typeof e.data);
        }

        const { steps } = getState();

        const processData = async (
          newMessages: TSocketMsg<TSocketMsgType>[],
        ) => {
          isProcessing = true;

          const {
            eventMessagesMap,
            computationStatus,
            computationProgress,
            itemsToUpdate,
            computationType,
            executionType,
          } = processEventMessages({
            messages: newMessages,
            currentStepId: steps.current?.id,
            shouldUpdateCurrentStepCCItemsInGrid: onlyCurrentStep,
          });

          if (computationType) {
            dispatch(setComputationType(computationType));
          }

          if (executionType) {
            dispatch(setExecutionType(executionType));
          }

          if (computationStatus) {
            dispatch(setComputationStatus(computationStatus));
          }

          if (computationProgress !== null) {
            dispatch(setProgress(computationProgress));
          }

          if (itemsToUpdate.length) {
            dispatch(bulkUpdateCcVariables(itemsToUpdate));
          }

          dispatch(addBulkComputationMessages(eventMessagesMap));

          isProcessing = false;

          // If there is data in the queue - process the following element
          if (messageQueue.length > 0) {
            const nextMessages = messageQueue.shift(); // Extracting some of the data from the beginning of the queue
            if (nextMessages) {
              processData(nextMessages);
            }
          }
        };

        if (isProcessing) {
          messageQueue.push(messages);
        } else {
          processData(messages);
        }
      };

      ws.onclose = async function (e) {
        dispatch(setIsConnected(false));

        if (!e.wasClean) {
          const { computationMessages } = getState();

          if (computationMessages.socketShouldReconnect) {
            const delay = computationMessages.reconnectDelay * 2;

            dispatch(setReconnectDelay(delay));

            console.log("socket reconnecting", delay);
            await wait(delay);

            dispatch(connectSocketThunk({ campaignId }));
          }
        }
      };

      ws.onerror = function (err: any) {
        dispatch(setIsConnected(false));
        console.error("Computation messages socket error:", err);
      };
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to connect to WS:`, e);

      return rejectWithValue(customError);
    }
  },
);

export const disconnectSocketThunk = createAsyncThunk<
  undefined,
  undefined,
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "computationMessages/disconnectSocket",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const { computationMessages } = getState();
      const ws = computationMessages.socket;

      if (ws) {
        ws.close();
        dispatch(setSocket(null));
        console.log("socket disconnected");
      }

      dispatch(setSocketShouldReconnect(false));
      dispatch(setReconnectDelay(1000));
      dispatch(setProgress(0));
      dispatch(setComputationStatus(null));
      dispatch(setComputationType(null));
      dispatch(resetComputationMessages());
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to disconnect from WS:`, e);

      return rejectWithValue(customError);
    }
  },
);
