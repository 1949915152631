import { Modal } from "antd";
import {
  TCcVariable,
  TCcVarsFormValues,
} from "../store/slices/ccVariablesSlice";
import { getConfirmationMessage } from "./getConfirmationMessage";

type Props = {
  values: TCcVarsFormValues;
  ccItem: TCcVariable | undefined;
  saveCCItem: (props: {
    rebuild?: boolean;
    values: TCcVarsFormValues;
  }) => Promise<void>;
};
const checkIsNeedRepublish = ({
  currentMicroSiteTargetFolder,
  newMicroSiteTargetFolder,
}: {
  currentMicroSiteTargetFolder: string | undefined;
  newMicroSiteTargetFolder: string | undefined;
}) => {
  return currentMicroSiteTargetFolder !== newMicroSiteTargetFolder;
};

const saveCCItemWithRepublish = async ({
  values,
  ccItem,
  saveCCItem,
}: Props) => {
  const isNeedRepublish = ccItem
    ? checkIsNeedRepublish({
        currentMicroSiteTargetFolder: values.microSiteTargetFolder,
        newMicroSiteTargetFolder: ccItem.options?.microSiteTargetFolder,
      })
    : false;

  const checkIsNameChanged = ({
    currentName,
    newName,
  }: {
    currentName: string | undefined;
    newName: string | undefined;
  }) => {
    return currentName !== newName;
  };

  const isNameChanged = ccItem
    ? checkIsNameChanged({
        currentName: values.assetTitle,
        newName: ccItem.options?.assetTitle,
      })
    : false;

  const confirmationMessage = getConfirmationMessage(
    isNeedRepublish,
    isNameChanged,
    "Asset Title",
  );

  if (confirmationMessage) {
    Modal.confirm({
      title: "Confirm changes",
      content: confirmationMessage,
      okText: "Yes",
      cancelText: "No",
      onOk: () => saveCCItem({ values, rebuild: true }),
      onCancel: () => saveCCItem({ values }),
    });
  } else {
    await saveCCItem({ values });
  }
};

export default saveCCItemWithRepublish;
