import { Editor } from "@monaco-editor/react";
import { Checkbox, CheckboxProps, Tooltip } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icons } from "src/components/common/Icons";
import { Button } from "src/components/common/ui/button";
import { Textarea } from "src/components/common/ui/textarea";
import { getCurrentCampaign } from "src/store/slices/campaignsSlice";
import { getCurrentStep } from "src/store/slices/stepsSlice";
import { selectMessageApi } from "../../../store/slices/appSlice";
import {
  saveCcItemResultOvrThunk,
  TCcVariable,
} from "../../../store/slices/ccVariablesSlice";
import { getCurrentPhase } from "../../../store/slices/phasesSlice";
import { AppDispatch } from "../../../store/store";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import { editorLanguages } from "../../storage/dynamicStorage/constants";
import clsx from "clsx";

/*
  Override Dialog Behavior:

  1. No Override Present on Open:
     - Show the original text in "Override Version" text area as grayed-out placeholder.
     - Disable the "Save" button.
     - When user edits text, text turns dark and "Save" button becomes enabled.
     - On clicking "Save," the override is saved, and dialog closes.

  2. Override Already in Place on Open:
     - Show current override text as dark text in "Override Version" area.
     - Disable "Save" button initially.
     - When user edits text, enable the "Save" button.
     - On clicking "Save," update the override and close the dialog.

  3. Removing the Override:
     - Option 1: "Restore Original" button:
       - Replace override text with original text, set text to gray, and enable "Save" button to remove override.
     - Option 2: Clear all text in "Override Version" area and click "Save."
       - This action removes the override.
*/

type Props = {
  setOverrideProps: React.Dispatch<React.SetStateAction<TCcVariable | null>>;
  overrideProps: TCcVariable;
};

const GridItemOverrideModal: FC<Props> = ({
  setOverrideProps,
  overrideProps,
}) => {
  const { result, resultOvr, id, mimeType } = overrideProps;
  const dispatch: AppDispatch = useDispatch();
  const messageApi = useSelector(selectMessageApi);
  const currentCampaign = getCurrentCampaign();
  const currentPhase = getCurrentPhase();
  const currentStep = getCurrentStep();
  const overrideRef = React.useRef<HTMLTextAreaElement>(null);
  const [isOverrideFocused, setIsOverrideFocused] = useState(false);
  const [overrideValue, setOverrideValue] = useState<string>(
    resultOvr || result || "",
  );
  const [recomputeDownstream, setRecomputeDownstream] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOverrideTouched, setIsOverrideTouched] = useState(false);
  const [shouldResetOverride, setShouldResetOverride] = useState(false);

  const variablePath = `${currentCampaign?.name} / ${currentPhase?.name} / ${currentStep?.name} / ${id.key}`;
  const editorLanguage = editorLanguages[mimeType] || "text";
  const isSaveButtonDisabled = !isOverrideTouched && !shouldResetOverride;
  const isPlainText = mimeType === "text/plain";

  //  Disable the submit form on enter for the override input because Editor doesn't support focus and blur events
  useSubmitFormOnEnter(
    () => {
      handleSaveOverride();
    },
    { condition: !isOverrideFocused && isPlainText && !isSaveButtonDisabled },
  );

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleRecompute: CheckboxProps["onChange"] = (e) => {
    setRecomputeDownstream(e.target.checked);
  };

  const handleCancelOverride = () => {
    setOverrideProps(null);
  };

  const handleRestoreOriginal = () => {
    setOverrideValue(result || "");
    setShouldResetOverride(true);
  };

  const handleSetOverrideValue = (value: string | undefined) => {
    setOverrideValue(value || "");
    setShouldResetOverride(false);
    if (!isOverrideTouched) {
      setIsOverrideTouched(true);
    }
  };

  const handleSaveOverride = async () => {
    try {
      setIsLoading(true);
      const value = shouldResetOverride ? "" : overrideValue;

      await dispatch(
        saveCcItemResultOvrThunk({
          varId: id,
          value,
          recomputeDownstream,
        }),
      ).unwrap();

      handleCancelOverride();
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(
        `An error occurred while trying to save the result override:`,
        e,
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed top-0 left-0 z-50 w-screen h-screen bg-white">
      <div className="w-full h-[72px] flex items-center pl-[34px] bg-slate-900">
        <Button
          variant={"text"}
          icon={Icons.back}
          onClick={handleCancelOverride}
          className="p-[12px] h-auto text-white text-xl font-semibold font-sans leading-tight"
        >
          Edit Content
        </Button>
      </div>

      <div className="w-full justify-start items-start gap-2 inline-flex px-[48px] py-[16px] border-b border-b-slate-200">
        <div className="text-slate-600 text-xs font-medium font-sans leading-3">
          {variablePath}
        </div>
      </div>

      <div className="flex px-[48px] w-full justify-between gap-[24px] pt-[33px]">
        <div className="w-full h-full flex flex-col gap-[16px]">
          <div className="h-[40px] flex justify-between w-full">
            <div className="flex items-center">
              <h2 className="text-slate-900 text-lg font-semibold  leading-[18px] mr-[16px]">
                Override Version
              </h2>
              <div className="h-[23px] px-3 py-[7px] rounded-full border border-slate-100 justify-center items-center gap-2.5 inline-flex">
                <div className="text-slate-500 text-xs font-semibold font-sans leading-3 line-clamp-1">
                  Edit Version
                </div>
              </div>
            </div>

            <Button
              className="rounded-full"
              onClick={handleRestoreOriginal}
              icon={Icons.restore}
              size={"sm"}
              disabled={overrideValue === result}
            >
              Restore Original
            </Button>
          </div>

          {isPlainText ? (
            <div className="relative overflow-hidden rounded-md">
              {resultOvr && (
                <Tooltip title="Override">
                  <div className="absolute top-[-5px] right-[-10px] w-[30px] h-[20px] bg-gradient-to-t from-white to-yellow-500 rotate-45" />
                </Tooltip>
              )}

              <Textarea
                className={clsx("w-full p-[24px] h-[calc(100vh-330px)]", {
                  "text-black/40":
                    (!isOverrideTouched && !resultOvr) || shouldResetOverride,
                })}
                onChange={(e) => handleSetOverrideValue(e.target.value)}
                value={overrideValue}
                ref={overrideRef}
                onFocus={() => setIsOverrideFocused(true)}
                onBlur={() => setIsOverrideFocused(false)}
              />
            </div>
          ) : (
            <Editor
              className="w-full h-full border border-slate-200 rounded-md mt-[24px]"
              defaultLanguage={editorLanguage}
              value={overrideValue}
              onChange={(value) => handleSetOverrideValue(value)}
            />
          )}

          <div className="flex justify-between gap-3 mt-[8px]">
            <div className="flex items-center gap-2">
              <Checkbox onChange={handleRecompute}>
                <span className="font-sans font-bold text-[#475569] text-[14px]">
                  Reset downstream dependencies
                </span>
              </Checkbox>
            </div>
            <div className="flex gap-3">
              <Button
                variant={"outline"}
                className="rounded-full px-[32px]"
                onClick={handleCancelOverride}
              >
                Cancel
              </Button>
              <Button
                className="rounded-full px-[32px] transition-all"
                onClick={handleSaveOverride}
                loading={isLoading}
                disabled={isSaveButtonDisabled}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-[16px]">
          <div className="h-[40px] flex items-center">
            <h2 className="text-slate-900 text-lg font-semibold  leading-[18px]">
              Original Output
            </h2>
          </div>
          <Textarea
            className="w-full p-[24px] h-[calc(100vh-330px)]"
            defaultValue={result || ""}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

export default GridItemOverrideModal;
