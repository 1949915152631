import { FloatButton, Skeleton } from "antd";
import React from "react";
import { Outlet } from "react-router-dom";
import { ChevronLeftIcon, Icons } from "../../components/common/Icons";
import { Button } from "../../components/common/ui/button";
import useGetUserInfo from "./hooks/useGetUserInfo.hook";
import { useUserHasPermission } from "../../hooks/useUserHasPermission";
import UserDetailsTabs from "../../components/users/UserDetailsTabs";
import UserDrawer2 from "../../components/users/UserDrawer/UserDrawer";
import { useGoBack } from "../../hooks/useGoBack";

const UserDetailsPage = () => {
  const {
    userInfo,
    isInitialized,
    error,
    onUpdateUser,
    toggleOpenDrawer,
    drawerVisible,
  } = useGetUserInfo();
  const { hasUserUpdateRole } = useUserHasPermission({});
  const goBack = useGoBack("/users");

  return (
    <div>
      <div className="flex justify-between items-center mb-[20px]">
        <div
          onClick={goBack}
          className="flex gap-[4px] items-center font-medium text-[#475569] transition-colors text-base cursor-pointer hover:text-[#4F46E5]"
        >
          <ChevronLeftIcon className="w-[20px] h-[20px]" />
          <span>Back</span>
        </div>

        {hasUserUpdateRole && (
          <Button
            icon={Icons.edit}
            variant="primaryOutline"
            className="rounded-full"
            disabled={!isInitialized || !!error}
            onClick={() => toggleOpenDrawer(true)}
          >
            Edit User
          </Button>
        )}
      </div>

      {error && <div className="text-red-600">Error: {error}</div>}

      {isInitialized ? (
        <>
          {userInfo && (
            <div className="w-full flex flex-col">
              <h1 className="font-semibold text-[24px] text-[#4F46E5]">
                {userInfo.firstName} {userInfo.lastName}
              </h1>
              <div className="flex justify-between py-[12px]">
                <div className="flex flex-col w-[30%] gap-[4px]">
                  <span className="font-bold text-[14px] text-[#475569]">
                    Email
                  </span>
                  <span>{userInfo.email}</span>
                </div>

                <div className="flex flex-col w-[30%] gap-[4px]">
                  <span className="font-bold text-[14px] text-[#475569]">
                    Position
                  </span>
                  <span className="font-normal text-[16px] text-[#0F172A]">
                    {userInfo.position}
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Skeleton active />
      )}

      <div className="h-[1px] bg-[#E2E8F0]" />

      <UserDetailsTabs />

      <Outlet />

      {hasUserUpdateRole && userInfo && (
        <UserDrawer2
          visible={drawerVisible}
          onClose={() => toggleOpenDrawer(false)}
          initialFormValues={{
            lastName: userInfo.lastName,
            firstName: userInfo.firstName,
            position: userInfo.position,
            email: userInfo.email,
          }}
          onUpdateUser={onUpdateUser}
        />
      )}

      <FloatButton.BackTop />
    </div>
  );
};

export default UserDetailsPage;
