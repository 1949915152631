import { MarkerType } from "reactflow";
import { NodeColor } from "../hooks/useGetNodes.hook";

const getUpdatedEdgeStyles = ({
  nodeId,
  inputNodeId,
  targetNodeId,
}: {
  nodeId: string;
  targetNodeId: string;
  inputNodeId: string;
}) => {
  const isTarget = nodeId === targetNodeId;
  const isInput = nodeId === inputNodeId;
  const isTargetOrInput = nodeId === targetNodeId || nodeId === inputNodeId;

  return {
    style: {
      strokeWidth: isTargetOrInput ? 3 : 1,
      stroke: isTarget
        ? NodeColor.TARGET
        : isInput
          ? NodeColor.INPUT
          : NodeColor.DEFAULT,
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: isTargetOrInput ? 10 : 20,
      height: isTargetOrInput ? 10 : 20,
      color: isTarget
        ? NodeColor.TARGET
        : isInput
          ? NodeColor.INPUT
          : NodeColor.DEFAULT,
    },
    markerStart: isTarget ? "target" : isInput ? "input" : "default",
    zIndex: isTargetOrInput ? 1 : 0,
  };
};

export default getUpdatedEdgeStyles;
