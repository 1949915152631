import { Form, FormInstance } from 'antd';
import React, { Fragment } from "react";
import { TCcVariable } from "../../../store/slices/ccVariablesSlice";
import { VAR_IN_PROMPT_PLACEHOLDER } from "../../../utils/campaigns.constant";
import TemplatePromptVar from "./TemplatePromptVar";
import { TVarType, VarTypeEnum } from "../../../types";

type PropsType = {
  promptTemplate: string;
  form: FormInstance<any>;
  formPromptArgsKey: string;
  gridItemSequence: number;
  localKeys: TCcVariable[];
};

const TemplatePrompt = (props: PropsType) => {
  const {
    promptTemplate,
    form,
    formPromptArgsKey,
    gridItemSequence,
    localKeys,
  } = props;
  const promptKeyArgs = Form.useWatch<(string | null)[] | undefined>("arguments", form);

  if (!promptTemplate) return <span>Prompt template is empty!</span>;
  if (!promptKeyArgs) return <span>Prompt template arguments are empty!</span>;

  const splitTemplate = promptTemplate.split(VAR_IN_PROMPT_PLACEHOLDER);

  if (splitTemplate.length === 1)
    return (
      <div
        style={{
          backgroundColor: "rgb(243, 247, 255)",
          borderRadius: "8px",
          padding: "5px 10px",
        }}
      >
        {splitTemplate[0]}
      </div>
    );

  return (
    <div
      style={{
        backgroundColor: "rgb(243, 247, 255)",
        borderRadius: "8px",
        padding: "5px 10px",
      }}
    >
      {splitTemplate.map((item, index) => {
        if (index === splitTemplate.length - 1) {
          return (
            <Fragment key={`${promptTemplate}__${index}`}>{item}</Fragment>
          );
        }

        const varKey = promptKeyArgs[index];
        let varType: TVarType = VarTypeEnum.LOCAL_VAR;

        if (varKey) {
          if (/^".*"$/.test(varKey)) {
            varType = VarTypeEnum.RAW;
          } else if (varKey.includes("/")) {
            varType = VarTypeEnum.CAMPAIGN_VAR;
          }
        }

        return (
          <Fragment key={`${promptTemplate}__${index}`}>
            {item}
            <TemplatePromptVar
              form={form}
              varKey={varKey}
              varType={varType}
              index={index}
              localKeys={localKeys}
              formPromptArgsKey={formPromptArgsKey}
              gridItemSequence={gridItemSequence}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

export default TemplatePrompt;
