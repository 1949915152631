import { Icons } from "../../../components/common/Icons";
import Pagination from "../../../components/common/Pagination";
import { Button } from "../../../components/common/ui/button";
import CompanyDrawer from "../../../components/companies/CompanyDrawer/CompanyDrawer";
import useCompaniesListHook from "./useCompaniesList.hook";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";
import React, { useState } from "react";
import { Table, TableBody } from "../../../components/common/ui/table";
import { TableHeader } from "../../../components/table/Header";
import CompaniesListItem from "../../../components/companies/CompaniesListItem";
import SkeletonList from "../../../components/common/SkeletonList";
import { EmptyResult } from "src/components/common/EmptyResult";

const tableHeaderItems = [
  {
    title: "Company Name",
    className: "w-1/3 font-sans font-bold text-[#0F172A]",
  },
  {
    title: "Website",
    className: "w-1/3 font-sans font-bold text-[#0F172A]",
  },
  {
    title: "Creation date",
    className: "w-1/3 font-sans font-bold text-[#0F172A]",
  },
  null,
];

const CompaniesPage = () => {
  const {
    pageNumber,
    pageSize,
    total,
    updatePage,
    updatePageSize,
    loading,
    list,
    onCreateCompany,
  } = useCompaniesListHook();
  const { hasCompanyCreateRole } = useUserHasPermission({});
  const [drawerVisible, setDrawerVisible] = useState(false);

  const renderPagination = () => (
    <Pagination
      updatePageSize={updatePageSize}
      updatePage={updatePage}
      total={total}
      pageSize={pageSize}
      pageNumber={pageNumber}
    />
  );

  return (
    <div className="w-full flex flex-col gap-[24px]">
      <div className="flex justify-between">
        <h1 className=" text-slate-900 text-2xl font-semibold font-sans leading-normal">
          Companies
        </h1>
        {hasCompanyCreateRole && (
          <Button
            icon={Icons.Plus}
            className="rounded-full"
            disabled={loading}
            onClick={() => setDrawerVisible(true)}
          >
            Add New Company
          </Button>
        )}
      </div>

      {list.length ? (
        <>
          {renderPagination()}

          {loading ? (
            <SkeletonList quantity={Math.ceil(total / 3)} />
          ) : (
            <div className="rounded-md border w-full mx-auto">
              <Table>
                <TableHeader items={tableHeaderItems} />
                <TableBody className="font-sans">
                  {list.map((item) => (
                    <CompaniesListItem key={item.id} item={item} />
                  ))}
                </TableBody>
              </Table>
            </div>
          )}

          {renderPagination()}
        </>
      ) : (
        <>
          {loading ? (
            <SkeletonList quantity={3} />
          ) : (
            <EmptyResult
              message="There are no companies in your profile."
              className="h-full w-full"
            />
          )}
        </>
      )}

      {hasCompanyCreateRole && (
        <CompanyDrawer
          visible={drawerVisible}
          setVisible={setDrawerVisible}
          initialFormValues={null}
          onCreateCompany={onCreateCompany}
        />
      )}
    </div>
  );
};

export default CompaniesPage;
