import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentTemplatesApi } from "src/api/document-templates.api";
import { selectMessageApi } from "src/store/slices/appSlice";
import {
  docTemplatesActions,
  selectDocTemplatesState,
} from "src/store/slices/docTemplatesSlice";
import {
  DocumentTemplate,
  DocumentTemplateField,
  DocumentTemplateState,
  SortingDirection,
} from "src/types/docTemplates";
import handleRequestError from "src/utils/handleRequestError";

export const useDocTemplatesList = () => {
  const dispatch = useDispatch();
  const messageApi = useSelector(selectMessageApi);
  const [loading, setLoading] = useState(false);

  const {
    list,
    total,
    pageNumber,
    pageSize,
    sortingField,
    sortingDirection,
    states,
  } = useSelector(selectDocTemplatesState);

  const load = useCallback(
    async (overriedBody?: any) => {
      try {
        setLoading(true);
        const { data } = await getDocumentTemplatesApi({
          pageNumber: pageNumber - 1,
          pageSize,
          states: states ? states?.join(",") : "", // Convert array to string
          sortBy: sortingField,
          orderBy: sortingDirection,
          ...overriedBody,
        });
        dispatch(
          docTemplatesActions.setList({
            list: data.items,
            total: data.totalItems,
          })
        );
      } catch (e: any) {
        const customError = handleRequestError(e);

        messageApi.error(customError.message);
        console.error(customError);
      } finally {
        setLoading(false);
      }
    },
    [pageNumber, pageSize, states, sortingField, sortingDirection]
  );

  const updatePage = (pageNumber: number) => {
    dispatch(docTemplatesActions.setPageNumber(pageNumber));
  };

  const deleteTemplate = (templateId: number) => {
    dispatch(docTemplatesActions.deleteTemplate(templateId));
  };

  const updatePageSize = (pageSize: number) => {
    dispatch(docTemplatesActions.setPageNumber(1));
    dispatch(docTemplatesActions.setPageSize(pageSize));
  };

  const updateClasses = (templateId: number, classes: string[]) => {
    dispatch(docTemplatesActions.setPageNumber(1));
    dispatch(docTemplatesActions.updateClasses({ templateId, classes }));
  };

  const updateTemplate = (
    templateId: number,
    body: Partial<DocumentTemplate>
  ) => {
    dispatch(docTemplatesActions.update({ id: templateId, ...body }));
  };

  const updateStateFilters = (states: DocumentTemplateState[]) => {
    dispatch(docTemplatesActions.setPageNumber(1));
    dispatch(docTemplatesActions.updateFilterByStates(states));
  };

  const updateSorting = (field: string, direction: SortingDirection) => {
    dispatch(
      docTemplatesActions.updateSorting({
        field: field as DocumentTemplateField,
        direction,
      })
    );
  };

  return {
    list,
    total,
    pageSize,
    pageNumber,
    sorting: {
      field: sortingField,
      direction: sortingDirection,
    },
    states,
    updatePage,
    updatePageSize,
    updateSorting,
    updateClasses,
    deleteTemplate,
    updateTemplate,
    updateStateFilters,
    loading,
    load,
  };
};
