import { Form } from "antd";
import { FC, useEffect, useState } from "react";
import { createSFTPUserApi } from "src/api/sftp-users.api";
import { Button } from "src/components/common/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "src/components/common/ui/dialog";
import { Input } from "src/components/common/ui/input";
import { Label } from "src/components/common/ui/label";
import { getMessageApi } from "src/store/slices/appSlice";
import handleRequestError from "src/utils/handleRequestError";
import { SftpUser } from "../../../globalTypes";
import { Icons } from "../../common/Icons";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import { minMax, required } from "../../../utils/validations";

type Props = {
  micrositeId: number;
  onCreated: (sftpUser: SftpUser) => void;
};

export type SftpUserValues = {
  username: string;
};

export const CreateSftpUserDialog: FC<Props> = ({ micrositeId, onCreated }) => {
  const messageApi = getMessageApi();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [form] = Form.useForm<SftpUserValues>();

  const handleCreate = async (values: SftpUserValues) => {
    try {
      setLoading(true);
      const { data } = await createSFTPUserApi({
        micrositeId,
        username: values.username,
      });

      onCreated(data);
      messageApi.success("sFTP User created successfully");
    } catch (error: any) {
      const customError = handleRequestError(error);

      if (
        customError.message.includes("already exists") &&
        customError.message.includes("secret")
      ) {
        messageApi.error("SFTP User with the same name already exists");
      } else {
        messageApi.error(customError.message);
        console.error(customError);
      }
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    return () => {
      form.setFieldValue("username", "");
    };
  }, [open]);

  const onFormSubmit = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: SftpUserValues) => {
      await handleCreate(values);
    },
  });

  return (
    <div>
      <Button
        icon={Icons.Plus}
        className="rounded-full"
        onClick={() => setOpen(true)}
      >
        Add Sftp User
      </Button>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="font-sans">
              Create new sFTP User
            </DialogTitle>
            <DialogDescription>
              <div className="font-sans flex flex-col gap-3 mt-3">
                <Form form={form} layout="vertical">
                  <Form.Item
                    name="username"
                    label={<Label>Username</Label>}
                    required
                    tooltip="Please enter a string between 2 and 150 characters long"
                    rules={[
                      required(),
                      minMax({ text: "Username", min: 2, max: 150 }),
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <div className="flex justify-end">
                    <Form.Item className="m-0">
                      <Button
                        disabled={loading}
                        onClick={onFormSubmit}
                        loading={loading}
                      >
                        Create
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
};
