import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const LabelsHeader: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Flex vertical gap="middle">
      <div>
        <Button
          size="middle"
          icon={<ArrowLeftOutlined />}
          onClick={() => {
            if (location.key !== "default") navigate(-1);
          }}
          disabled={location.key === "default"}
          style={{
            fontFamily: "Geist",
            fontWeight: "600",
          }}
        >
          Back To Campaigns
        </Button>
      </div>
      <h1
        style={{
          fontFamily: "Geist",
          fontSize: "32px",
          fontWeight: "600",
          margin: 0,
        }}
      >
        Global Labels
      </h1>
    </Flex>
  );
};

export default LabelsHeader;
