const VersionButton = ({ onClick, label }: any) => {
  return (
    <div
      className="py-[5px] px-[12px] bg-[#F1F5F9] rounded-full font-sans cursor-pointer flex items-center hover:bg-gray-200 transition-colors"
      onClick={onClick}
    >
      <span className="font-sans font-semibold text-[12px] leading-[100%] text-[#64748B] mr-[4px]">
        {label}
      </span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.22007 6.22C4.3607 6.07955 4.55132 6.00066 4.75007 6.00066C4.94882 6.00066 5.13945 6.07955 5.28007 6.22L8.00007 8.94L10.7201 6.22C10.7887 6.14631 10.8715 6.08721 10.9635 6.04622C11.0555 6.00523 11.1548 5.98319 11.2556 5.98141C11.3563 5.97963 11.4563 5.99816 11.5497 6.03588C11.6431 6.0736 11.7279 6.12974 11.7991 6.20096C11.8703 6.27218 11.9265 6.35701 11.9642 6.4504C12.0019 6.54379 12.0204 6.64382 12.0187 6.74452C12.0169 6.84523 11.9948 6.94454 11.9539 7.03654C11.9129 7.12854 11.8538 7.21134 11.7801 7.28L8.53007 10.53C8.38945 10.6705 8.19882 10.7493 8.00007 10.7493C7.80132 10.7493 7.6107 10.6705 7.47007 10.53L4.22007 7.28C4.07962 7.13938 4.00073 6.94875 4.00073 6.75C4.00073 6.55125 4.07962 6.36063 4.22007 6.22Z"
          fill="#64748B"
        />
      </svg>
    </div>
  );
};

export default VersionButton;