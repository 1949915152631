import React from "react";
import CompanyUsersList from "../../../components/companies/CompanyUsersList/CompanyUsersList";
import useGetCompanyUsers from "./useGetCompanyUsers.hook";
import { Skeleton } from "antd";

const CompanyUsersPage = () => {
  const { isInitialized, error, companyUsers, loadUsers } = useGetCompanyUsers();

  if (!isInitialized) return <Skeleton active />;

  return (
    <div>
      {error ? (
        <div className="text-red-600">{error}</div>
      ) : (
        <CompanyUsersList users={companyUsers} loadUsers={loadUsers}/>
      )}
    </div>
  );
};

export default CompanyUsersPage;
