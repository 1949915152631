import { useState } from "react";
import { getPermissionsGroupApi } from "../../../api/permissions-group.api";
import handleRequestError from "../../../utils/handleRequestError";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { useNavigate, useParams } from "react-router-dom";
import { fetchCompaniesOptions } from "../../../utils/apiUtils";
import { TSelectOptionWithData } from "../../common/SelectWithHighlightSearch";
import { addCompanyPermissionGroupApi } from "../../../api/user.api";
import { TCompany } from "../../../store/slices/companiesSlice";
import { useUserHasPermission } from "../../../hooks/useUserHasPermission";

const useGetCompanySecurityGroups = ({
  updateGroupsList,
}: {
  updateGroupsList: ({
    userId,
    update,
  }: {
    userId: string | number;
    update?: boolean | undefined;
  }) => Promise<void>;
}) => {
  const { userId } = useParams();
  const [open, setOpen] = useState(false);
  const messageApi = useSelector(selectMessageApi);
  const [isFetchingAddGroup, setIsFetchingAddGroup] = useState(false);
  const [isFetchingInitialData, setIsFetchingInitialData] = useState(false);
  const [error, setError] = useState("");
  const [globalGroupsOptions, setGlobalGroupsOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [companyOptions, setCompanyOptions] = useState<
    TSelectOptionWithData<TCompany>[]
  >([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(
    null,
  );
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const navigate = useNavigate();
  const { hasUserCompanyPermReadRole } = useUserHasPermission({
    companyId: selectedCompanyId || undefined,
  });

  const loadInitData = async () => {
    try {
      setIsFetchingInitialData(true);

      const [{ data: userGroups }, companyOptionsData] = await Promise.all([
        getPermissionsGroupApi(),
        fetchCompaniesOptions(),
      ]);

      setGlobalGroupsOptions(
        userGroups.map(({ title, id }) => ({ label: title, value: id })),
      );
      setCompanyOptions(companyOptionsData);
      setOpen(true);
      setError("");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      setError(customError.message);

      console.error(customError);
    } finally {
      setIsFetchingInitialData(false);
    }
  };

  const onAddCompanyGroup = async () => {
    try {
      if (selectedCompanyId !== null && selectedGroupId !== null && userId) {
        setIsFetchingAddGroup(true);

        await addCompanyPermissionGroupApi({
          userId,
          companyId: selectedCompanyId,
          permissionGroupId: selectedGroupId,
        });

        await updateGroupsList({ userId, update: true });

        handleOpenChange(false);
        setError("");

        if (hasUserCompanyPermReadRole) {
          navigate(
            `/users/${userId}/details/company-security/${selectedCompanyId}`,
          );
        }

        messageApi.success("The company's group has been successfully created");
      }
    } catch (e: any) {
      const customError = handleRequestError(e);

      setError(customError.message);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetchingAddGroup(false);
    }
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
    setSelectedGroupId(null);
    setSelectedCompanyId(null);
  };

  return {
    error,
    globalGroupsOptions,
    companyOptions,
    selectedCompanyId,
    setSelectedGroupId,
    selectedGroupId,
    isFetchingAddGroup,
    loadInitData,
    open,
    isFetchingInitialData,
    handleOpenChange,
    setSelectedCompanyId,
    onAddCompanyGroup,
  };
};

export default useGetCompanySecurityGroups;
