import React from "react";
import { Navigate, Route, Routes } from 'react-router-dom';
import ModelConfigsListPage from "../../pages/modelConfigs/ModelConfigsListPage";
import CreateModelConfigPage from "../../pages/modelConfigs/CreateModelConfigPage";
import EditModelConfigPage from "../../pages/modelConfigs/EditModelConfigPage";
import { UserPermissions } from "../../store/slices/userDataSlice";
import ProtectedByRoleRoute from "../ProtectedByRoleRoute";

const ModelConfigsRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_AI_CONFIGURATION_LIST}
          >
            <ModelConfigsListPage />
          </ProtectedByRoleRoute>
        }
      />

      <Route
        path="create"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_AI_CONFIGURATION_CREATE}
          >
            <CreateModelConfigPage />
          </ProtectedByRoleRoute>
        }
      />

      <Route
        path="edit/:modelConfigId"
        element={
          <ProtectedByRoleRoute
            permission={UserPermissions.ROLE_AI_CONFIGURATION_UPDATE}
          >
            <EditModelConfigPage />
          </ProtectedByRoleRoute>
        }
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export default ModelConfigsRoutes;
