const micrositeContextFolderTooltip = {
  title: (
    <span>
      Maximum length is 255 characters. <br />
      <br /> You can use variables to name folders. For example:
      <br />
      <b>
        /$&#123;campaign.name&#125;/$&#123;phase.name&#125;/$&#123;step.name&#125;
      </b>
      <br />
      <i className="text-[12px]">
        *If it starts with a slash, it resets all previous path concatenation
      </i>
    </span>
  ),
  overlayStyle: { maxWidth: "450px" },
};

export default micrositeContextFolderTooltip;
