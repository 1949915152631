import { Button, Form, FormInstance } from "antd";
import { Trash2Icon } from "lucide-react";
import { FC, useEffect, useState } from "react";
import { TEditModeProps } from "src/components/CampaignGrid/Grid/CampaignGrid";
import { Label } from "src/components/common/ui/label";
import { TCcVariable } from "../../../store/slices/ccVariablesSlice";
import PostProcessing from "./PostProcessingItem";

type Props = {
  dynElemName: string;
  required: boolean;
  hidden: boolean;
  form: FormInstance<any>;
  gridItemSequence: number;
  ccVarData: TEditModeProps | null;
  campaignId: number;
  stepId: number;
  localKeys: TCcVariable[];
};

export type PostProcessingItem = {
  type: "env" | "cc" | "text";
  value: string;
  keys: (string | null)[];
};

export const generateId = () => String(Math.floor(Math.random() * 100));

export type PostProcessingRenderItem = {
  id: string;
} & PostProcessingItem;

const defaultPostProcessingItem: PostProcessingItem = {
  type: "text",
  value: "",
  keys: [],
};

const PostProcessingItems: FC<Props> = ({
  dynElemName,
  required,
  form,
  gridItemSequence,
  ccVarData,
  stepId,
  campaignId,
  localKeys,
}) => {
  const [items, setItems] = useState<PostProcessingRenderItem[]>([]);

  const handleAddPostProcessing = () => {
    setItems([
      ...items,
      {
        id: generateId(),
        ...defaultPostProcessingItem,
      },
    ]);
  };

  const handleRemovePostProcessing = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  const serializeItemsForSaving = (items: PostProcessingRenderItem[]) => {
    return items.map(({ id, ...rest }) => rest);
  };

  const handleEditPostProcessing =
    (index: number) => (item: PostProcessingRenderItem) => {
      const newItems = [...items];
      newItems[index] = item;
      setItems(newItems);
    };

  useEffect(() => {
    form.setFieldsValue({
      postProcessingParams: serializeItemsForSaving(items),
    });
  }, [items]);

  useEffect(() => {
    const postProcessingParams =
      ccVarData?.variableData.options?.postProcessingParams;

    if (postProcessingParams) {
      const parsedItems =
        typeof postProcessingParams === "string"
          ? JSON.parse(postProcessingParams)
          : postProcessingParams;

      setItems(
        parsedItems.map((item: PostProcessingItem) => ({
          id: generateId(),
          ...item,
        })),
      );
    }
  }, []);

  return (
    <div>
      <Label className="font-sans font-bold text-[#475569] text-[14px] flex mb-3">
        Post Processing
      </Label>
      {items.map((item, index: number) => (
        <Form.Item
          key={item.id}
          name={[dynElemName, index]}
          validateTrigger=""
          rules={[
            { required, message: "Post Processing is required" },
            {
              validator: async (_, item: PostProcessingItem) => {
                if (item.keys?.length && item.keys.includes(null)) {
                  return Promise.reject("Please fill all the keys");
                }

                if (!item.value) {
                  return Promise.reject("Please fill the prompt template");
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <div className="flex gap-3 w-full border border-slate-300 rounded-md p-2">
            <div className="w-full flex justify-between">
              <div>
                <div className={"text-bold rounded-full p-2"}>
                  ${`{${index + 1}}`}
                </div>
              </div>
              <div className="w-full">
                <PostProcessing
                  defaultItem={item}
                  index={index}
                  form={form}
                  gridItemSequence={gridItemSequence}
                  onItemEdit={handleEditPostProcessing(index)}
                  campaignId={campaignId}
                  stepId={stepId}
                  localKeys={localKeys}
                />
              </div>
            </div>
            <Button danger onClick={() => handleRemovePostProcessing(index)}>
              <Trash2Icon className="h-4 w-4" />
            </Button>
          </div>
        </Form.Item>
      ))}
      <Button onClick={handleAddPostProcessing}>Add</Button>
    </div>
  );
};

export default PostProcessingItems;
