import { Form, Modal, Select, message } from "antd";
import { TextAreaRef } from "antd/es/input/TextArea";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import {
  TDynamicElementImportVar,
  TEnvVariableType,
  TEnvVarsFormValues,
  getDynamicFormItemsImportVars,
  getImportEnvVarTypes,
  importVariablesThunk,
} from "../../../store/slices/envVariablesSlice";
import { AppDispatch } from "../../../store/store";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import TextAreaWithAltEnter from "../../common/TextAreaWithAltEnter";
import ImportVarDynForms from "../ImportVarDynForms/ImportVarDynForms";

type PropsType = {
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

const ImportVariablesModal = (props: PropsType) => {
  const { isOpened, setIsOpened } = props;
  const dispatch: AppDispatch = useDispatch();
  const importEnvVarTypes = getImportEnvVarTypes();
  const dynamicFormItems = getDynamicFormItemsImportVars();
  const [form] = Form.useForm<TEnvVarsFormValues>();
  //hardcoded default selected value to 'prompt'
  const [isTypeSelected, setIsTypeSelected] = useState(true);
  const [dynamicData, setDynamicData] = useState<TDynamicElementImportVar>(
    dynamicFormItems["prompt"]
  );
  const [messageApi, contextHolder] = message.useMessage();
  const initialFormValues = {
    type: "prompt",
  };
  const inputTagRef = useRef<TextAreaRef>(null);

  useSubmitFormOnEnter(() => submitForm());

  useEffect(() => {
    if (inputTagRef.current) {
      inputTagRef.current.focus({
        cursor: "end",
      });
    }
  }, []);

  const handleCancel = () => {
    form.resetFields();

    setIsTypeSelected(false);
    setDynamicData([]);
    setIsOpened(false);
  };

  const onTypeSelect = async (value: TEnvVariableType) => {
    form.resetFields();
    form.setFieldsValue({ type: value });

    setIsTypeSelected(true);
    setDynamicData(dynamicFormItems[value]);
  };

  const onImport = async (values: TEnvVarsFormValues) => {
    try {
      const res = await dispatch(importVariablesThunk(values));

      if ("error" in res) {
        messageApi.error(res.payload?.message);
      } else {
        setIsOpened(false);
        setIsTypeSelected(false);
        setDynamicData([]);
        form.resetFields();
      }
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(
        "An error occurred while trying to import env variables:",
        e
      );
    }
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TEnvVarsFormValues) => {
      await onImport(values);
    },
  });

  const varTypeSelectOptions = importEnvVarTypes.map((type) => ({
    value: type,
    label: type,
  }));

  return (
    <>
      {contextHolder}
      <Modal
        title="Import Variables"
        open={isOpened}
        okText="Import Variables"
        onOk={submitForm}
        okButtonProps={{ disabled: !isTypeSelected }}
        onCancel={handleCancel}
        destroyOnClose={true}
        centered={true}
        width={"50vw"}
      >
        <Form
          form={form}
          layout="vertical"
          name="import_env_variable_form"
          style={{ height: "50vh", overflowY: "scroll" }}
          initialValues={initialFormValues}
        >
          <Form.Item
            name="type"
            label="Type"
            rules={[{ required: true, message: "Required field!" }]}
          >
            <Select
              placeholder="Select type"
              style={{ width: 120 }}
              onChange={onTypeSelect}
              options={varTypeSelectOptions}
            />
          </Form.Item>

          <ImportVarDynForms
            isTypeSelected={isTypeSelected}
            form={form}
            items={dynamicData}
          />

          <Form.Item
            hidden={!isTypeSelected}
            name="value"
            label="Value"
            rules={[{ required: true, message: "Required field!" }]}
          >
            <TextAreaWithAltEnter
              autoFocus
              placeholder="Enter value"
              currentForm={form}
              fieldName="value"
              rows={10}
              ref={inputTagRef}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ImportVariablesModal;
