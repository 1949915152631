import { TStorageFileItem } from "../../../../globalTypes";
import { SelectStorageItemType, TreeSelectItem } from "../SelectStorageItems";

type Props = {
  data: TStorageFileItem[];
  selectType: SelectStorageItemType;
  parentFileName?: string;
  level?: number;
  origin?: TreeSelectItem[];
};

const processSelectStorageItems = ({
  data,
  selectType,
  parentFileName = "",
  level = 0,
  origin = [],
}: Props): Array<TreeSelectItem> => {
  const newTreeData = data.map((file) => {
    let title: string;

    // slash ("/") - this is a prefixPath(root dir) for global/microSite storages
    if (file.fileName === "/") {
      title = file.fileName;
    } else {
      const fileNameSplit = file.fileName.split("/");
      title = file.folder
        ? fileNameSplit[fileNameSplit.length - 2]
        : fileNameSplit[fileNameSplit.length - 1];
    }

    return {
      ...file,
      title: file.folder ? (
        <div className="font-semibold">{title}</div>
      ) : (
        <div>{title}</div>
      ),
      key: file.fileName,
      value: file.fileName,
      parentId: parentFileName || null,
      expanded: file.folder ? false : undefined,
      level: level + 1,
      isLeaf: !file.folder,
      selectable:
        (file.folder && selectType === "FOLDER") ||
        (!file.folder && selectType === "FILE"),
      children: [],
    };
  });

  const updateTreeData = (items: TreeSelectItem[]): TreeSelectItem[] => {
    return items.map((item) => {
      if (item.fileName === parentFileName) {
        return {
          ...item,
          expanded: true,
          children:
            newTreeData.length > 0
              ? newTreeData
              : [
                  {
                    key: `${parentFileName}-empty-${Date.now()}`,
                    value: `${parentFileName}-empty-${Date.now()}`,
                    title: "Directory is empty",
                    isLeaf: true,
                    disabled: true,
                    folder: false,
                    size: 0,
                    fileName: "",
                    mimeType: "",
                    level: item.level + 1,
                    parentId: item.fileName,
                  },
                ],
        };
      }

      return {
        ...item,
        children: item.children ? updateTreeData(item.children) : [],
      };
    });
  };

  const collapseTreeData = (items: TreeSelectItem[]): TreeSelectItem[] => {
    return items.map((item) => {
      if (item.fileName === parentFileName) {
        return {
          ...item,
          expanded: false,
          children: [],
        };
      }

      return {
        ...item,
        children: item.children ? collapseTreeData(item.children) : [],
      };
    });
  };

  if (origin.length > 0) {
    if (parentFileName) {
      return updateTreeData(origin);
    } else {
      return collapseTreeData(origin);
    }
  } else {
    return newTreeData;
  }
};

export default processSelectStorageItems;
