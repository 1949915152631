import { Checkbox, Flex, Modal } from "antd";
import React, { FC, useState } from "react";
import { TCampaign } from "src/store/slices/campaignsSlice";
import { exportCampaignApi } from "../../../api/campaigns.api";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import { getMessageApi } from "../../../store/slices/appSlice";
import {
  downloadFileUtil,
  getExportSettingQueryParam,
} from "../../../utils/cm.utils";
import { TExportCampaignSettings, TSettingType } from "../Settings/Settings";

type Props = {
  campaignId: number;
  currentCampaign: TCampaign | null;
  setIsOpenExportModal: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenExportModal: boolean;
};

const ExportCampaignModal: FC<Props> = ({
  campaignId,
  currentCampaign,
  setIsOpenExportModal,
  isOpenExportModal,
}) => {
  const messageApi = getMessageApi();
  const [isFetching, setIsFetching] = useState(false);
  const [settings, setSettings] = useState<TExportCampaignSettings>({
    I18N: true,
    ENV: true,
    CC: true,
    ASSETS: true,
    STORAGE: true,
  });

  useSubmitFormOnEnter(() => onExport(), { condition: !isFetching });

  const onSettingsChange = (checked: boolean, setting: TSettingType) => {
    setSettings({
      ...settings,
      [setting]: checked,
    });
  };

  const onExport = async () => {
    try {
      setIsFetching(true);
      const params = getExportSettingQueryParam(settings);

      const response = await exportCampaignApi({
        campaignId,
        params,
      });
      const fileName = `${currentCampaign ? currentCampaign.name : "CampaignData"}.zip`;

      downloadFileUtil(response, fileName);

      messageApi.success("Successfully exported");
    } catch (e: any) {
      if (e.response && e.response.data instanceof Blob) {
        const errorText = await e.response.data.text();
        try {
          const errMessage = JSON.parse(errorText);
          messageApi.error(errMessage?.debugMessage || e?.message);
        } catch (e) {
          console.error("Error converting Blob to JSON", e);
        }
      } else {
        messageApi.error(`Export error: ${e?.message}`);
      }

      console.error("Can't export campaign", e);
    } finally {
      setIsOpenExportModal(false);
      setIsFetching(false);
    }
  };

  return (
    <Modal
      title="Export Campaign"
      open={isOpenExportModal}
      okText="Export"
      cancelText="Cancel"
      confirmLoading={isFetching}
      onOk={onExport}
      onCancel={() => setIsOpenExportModal(false)}
    >
      <Flex vertical gap="small">
        <Checkbox
          onChange={(e) => onSettingsChange(e.target.checked, "CC")}
          checked={settings["CC"]}
        >
          Export Computation Items
        </Checkbox>
        <Checkbox
          onChange={(e) => onSettingsChange(e.target.checked, "I18N")}
          checked={settings["I18N"]}
        >
          Export Labels
        </Checkbox>
        <Checkbox
          onChange={(e) => onSettingsChange(e.target.checked, "ENV")}
          checked={settings["ENV"]}
        >
          Export Environment Variables
        </Checkbox>
        <Checkbox
          onChange={(e) => onSettingsChange(e.target.checked, "ASSETS")}
          checked={settings["ASSETS"]}
        >
          Export Assets
        </Checkbox>
        <Checkbox
          onChange={(e) => onSettingsChange(e.target.checked, "STORAGE")}
          checked={settings["STORAGE"]}
        >
          Export Storage
        </Checkbox>
      </Flex>
    </Modal>
  );
};

export default ExportCampaignModal;
