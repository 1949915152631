import { FileSearchOutlined } from "@ant-design/icons";
import { Button } from "antd";

const FindDuplicates = () => {
  return (
    <Button icon={<FileSearchOutlined />} disabled>
      Find Duplicates
    </Button>
  );
};

export default FindDuplicates;
