import {
  getCurrentCompany,
  selectCompanies,
} from "../../store/slices/companiesSlice";
import { useSelector } from "react-redux";
import { Empty, Flex } from "antd";
import CampaignListHeader from "../../components/campaignsList/CampaignListHeader";
import CampaignsList from "../../components/campaignsList/CampaignsList";
import CampaignsPagination from "../../components/campaignsList/CampaignsPagination";
import useCampaignsList, {
  ALL_TEMPLATES_ID,
} from "../campaigns/hooks/useCampaignsList";

const CampaignTemplatesPage = () => {
  const {
    requestParams,
    additionalParams,
    requestCampaignsList,
    handleChangePage,
    handleChangePageSize,
    handleChangeCompany,
    handleChangeCampaignType,
  } = useCampaignsList();

  const companies = useSelector(selectCompanies);
  const currentCompany = getCurrentCompany();

  if (!companies.length)
    return (
      <Flex
        vertical
        style={{ height: "100%", width: "100%" }}
        align="center"
        justify="center"
      >
        <Empty
          imageStyle={{ height: "30vh" }}
          description={
            <div>
              <span>There are no companies in your profile.</span>
            </div>
          }
        />
      </Flex>
    );

  return (
    <div className="flex flex-col w-full gap-[24px]">
      <CampaignListHeader
        requestParams={requestParams}
        backUrl={additionalParams.backUrl || ""}
        currentCompany={currentCompany}
        campaignType={requestParams.campaignType}
        onCompanyChange={handleChangeCompany}
        onCampaignTypeChange={handleChangeCampaignType}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        enabledTypes={[
          ALL_TEMPLATES_ID,
          "DRAFT_TEMPLATE",
          "PUBLISHED_TEMPLATE",
        ]}
      />
      <CampaignsList
        requestParams={requestParams}
        requestCampaignsList={requestCampaignsList}
      />
      <CampaignsPagination
        page={requestParams.page}
        pageSize={requestParams.pageSize}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
      />
    </div>
  );
};

export default CampaignTemplatesPage;
