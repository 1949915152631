import React, { FC } from "react";
import { TFileTreeItem } from "../../../globalTypes";
import File from "./File";
import Folder from "./Folder";
import { Link, useLocation, useParams } from "react-router-dom";
import clsx from "clsx";
import TableItem from "./TableItem";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

type Props = {
  treeData: TFileTreeItem[];
  getFolderFiles: (selectedDir: TFileTreeItem) => Promise<void>;
  setSelectedItem: React.Dispatch<React.SetStateAction<TFileTreeItem | null>>;
  selectedItem: TFileTreeItem | null;
  renderMenu: (props: {
    isHovering: boolean;
    isSelected: boolean;
    item: TFileTreeItem;
  }) => React.ReactElement;
};

const FileTree: FC<Props> = ({
  treeData,
  getFolderFiles,
  setSelectedItem,
  selectedItem,
  renderMenu,
}) => {
  const { pathname } = useLocation();
  const isMicrositeStorage = pathname.includes("microsite");
  const { companyId } = useParams();

  const renderTree = (items: TFileTreeItem[], level = 0) => {
    return (
      <div
        className="font-medium text-[14px] text-[#475569] flex flex-col gap-[16px]"
        style={{
          marginLeft: level === 0 ? 0 : `${level + 20}px`,
        }}
      >
        {items.map((item, index) => {
          const {
            fileName,
            folder,
            expanded,
            isRootDir,
            campaigns,
            publicationDates,
          } = item;

          const shouldRenderTooltip =
            !isRootDir && campaigns?.[0]?.campaignId !== "uploaded";
          const sourceTooltip = shouldRenderTooltip ? (
            <ul>
              {campaigns?.map((campaign, index) => {
                return (
                  <li
                    key={`${campaign.campaignName}_${index}`}
                    className="mb-[8px]"
                  >
                    <b>{index + 1}. </b>{" "}
                    <Link
                      to={
                        folder
                          ? `/campaigns/company/${companyId}/campaign/${campaign.campaignId}/storage/microsite`
                          : `/campaigns/company/${companyId}/campaign/${campaign.campaignId}/phase/${campaign.phaseId}/step/${campaign.stepId}?view=GRID`
                      }
                      className="inline-flex items-center gap-1"
                      target={"_blank"}
                    >
                      {campaign.campaignName}{" "}
                      <ArrowTopRightOnSquareIcon className="w-4 h-4" />{" "}
                    </Link>
                  </li>
                );
              })}
            </ul>
          ) : null;
          const publicationDatesTooltip = publicationDates?.map(
            (date, index) => {
              return <li key={index}>{date}</li>;
            },
          );

          const itemColumns =
            campaigns || publicationDates ? (
              <div className="absolute flex gap-[16px] left-[calc((100vw-96px)/2+16px)] w-[calc((100vw-96px)/2-16px)]">
                <TableItem
                  tooltipTitle={sourceTooltip}
                  data={campaigns?.map((campaign) => campaign.campaignName)}
                />
                <TableItem
                  tooltipTitle={publicationDatesTooltip}
                  data={publicationDates}
                />
              </div>
            ) : null;

          return (
            <div
              key={fileName}
              className={index === 0 && !isRootDir ? "mt-[16px]" : undefined}
            >
              {folder ? (
                <>
                  <Folder
                    item={item}
                    getFolderFiles={getFolderFiles}
                    onSelectItem={() => setSelectedItem(item)}
                    isSelected={selectedItem?.fileName === fileName}
                    renderMenu={renderMenu}
                    columns={itemColumns}
                  />
                  {expanded &&
                    renderTree(
                      treeData.filter((i) => i.parentId === fileName),
                      level + 1,
                    )}
                </>
              ) : (
                <File
                  item={item}
                  onSelectItem={() => setSelectedItem(item)}
                  isSelected={selectedItem?.fileName === fileName}
                  renderMenu={renderMenu}
                  columns={itemColumns}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className={clsx("relative", {
        "w-[calc((100vw-96px)/2)]": isMicrositeStorage,
        "w-full": !isMicrositeStorage,
      })}
    >
      {renderTree(treeData.filter((item) => !item.parentId))}
    </div>
  );
};

export default FileTree;
